import React from "react"
import "./OrderSummary.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { Loading } from "@/components"
import { priceDisplay } from "@/utils/functionHelper"

interface IOrderSummaryProps {}
const OrderSummary: React.FC<IOrderSummaryProps> = ({}) => {
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  if (!currentQuote || !currentQuote.products) {
    return <Loading></Loading>
  }

  const {
    total_price_without_tax,
    tax_in_percents,
    delivery_price,
    total_discount_value,
    total_price
  } = currentQuote
  const taxPrice = +total_price_without_tax * tax_in_percents

  return (
    <div className="order-summary-container">
      <div className="order-summary-title">
        <h3>Order summary</h3>
      </div>
      <div className="order-summary-content">
        {currentQuote?.products.map((item) => (
          <div key={item.id}>
            <div className="order-summary-item">
              <div className="order-summary-item-info">
                <img
                  src={item.model_thumbnail}
                  alt="order item"
                  width={21}
                  height={16}
                />
                <p>{item.model_title}</p>
              </div>
              <p>x{item.count}</p>
            </div>
          </div>
        ))}

        <div className="order-summary-price">
          <div className="order-summary-price-section">
            <p>Subtotal</p>
            <p>${priceDisplay(Number(total_price_without_tax))}</p>
          </div>
          <div className="order-summary-price-section">
            <p>Discount</p>
            <p>${priceDisplay(Number(total_discount_value))}</p>
          </div>
          <div className="order-summary-price-section">
            <p>Shipping</p>
            <p>${priceDisplay(Number(delivery_price))}</p>
          </div>
          <div className="order-summary-price-section">
            <p>Taxes</p>
            <p>${priceDisplay(Number(taxPrice))}</p>
          </div>
        </div>

        <div className="order-summary-total-price">
          <p>Total Amount</p>
          <p>${priceDisplay(Number(total_price) + Number(delivery_price))}</p>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary
