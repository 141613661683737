import { ISaveQuote } from "@/interfaces/saveQuote.interface"
import "./QuoteDetail.scss"

interface IQuoteDetailProps {
  data: ISaveQuote
}

function QuoteDetail({ data }: IQuoteDetailProps) {
  const {
    address: billAddress,
    unit: billUnit,
    state: billState,
    zipCode: billZipCode,
    city: billCity
  } = data.billingAddress
  const { address, unit, state, zipCode, city } = data.deliverAddress
  return (
    <div className="detail">
      <p className="detail-title">QUOTE DETAILS</p>
      <div className="detail-box">
        <div className="detail-box-content">
          <p className="detail-box-content-title">Billing Address</p>
          <p className="detail-box-content-info">
            {billAddress}, {billUnit}, {billCity}, {billState}, {billZipCode}
          </p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">Delivery Address</p>
          <p className="detail-box-content-info">
            {address}, {unit}, {city}, {state}, {zipCode}
          </p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">Shipping Method</p>
          <p className="detail-box-content-info"></p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">Order Notifications</p>
          <p className="detail-box-content-info"></p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">Payment Method</p>
          <p className="detail-box-content-info"></p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">PO Number</p>
          <p className="detail-box-content-info"></p>
        </div>
        <div className="detail-box-content">
          <p className="detail-box-content-title">Account Payable</p>
          <p className="detail-box-content-info"></p>
        </div>
      </div>
    </div>
  )
}

export default QuoteDetail
