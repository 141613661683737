import React from "react"
import { Pagination as AntPagination, PaginationProps, Select } from "antd"
import "./Pagination.scss"
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  DownOutlined
} from "@ant-design/icons"

const { Option } = Select

const PAGE_SIZE_OPTIONS = [
  {
    value: 10,
    label: "10/page"
  },
  {
    value: 15,
    label: "15/page"
  },
  {
    value: 20,
    label: "20/page"
  }
]

interface CustomPaginationProps extends PaginationProps {
  onChange: (page: number, pageSize?: number) => void
  onShowSizeChange?: (current: number, size: number) => void
}

const Pagination: React.FC<CustomPaginationProps> = ({
  current,
  pageSize,
  onChange,
  onShowSizeChange,
  ...props
}) => {
  const { total } = props
  const totalPages = total && pageSize ? Math.ceil(total / pageSize) : 0

  const handleLastPageClick = () => {
    totalPages && onChange(totalPages)
  }

  const handleFirstPageClick = () => {
    onChange(1)
  }

  return (
    <div className="custom-pagination-container">
      {onShowSizeChange && (
        <div className="select-wrapper page-size-selector">
          <Select
            className="pagination-select"
            popupClassName={`dropdown-menu-pagination`}
            defaultValue={pageSize}
            onChange={(value) => onShowSizeChange(current || 10, value)}
          >
            {PAGE_SIZE_OPTIONS.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}

      <div className="custom-pagination-right">
        <div
          className={`pagination-item ${
            current === 1 ? "pagination-item-disabled" : ""
          }`}
        >
          <a>
            <DoubleLeftOutlined onClick={handleFirstPageClick} />
          </a>
        </div>
        <AntPagination
          onChange={onChange}
          current={current}
          pageSize={pageSize}
          showSizeChanger={false}
          {...props}
        />
        <div
          className={`pagination-item ${
            current === totalPages ? "pagination-item-disabled" : ""
          }`}
        >
          <a>
            <DoubleRightOutlined onClick={handleLastPageClick} />
          </a>
        </div>
        {onChange && (
          <div className="select-wrapper">
            <Select
              popupClassName={`dropdown-menu-pagination`}
              placeholder="Page"
              className="pagination-select pagination-select-right"
              value={current}
              onChange={(value) => onChange(value, pageSize)}
            >
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <Option key={page} value={page}>
                    {page}
                  </Option>
                )
              )}
            </Select>
          </div>
        )}
      </div>
    </div>
  )
}

export default Pagination
