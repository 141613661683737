export const getPresistStorage = (event: StorageEvent) => {
  const storageArea = event.storageArea
  if (!storageArea) return

  const persistData = storageArea["persist:root"]
  if (!persistData) return

  const persistObj = JSON.parse(persistData)
  const persistStorage = persistObj["storage"]
  const persistStorageObj = JSON.parse(persistStorage)

  return persistStorageObj
}
