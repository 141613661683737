import { LoadingIcon } from "@/assets"
import "./Loading.scss"
const Loading: React.FC = () => {
  return (
    <>
      <div className="loading-wrapper">
        <div className="loading">
          <img src={LoadingIcon} />
        </div>
      </div>
    </>
  )
}

export default Loading
