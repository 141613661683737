import { Country, CountryOptions } from "@/interfaces/common.interface"
import { GetCity, GetCountries, GetState } from "react-country-state-city"

export const getCountriesList = () =>
  GetCountries().then((result) => {
    const filterData =
      result.filter(
        (country: Country) =>
          !(
            country.name.toLowerCase().includes("islands") &&
            country.name.toLowerCase().includes("island")
          )
      ) || []
    const mapData =
      filterData.map((country: Country) => ({
        id: country.id.toString(),
        title: country.name,
        iso2: country.iso2,
        phoneCode: country.phone_code
      })) || []
    return mapData as CountryOptions[]
  })

export const getStatesList = (countryId: number) =>
  GetState(countryId).then((result) => {
    const mapData =
      result.map((country: Country) => ({
        id: country.id,
        title: country.name,
        iso2: country.state_code
      })) || []
    return mapData as CountryOptions[]
  })

export const getCitiesList = (countryId: number, stateId: number) =>
  GetCity(countryId, stateId).then((result) => {
    const mapData =
      result.map((country: Country) => ({
        id: country.id,
        title: country.name
      })) || []

    return mapData as CountryOptions[]
  })
