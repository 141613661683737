import * as React from "react"
import "./BillingPage.scss"
import ShippingContainer from "../components/ShippingContainer/ShippingContainer"
import BillingAddress from "../components/BillingAddress/BillingAddress"

export default function BillingPage(): JSX.Element {
  return (
    <ShippingContainer>
      <div className="billing-content">
        <BillingAddress />
      </div>
    </ShippingContainer>
  )
}
