import * as React from "react"
import "./BillingAddress.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { useNavigate } from "react-router-dom"
import ShippingAddressItem from "@/pages/ProfilePage/components/ShippingAddressItem/ShippingAddressItem"
import { useGetBillingAddressQuery } from "@/services/api/user"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { isEmptyObject } from "@/utils/dataHelper"

interface IShippingAddressProps {}

const BillingAddress: React.FC<IShippingAddressProps> = ({}) => {
  useGetBillingAddressQuery()
  const navigate = useNavigate()
  const { billingAddress } = useSelector<RootState, IUserStore>((s) => s.user)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)

  React.useEffect(() => {}, [billingAddress])

  const onNavigateToEdit = () => {
    navigate("/profile/billing-address/edit")
  }

  const onNavigateToCreate = () => {
    navigate("/profile/billing-address/create")
  }

  return (
    <div>
      <div className="billing-address-header">
        <h3>Billing Address</h3>
      </div>

      {userInfoNew.last_billing_address &&
      !isEmptyObject(userInfoNew.last_billing_address) ? (
        <>
          <div className="">
            <div className="billing-address-content">
              <ShippingAddressItem
                data={userInfoNew.last_billing_address}
                labelL="Recipient's name"
                labelR="Address"
              />
            </div>

            <div className="billing-address-footer">
              <Button
                customType={Type.PRIMARY}
                hierarchy={ButtonHierarchy.OUTLINE}
                customSize={Size.MEDIUM}
                onClick={onNavigateToEdit}
                customClassName="billing-address-footer-btn"
              >
                Edit
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="billing-address-content">
            <div className="billing-address-empty">
              <p>
                You don’t have Billing Address. Please add one to proceed with
                the order
              </p>
            </div>
          </div>
          <div>
            <Button
              customType={Type.PRIMARY}
              hierarchy={ButtonHierarchy.OUTLINE}
              customSize={Size.MEDIUM}
              onClick={onNavigateToCreate}
              customClassName="billing-address-empty-btn"
            >
              Add Address
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default BillingAddress
