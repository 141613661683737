import { uploadIcon } from "@/assets"
import { UploadFile, UploadProps } from "antd"
import Dragger from "antd/es/upload/Dragger"
import React, { FC } from "react"
import CustomItemFileRender from "../CustomItemFileRender/CustomItemFileRender"
import "./Upload.scss"
import { debounce } from "@/utils/functionHelper"

interface CustomUploadProps extends UploadProps {
  onUploadFiles?: (info: { file: UploadFile; fileList: UploadFile[] }) => void
  beforeUpload?: (file: UploadFile) => void
  onRemoveFile?: (file: UploadFile) => void
  files?: UploadFile[]
  onAllDone?: (fileList: UploadFile[]) => void
  isShowUploadList?: boolean
  uploadHint?: string
  customClass?: string
  isLoading?: boolean
}

const CustomUpload: FC<CustomUploadProps> = (props) => {
  const {
    onUploadFiles,
    onRemoveFile,
    isShowUploadList,
    customClass,
    uploadHint = "STL, OBJ, WRL, STEP, STP,  IGES, IGS, 3MF, DXF, DWG, ZIP",
    files = [],
    onAllDone = (_: UploadFile[]) => [],
    beforeUpload,
    isLoading,
    ...restProps
  } = props
  const debounceAllDone = debounce(onAllDone, 500)

  const handleChange = (info: { file: UploadFile; fileList: UploadFile[] }) => {
    if (onUploadFiles) onUploadFiles(info)

    debounceAllDone(info.fileList)
  }

  const customUploadProps: UploadProps = {
    ...restProps,
    name: "file",
    multiple: true,
    onChange: handleChange,
    beforeUpload: async (file: UploadFile) => {
      if (beforeUpload) await beforeUpload(file)
      return false
    },
    showUploadList: isShowUploadList,
    fileList: files,
    itemRender: (
      _: React.ReactElement,
      file: UploadFile,
      fileList: UploadFile[],
      actions: {
        download: () => void
        preview: () => void
        remove: (file: UploadFile) => void
      }
    ) =>
      isShowUploadList &&
      CustomItemFileRender({
        file,
        fileList,
        actions: {
          download: actions.download,
          preview: actions.preview,
          remove: onRemoveFile ? () => onRemoveFile(file) : actions.remove
        }
      })
  }

  return (
    <div style={{ position: "relative" }}>
      <Dragger
        {...customUploadProps}
        className={`custom-dragger ${customClass}`}
      >
        <div className="upload-content">
          <img src={uploadIcon} className="upload-icon" />
          <p className="upload-text">
            <span>Click to upload</span> or drag & drop
          </p>
          <p className="upload-hint">{uploadHint}</p>
        </div>
      </Dragger>
    </div>
  )
}

export default CustomUpload
