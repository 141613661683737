import { Button, Loading } from "@/components"
import "./OrderDetailSummary.scss"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { priceDisplay } from "@/utils/functionHelper"
import { useLocation, useNavigate } from "react-router-dom"
import { EOrderStatus } from "@/enums/quotesList.enum"
function OrderDetailSummary() {
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (!quoteDetail) {
    return <Loading></Loading>
  }
  const { models_price, delivery_price, total_price } = quoteDetail
  const invoice_id = quoteDetail?.main_invoice?.id
  const invoice_hash = quoteDetail?.main_invoice?.pay_url.split("/").pop()

  const handleInvoice = () => {
    if (pathname.includes("quotes"))
      return navigate(`/quotes/invoices/${invoice_id}/${invoice_hash}`, {
        replace: true
      })
    if (pathname.includes("orders"))
      return navigate(`/orders/invoices/${invoice_id}/${invoice_hash}`, {
        replace: true
      })
    if (pathname.includes("new-quote"))
      return navigate(
        `/new-quote/checkout/${quoteDetail.id}/submitted/invoices/${invoice_id}/${invoice_hash}`,
        {
          replace: true
        }
      )
  }

  return (
    <div className="order-detail-summary">
      <p className="order-detail-summary-title">Total</p>
      <div className="order-detail-summary-text">
        <div className="text-box">
          <p>Models Price</p>
          <p>${priceDisplay(Number(models_price))}</p>
        </div>
        <div className="text-box">
          <p>Delivery</p>
          <p>${priceDisplay(Number(delivery_price))}</p>
        </div>
        <div className="text-box">
          <p>Subtotal</p>
          <p>${priceDisplay(Number(total_price))}</p>
        </div>
      </div>
      <div className="order-detail-summary-price">
        <p>Total</p>
        <p>${priceDisplay(Number(total_price))}</p>
      </div>
      <div className="order-detail-summary-btn">
        {quoteDetail.status !== EOrderStatus.WAITING_FOR_REVIEW && (
          <Button
            customSize={Size.LARGE}
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={handleInvoice}
            customClassName="order-detail-summary-btn-invoice"
          >
            Invoice Page
          </Button>
        )}
      </div>
    </div>
  )
}

export default OrderDetailSummary
