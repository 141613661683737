import { quoteDetailIcon, tickSaveQuoteIcon } from "@/assets"
import "./SaveQuoteSuccessPage.scss"
import { quoteDetails } from "@/constants/saveQuote.constant"
import QuoteDetail from "./components/QuoteDetail/QuoteDetail"
import BillQuoteDetail from "./components/BillQuoteDetail/BillQuoteDetail"
import { Link } from "react-router-dom"

function SaveQuoteSuccessPage() {
  return (
    <div className="quote-detail">
      <div className="quote-detail-header">
        <div className="quote-detail-header-img">
          <img src={quoteDetailIcon} alt="" />
          <div className="quote-detail-header-img-tick">
            <img src={tickSaveQuoteIcon} alt="" />
          </div>
        </div>
        <div className="quote-detail-text">
          <p className="quote-detail-text-title">
            Your quote number <span>{quoteDetails.quoteId}</span> has been
            successfully generated
          </p>
          <p className="quote-detail-text-sub-title">
            You can view, order, or reject this quote from your{" "}
            <Link to="/quotes">
              <span>Quote</span>
            </Link>{" "}
            page
          </p>
        </div>
      </div>
      <QuoteDetail data={quoteDetails}></QuoteDetail>
      <BillQuoteDetail data={quoteDetails}></BillQuoteDetail>
    </div>
  )
}

export default SaveQuoteSuccessPage
