import RootContainer from "@/components/RootContainer/RootContainer"
import "./CheckoutPage.scss"
import OrderSummary from "./components/OrderSummary/OrderSummary"
import { Button, HeadingCollapse } from "@/components"
import {
  DeliveryIcon,
  downloadIcon,
  OrderReviewIcon,
  PaymentIcon,
  ShippingIcon
} from "@/assets"
import { useLazyGetQuoteQuery } from "@/services/api/quote"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { useLazyGetModelsDetailQuery } from "@/services/apiDigifabster/model"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import {
  IQuoteStore,
  setDoneKeysCheckout,
  setQuoteShippingPrice
} from "@/store/quote"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import DeliveryAddress from "./components/DeliveryAddress/DeliveryAddress"
import OrderReview from "./components/OrderReview/OrderReview"
import ShippingMethod from "./components/ShippingMethod/ShippingMethod"
import Payment from "./components/Payment/Payment"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import {
  ECollapseKey,
  EPaymentMethod,
  EShippingMethod
} from "@/enums/checkout.enum"
import { Collapse, RadioChangeEvent, UploadFile, UploadProps } from "antd"
import { debounce } from "@/utils/functionHelper"
import {
  useSubmitOrderMutation,
  useUpdateInvoiceMutation
} from "@/services/apiDigifabster/order"
import * as toast from "@/utils/Toast"
import { RcFile } from "antd/es/upload"
import { EOrderStatus } from "@/enums/quotesList.enum"
import {
  ShippingRateResponse,
  useGetShippingRateMutation
} from "@/services/apiDigifabster/shipping"
import { INotesForm } from "@/interfaces/notes.interface"

export default function CheckoutPage(): JSX.Element {
  const { currentQuote, doneKeysCheckout } = useSelector<
    RootState,
    IQuoteStore
  >((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  let { quoteId } = useParams()
  const [getQuote, { isLoading }] = useLazyGetQuoteQuery()
  const [
    updateInvoice,
    { isLoading: isLoadingUpdateInvoice, isSuccess: isSuccessUpdate }
  ] = useUpdateInvoiceMutation()
  const [getShippingRate, { isLoading: getShippingRateLoading }] =
    useGetShippingRateMutation()
  const [submitOrder, { isLoading: isLoadingSubmitOrder }] =
    useSubmitOrderMutation()

  useAppLoading([
    isLoading,
    isLoadingUpdateInvoice,
    getShippingRateLoading,
    isLoadingSubmitOrder
  ])
  const [getModelDetails] = useLazyGetModelsDetailQuery()
  const [activeKey, setActiveKey] = useState<number>(1)
  const [formData, setFormData] = useState<ICheckoutForm>({} as ICheckoutForm)
  const [shippingMethod, setShippingMethod] = useState(EShippingMethod.DELIVERY)
  const [isEditDelivery, setIsEditDelivery] = useState<boolean>(true)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [paymentMethod, setPaymentMethod] = useState<string>(
    EPaymentMethod.CREDIT_CARD
  )
  const [shippingRate, setShippingRate] = useState<ShippingRateResponse[]>([])
  const dispatch = useDispatch()

  const navigate = useNavigate()
  useEffect(() => {
    if (quoteId) {
      getQuote({ quoteId })
      dispatch(setDoneKeysCheckout([1]))
    }
  }, [])

  useEffect(() => {
    if (!currentQuote || !currentQuote.products) return
    if (currentQuote?.status !== EOrderStatus.INITIAL)
      return navigate(`/new-quote/checkout/${currentQuote?.id}/submitted`)
    const id = currentQuote.products.map((item) => item.model_id).join(",")
    getModelDetails({ id })
  }, [currentQuote])

  const handleChangeCollapse = (key: string | string[]) => {
    const currentKey = Array.isArray(key) ? Number(key[1]) : Number(key)
    const isDoneStep = doneKeysCheckout.includes(currentKey)
    if (key.length === 0 || !isDoneStep) return
    setActiveKey(currentKey)
  }

  const handleEditDelivery = () => {
    setIsEditDelivery((s) => !s)
  }

  const onSubmitNotes = async (data: INotesForm) => {
    setFormData({ ...formData, notes: data.notes })
  }

  const onSubmitDelivery = async (data: IDeliveryForm) => {
    setFormData({ ...formData, delivery_address: data })
    setIsEditDelivery(false)
    const { data: shippingRateRes } = await getShippingRate({
      orderId: currentQuote?.id || 0,
      arg: {
        country: data.country.iso2 || "",
        state: data.state.iso2 || "",
        postalCode: data.zipCode,
        city: data.city
      }
    })

    if (shippingRateRes) {
      setShippingRate(shippingRateRes)
      dispatch(setQuoteShippingPrice(shippingRateRes[0]?.price || 0))
    }
  }

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setFileList([file])
    setFormData({ ...formData, attached_po: file })
  }

  const handleRemoveFile = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
    setFormData({ ...formData, attached_po: {} as UploadFile })
  }

  const onChangePONumber = debounce((value?: string) => {
    setFormData({ ...formData, po_number: value || "" })
  }, 500)

  const onSubmitPayment = (data: IPaymentForm) => {
    const { poFile, poNumber, billing } = data
    setFormData({
      ...formData,
      attached_po: poFile || ({} as any),
      billing,
      po_number: poNumber || ""
    })
    setIsEditDelivery(false)
  }

  const onChangeMethod = (data: {
    method: EShippingMethod
    carrier?: string
    note?: string
  }) => {
    setShippingMethod(data.method)
    if (data.method !== EShippingMethod.DELIVERY) {
      dispatch(setQuoteShippingPrice(0))
    }
    setFormData({
      ...formData,
      shipping_method: data.method,
      shipping_carrier: data.carrier,
      shipping_note: data.note
    })
  }

  const handleChangePaymentMethod = (e: RadioChangeEvent) => {
    setPaymentMethod(e.target.value)
  }

  const handleSubmit = async () => {
    try {
      if (!currentQuote) return

      if (ECollapseKey.PAYMENT !== activeKey) {
        const nextKey = activeKey + 1
        setActiveKey(nextKey)
        if (!doneKeysCheckout.includes(nextKey))
          dispatch(setDoneKeysCheckout([...doneKeysCheckout, nextKey]))
        return
      }
      const {
        delivery_address,
        billing,
        attached_po,
        po_number,
        shipping_method,
        shipping_carrier,
        shipping_note,
        notes
      } = formData
      const resSubmitOrder = await submitOrder({
        orderId: currentQuote?.id || 0,
        arg: {
          name: delivery_address?.name,
          surname: delivery_address?.surname,
          phone: delivery_address?.phoneNumber,
          email: delivery_address?.email,
          notes: notes,
          shippingMethod: {
            method: shipping_method,
            carrier: shipping_carrier,
            note: shipping_note
          },
          shippingAddress: {
            addressLine1: delivery_address?.addressLine1,
            addressLine2: delivery_address?.addressLine2,
            country: delivery_address?.country.iso2 || "",
            state: delivery_address?.state.iso2 || "",
            city: delivery_address?.city,
            postalCode: delivery_address?.zipCode,
            companyName: delivery_address?.company
          },
          billingAddress:
            paymentMethod === EPaymentMethod.CREDIT_CARD
              ? undefined
              : {
                  addressLine1: billing?.addressLine1,
                  addressLine2: billing?.addressLine2,
                  country: billing?.country.iso2 || "",
                  state: billing?.state.iso2 || "",
                  city: billing?.city,
                  postalCode: billing?.zipCode,
                  companyName: billing?.company,
                  name: billing?.firstName,
                  surname: billing?.lastName,
                  phone: billing?.phoneNumber,
                  email: billing?.accountPayEmail
                }
        }
      })
      const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
      if (resSubmitOrder.error || !resSubmitOrder.data)
        throw new Error(errorMsg)
      if (paymentMethod === EPaymentMethod.PURCHASE_ORDER) {
        const file = attached_po.originFileObj
        const uploadPayload = new FormData()
        if (file)
          uploadPayload.append("attached_po", file as RcFile, file?.name)
        if (po_number) uploadPayload.append("po_number", po_number)

        const payloadUpdateInvoice = {
          invoice_id: Number(resSubmitOrder.data.invoiceId),
          invoice_hash: resSubmitOrder.data.invoiceHash,
          arg: uploadPayload
        }
        await updateInvoice(payloadUpdateInvoice)
      }
      window.location.href = resSubmitOrder.data.payUrl
    } catch (err) {
      console.log("err", err)
      const message = err as any
      toast.showError(message.message)
    }
  }

  const submitText = useMemo(() => {
    if (ECollapseKey.PAYMENT === activeKey) {
      if (EPaymentMethod.CREDIT_CARD === paymentMethod) return "Place Order"
      return "Submit Order"
    }
    return "Continue"
  }, [activeKey, paymentMethod])

  const disabled = useMemo(() => {
    const { delivery_address, billing, shipping_carrier, shipping_method } =
      formData
    const isDonePreSteps = Boolean(
      delivery_address?.email &&
        delivery_address?.addressLine1 &&
        delivery_address?.city &&
        delivery_address?.phoneNumber &&
        delivery_address?.state &&
        delivery_address?.country &&
        delivery_address?.zipCode &&
        delivery_address?.name &&
        delivery_address?.surname &&
        delivery_address?.phoneNumber
    )
    const isDoneBilling = Boolean(
      billing?.accountPayEmail &&
        billing?.addressLine1 &&
        billing?.city &&
        billing?.phoneNumber &&
        billing?.state &&
        billing?.country &&
        billing?.zipCode &&
        billing?.firstName &&
        billing?.lastName &&
        billing?.phoneNumber
    )

    switch (activeKey) {
      case ECollapseKey.DELIVERY_ADDRESS:
        return !isDonePreSteps
      case ECollapseKey.SHIPPING_METHOD:
        if (shipping_method !== EShippingMethod.LOCAL_PICKUP) {
          return !shipping_carrier
        }
        return false
      case ECollapseKey.PAYMENT:
        if (paymentMethod !== EPaymentMethod.CREDIT_CARD) {
          return !(isDonePreSteps && isDoneBilling)
        }
        return false
      default:
        return false
    }
  }, [activeKey, formData, paymentMethod, fileList])

  const items = [
    {
      key: ECollapseKey.ORDER_REVIEW,
      label: (
        <>
          <HeadingCollapse
            title="Order Review"
            prefix={OrderReviewIcon}
            subtitle="Review quantities and production timelines."
          />
        </>
      ),
      children: <OrderReview onSubmit={onSubmitNotes} />
    },
    {
      key: ECollapseKey.DELIVERY_ADDRESS,
      label: (
        <>
          <HeadingCollapse
            title="Delivery Address"
            prefix={DeliveryIcon}
            subtitle="Enter the address where your order will be delivered."
          />
        </>
      ),
      children: (
        <DeliveryAddress
          onSubmit={onSubmitDelivery}
          prefillData={formData.delivery_address}
          isEdit={isEditDelivery}
          handleEdit={handleEditDelivery}
        />
      )
    },
    {
      key: ECollapseKey.SHIPPING_METHOD,
      label: (
        <>
          <HeadingCollapse
            title="Shipping Method"
            prefix={ShippingIcon}
            subtitle="Select your shipping method and delivery speed."
          />
        </>
      ),
      children: (
        <ShippingMethod
          shippingRate={shippingRate}
          value={shippingMethod}
          onChange={onChangeMethod}
        />
      )
    },
    {
      key: ECollapseKey.PAYMENT,
      label: (
        <>
          <HeadingCollapse
            title="Payment"
            prefix={PaymentIcon}
            subtitle="Select your payment method to complete the purchase."
          />
        </>
      ),
      children: (
        <Payment
          onSubmit={onSubmitPayment}
          prefillData={formData}
          fileList={fileList}
          handleRemove={handleRemoveFile}
          handleChangePaymentMethod={handleChangePaymentMethod}
          value={paymentMethod}
          handleChange={handleChangeFile}
          handleChangePONumber={onChangePONumber}
        />
      )
    }
  ]

  return (
    <RootContainer
      onBack={() => navigate(`/new-quote/specification/${currentQuote?.id}`)}
      headerText="Checkout"
      subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
    >
      <div className="checkout-container">
        <div className="checkout-left">
          <div className="checkout-review-container">
            <div className="checkout-review">
              <Collapse
                ghost
                items={items}
                expandIconPosition="end"
                onChange={handleChangeCollapse}
                activeKey={activeKey}
              />
            </div>
          </div>
        </div>
        <div className="checkout-right">
          <OrderSummary />
          <Button
            customClassName="checkout-btn"
            customSize={Size.LARGE}
            onClick={handleSubmit}
            disabled={disabled}
          >
            {submitText}
          </Button>
          <Button
            customClassName="checkout-download-btn"
            customSize={Size.LARGE}
            hierarchy={ButtonHierarchy.TONAL}
            customType={Type.NEUTRAL}
            onClick={() => {
              window.open(currentQuote?.pdf_url || "", "_blank")
            }}
          >
            <img src={downloadIcon} alt="" />
            Download Draft Quote
          </Button>
          <div className="checkout-question">
            Have Questions?{" "}
            <a href={`mailto:${settings.support_email}`}>Ask for help</a>
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
