import { EUnit } from "@/enums/unit.enum"

export const UNIT_OPTIONS = [
  {
    id: EUnit.MM,
    title: "Millimeters"
  },
  {
    id: EUnit.CM,
    title: "Centimeter"
  },
  {
    id: EUnit.IN,
    title: "Inch"
  }
]
