import { Button } from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import BackToLogin from "../components/BackToLogin/BackToLogin"
import { useSearchParams } from "react-router-dom"
import { TokenType, useResendEmailMutation } from "@/services/api/auth"
import { useAppLoading } from "@/hooks/useLoading"
import { useEffect } from "react"
import { showError, showSuccess } from "@/utils/Toast"
import { useSendMailForgotPasswordMutation } from "@/services/apiDigifabster/auth"

const NotifyResetPassword: React.FC = () => {
  const [handleForgotPassword, { isLoading, isSuccess, error }] =
    useSendMailForgotPasswordMutation()
  useAppLoading([isLoading])

  useEffect(() => {
    if (!error) return

    showError("Resend email error")
  }, [error])

  useEffect(() => {
    if (!isSuccess) return

    showSuccess("Email sent")
  }, [isSuccess])

  const [searchParams] = useSearchParams()

  const handleResendEmail = () => {
    handleForgotPassword({
      email: String(searchParams.get("email"))
    })
  }

  return (
    <div className="forgot-pw-page-notify">
      <div>
        We have sent you an email with a link to reset your password.
        <br></br>
        Please check your email.
      </div>
      <p>
        Haven't received the verification email yet?
        <Button
          hierarchy={ButtonHierarchy.LINK}
          customSize={Size.EXTRA_LARGE}
          customClassName="btn-resend-email"
          onClick={handleResendEmail}
        >
          Resend Email
        </Button>
      </p>
      <BackToLogin justify="start" />
    </div>
  )
}

export default NotifyResetPassword
