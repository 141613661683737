import { SelectProps } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { CountryOptions, IPhoneData } from "@/interfaces/common.interface"
import TextField from "../TextField/TextField"
import "./PhoneInput.scss"
import SelectDropdown from "../SelectDropdown/SelectDropdown"
import { getCountriesList } from "@/constants/countries"
import { FieldError } from "react-hook-form"

interface PhoneInputProps {
  data: IPhoneData
  handleChange: (phone: IPhoneData) => void
  errors?: FieldError | undefined
}

type LabelRender = SelectProps["labelRender"]

const DEFAULT_CODE_ID = 39
const DEFAULT_CODE = "1"

export const PhoneInput: React.FC<PhoneInputProps> = ({
  data,
  handleChange,
  errors
}) => {
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [selectedCode, setSelectedCode] = useState<string>(
    data?.countryCode || DEFAULT_CODE
  )
  const [selectedCodeId, setSelectedCodeId] = useState(
    data.countryCodeId || DEFAULT_CODE_ID
  )
  const [width, setWidth] = useState(0)
  const phoneInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    getCountries()
    if (phoneInputRef.current) {
      const inputWidth = phoneInputRef.current.offsetWidth
      setWidth(inputWidth)
    }
  }, [])

  useEffect(() => {
    setSelectedCode(data?.countryCode || DEFAULT_CODE)
    setSelectedCodeId(Number(data?.countryCodeId) || DEFAULT_CODE_ID)
  }, [data])

  const getCountries = async () => {
    const countries = await getCountriesList()
    const mapData =
      countries.map((country) => ({
        ...country,
        title: `${country.title} +${country.phoneCode}`
      })) || []
    setCountriesList(mapData)
  }

  const handleChangeCountry = (e: string | number) => {
    const state = countriesList.find((country) => country.id === e)
    const countryCode = state?.phoneCode?.toString() || ""
    const countryCodeId = state?.id?.toString() || ""
    setSelectedCode(countryCode)
    setSelectedCodeId(countryCodeId)
    const tel = {
      ...data,
      countryCode,
      countryCodeId
    }
    handleChange(tel)
  }

  const handleChangeData = (
    phoneNum: string,
    countryCode: string,
    countryCodeId: string
  ) => {
    const tel = {
      phoneNum,
      countryCode,
      countryCodeId
    }
    handleChange(tel)
  }

  const labelRender: LabelRender = (props) => {
    const { label, value } = props

    if (label) {
      return `+${
        countriesList.find((country) => country.id === value)?.phoneCode
      }`
    }
    return ""
  }

  const handleChangeTextFiled = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeData(
      e.target.value,
      selectedCode || "",
      selectedCodeId.toString() || ""
    )
  }

  return (
    <div className="phone-input-wrapper" ref={phoneInputRef}>
      <SelectDropdown
        placeholder="+1"
        placeholderSearch="Search country"
        onChange={handleChangeCountry}
        defaultValue={selectedCodeId}
        value={selectedCodeId}
        options={countriesList}
        virtual={false}
        searchBar={true}
        width={52}
        height={48}
        suffixIcon={null}
        labelRender={labelRender}
        status={errors ? "error" : ""}
        popupMatchSelectWidth={width}
      ></SelectDropdown>

      <TextField
        placeholder="Enter your phone number"
        type="number"
        status={errors ? "error" : ""}
        onChange={handleChangeTextFiled}
        name="phone"
        autoComplete="tel"
        value={data.phoneNum}
      />
    </div>
  )
}
