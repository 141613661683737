import "./CreateShippingPage.scss"
import ShippingContainer from "../../components/ShippingContainer/ShippingContainer"
import ShippingForm from "../../components/ShippingForm/ShippingForm"
import { useForm } from "react-hook-form"
import { IShippingForm } from "@/interfaces/shipping.interface"
import { yupResolver } from "@hookform/resolvers/yup"
import { SHIPPING_SCHEMA } from "@/validations/shipping.validation"
import { useNavigate } from "react-router-dom"
import {
  useAddShippingAddressMutation,
  useLazyGetShippingAddressesQuery
} from "@/services/api/user"
import { useAppLoading } from "@/hooks/useLoading"
import { useEffect } from "react"
import { showError, showSuccess } from "@/utils/Toast"
import { DEFAULT_FORM_VALUES } from "@/constants/shipping.constants"

export default function CreateShippingPage(): JSX.Element {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm<IShippingForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(SHIPPING_SCHEMA)
  })
  const navigate = useNavigate()
  const [addShippingAddress, { isLoading, isError, isSuccess }] =
    useAddShippingAddressMutation()
  const [getShippingAddresses] = useLazyGetShippingAddressesQuery()

  useAppLoading([isLoading])

  useEffect(() => {
    if (isError) {
      showError("Cannot create shipping address", "")
    }

    if (isSuccess) {
      showSuccess("Create shipping address successfully")
      getShippingAddresses()
      navigate("/profile/shipping-address")
    }
  }, [isError, isSuccess])

  const onSubmit = (data: IShippingForm) => {
    addShippingAddress({ addUpdateShippingAddressDto: data })
  }

  const onCancel = () => {
    navigate("/profile/shipping-address")
  }

  return (
    <ShippingContainer>
      <div className="shipping-content">
        <div className="shipping-address-header">
          <h3>Add Shipping Address</h3>
        </div>
        <div className="">
          <ShippingForm
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            setValue={setValue}
            onCancel={onCancel}
            watch={watch}
          />
        </div>
      </div>
    </ShippingContainer>
  )
}
