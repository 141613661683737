import React from "react"
import "./MethodLocalPickUp.scss"
import { forgeLabIcon } from "@/assets"
import "./MethodLocalPickUp.scss"

function MethodLocalPickUp() {
  return (
    <div>
      <p className="title-pick-up">PICK-UP POINT</p>
      <div className="pick-up-box">
        <div className="img-box">
          <img src={forgeLabIcon} alt="" />
        </div>
        <p>Forge Labs Inc.</p>
        <p>101 - 8337 Eastlake Drive</p>
        <p>Burnaby, BC, V5A 4W2</p>
      </div>
    </div>
  )
}

export default MethodLocalPickUp
