import { REGEX_EMAIL, REQUIRED_ERROR } from "@/constants/common.constant"
import * as yup from "yup"

export const RESET_PASSWORD_SCHEMA = yup.object().shape({
  newPassword: yup
    .string()
    .required("New password is required")
    .min(10, "Password must be at least 10 characters"),
  confirmPassword: yup
    .string()
    .min(10, "Password must be at least 10 characters")
    .oneOf([yup.ref("newPassword")], "Passwords does not match")
    .required("Confirm new password is required")
})

export const RESET_PASSWORD_EMAIL_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format")
})
