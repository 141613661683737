import { UploadFileIcon } from "@/assets"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { Upload, UploadFile, UploadProps } from "antd"
import Button from "../Button/Button"
import CustomItemFileRender from "../CustomItemFileRender/CustomItemFileRender"
import "./UploadFiles.scss"
import { debounce } from "@/utils/functionHelper"

interface UploadFileProps {
  listFiles: UploadFile[]
  onAllDone?: (fileList: UploadFile[]) => void
  uploadText?: string
  onRemove?: (file: UploadFile) => void
  onUploadFiles?: (info: { file: UploadFile; fileList: UploadFile[] }) => void
}

const UploadFiles: React.FC<UploadFileProps> = ({
  onRemove,
  listFiles,
  onAllDone = (_: UploadFile[]) => [],
  uploadText = "Upload files",
  onUploadFiles
}) => {
  const debounceAllDone = debounce(onAllDone, 500)

  const handleChange = (info: { file: UploadFile; fileList: UploadFile[] }) => {
    if (onUploadFiles) onUploadFiles(info)

    debounceAllDone(info.fileList)
  }

  const uploadButton = (
    <div className="upload-container">
      <Button
        customClassName="btn-upload"
        customSize={Size.EXTRA_SMALL}
        hierarchy={ButtonHierarchy.TONAL}
        customType={Type.PRIMARY}
      >
        <img src={UploadFileIcon} />
      </Button>
      <span className="upload-text">{uploadText}</span>
    </div>
  )

  const beforeUpload = async (file: UploadFile) => {
    if (beforeUpload) await beforeUpload(file)
    return false
  }

  return (
    <div className="upload-file">
      <Upload
        className="avatar-uploader"
        fileList={listFiles}
        showUploadList={true}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        accept="*/*"
        itemRender={(_, file, fileList) =>
          CustomItemFileRender({
            file,
            fileList,
            actions: {
              download: () => {},
              preview: () => {},
              remove: () => onRemove && onRemove(file)
            },
            showErrorText: false
          })
        }
      >
        {uploadButton}
      </Upload>
    </div>
  )
}

export default UploadFiles
