import "./MethodDelivery.scss"
import { Form, Radio, RadioChangeEvent } from "antd"
import { useEffect, useState } from "react"
import { FormField, RadioButton, TextField } from "@/components"
import { ShippingRateResponse } from "@/services/apiDigifabster/shipping"
import { PurolatorLogo, UpsLogo } from "@/assets"
import { debounce } from "@/utils/functionHelper"
import { useDispatch } from "react-redux"
import { setQuoteShippingPrice } from "@/store/quote"

interface IMethodDeliveryProps {
  data: ShippingRateResponse[]
  onChangeCarrier: (e: string) => void
  onChangeNote: (e: string) => void
}

const logo: Record<string, string> = {
  purolator: PurolatorLogo,
  ups: UpsLogo
}

function MethodDelivery(props: IMethodDeliveryProps) {
  const { data, onChangeCarrier, onChangeNote } = props
  const dispatch = useDispatch()
  const [value, setValue] = useState(data[0] || "")
  const debounceChangeNote = debounce(onChangeNote, 500)

  useEffect(() => {
    setValue(data[0])
  }, [data])

  useEffect(() => {
    onChangeCarrier(value?.code)
    dispatch(setQuoteShippingPrice(value?.price || 0))
  }, [value])

  const handleChangeMethod = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    onChangeCarrier(e.target.value.code)
  }

  const handleText = (value: string) => {
    debounceChangeNote(value)
  }
  return (
    <div className="shipping-method-box-partner">
      <p className="title-method">DELIVERY PARTNER</p>
      {data.map((item) => (
        <div className="shipping-method-box-partner-item" key={item.code}>
          <div>
            <Radio.Group onChange={handleChangeMethod} value={value}>
              <RadioButton value={item}></RadioButton>
            </Radio.Group>
          </div>
          <div className="img-box">
            <img src={logo[item.code]} alt="" />
            <p className="img-box-name">{item.name}</p>
          </div>
          <p className="time-text">{item.service}</p>
          <p className="price-text">${item.price}</p>
        </div>
      ))}
      <Form>
        <FormField textLabel="DELIVERY INSTRUCTIONS">
          <TextField
            placeholder="Delivery instructions"
            onChange={(e) => handleText(e.currentTarget.value)}
          ></TextField>
        </FormField>
      </Form>
    </div>
  )
}

export default MethodDelivery
