import { createSlice } from "@reduxjs/toolkit"
import { extraReducers } from "./extraReducers"
import { authReducers } from "./reducers"

export interface IAuthStore {
  appInited: boolean
  isAuthenciated: boolean
}
const initialState: IAuthStore = { appInited: false, isAuthenciated: false }
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: authReducers,
  extraReducers: extraReducers
})
export const { updateAppInit, updateAuthenticated } = authSlice.actions
export const authReducer = authSlice.reducer
