import * as React from "react"
import "./ShippingMethodItem.scss"
import { IShippingMethod } from "@/interfaces/shipping.interface"

interface IShippingMethodItemProps {
  method: IShippingMethod
}

const ShippingMethodItem: React.FC<IShippingMethodItemProps> = ({ method }) => {
  return (
    <div className="method-wrapper">
      <div className="method-left">
        <img
          src={method.logoSrc}
          height={40}
          width={40}
          alt={`${method.title} logo`}
        />
        <p>{method.title}</p>
      </div>
      <p className="method-right">{method.description}</p>
    </div>
  )
}

export default ShippingMethodItem
