import { CheckCircleFilled } from "@ant-design/icons"
import { Flex } from "antd"
import BackToLogin from "../components/BackToLogin/BackToLogin"
const SuccessResetPassword: React.FC = () => {
  return (
    <div className="forgot-pw-page-confirm">
      <Flex align="center" gap={4} style={{ marginBottom: 40 }}>
        <div className="forgot-pw-page-confirm-text">
          Your password has been changed!
        </div>
        <CheckCircleFilled />
      </Flex>
      <BackToLogin justify="start" />
    </div>
  )
}

export default SuccessResetPassword
