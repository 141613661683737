import { api } from "../apiInstance"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<TokenResponse, RegisterApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfc/auth/register/`,
        method: "POST",
        body: queryArg
      })
    }),

    login: build.mutation<TokenResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/api/dgfo/obtain_auth_token/`,
        method: "POST",
        body: queryArg
      })
    }),

    sendMailForgotPassword: build.mutation<void, ForgotPasswordDto>({
      query: (queryArg) => ({
        url: `/api/dgfc/auth/forgot-password`,
        method: "POST",
        body: queryArg
      })
    }),
    resetPassword: build.mutation<void, NewPasswordDto>({
      query: (queryArg) => ({
        url: `/api/dgfc/auth/reset-password`,
        method: "POST",
        body: queryArg
      })
    }),
    googleLogIn: build.mutation<GoogleLogInResponse, GoogleLogInPayload>({
      query: (queryArg) => ({
        url: `/api/dgfc/auth/google-signin`,
        method: "POST",
        body: queryArg
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as authService }

export interface TokenResponse {
  token: string
}

export interface RegisterApiArg {
  companyName: string
  email: string
  password: string
  firstName: string
  lastName: string
  phone: string
  position?: string
  companyTitle?: string
}

export interface LoginApiArg {
  company_name: string
  email: string
  password: string
}
export type ForgotPasswordDto = {
  email: string
}
export type NewPasswordDto = {
  token: string
  email: string
  newPassword: string
}

export interface GoogleLogInResponse {
  token: string
}

export interface GoogleLogInPayload {
  idToken: string
}

export const {
  useRegisterMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useSendMailForgotPasswordMutation,
  useGoogleLogInMutation
} = injectedRtkApi
