import React from "react"
import "./InputView.scss"
import { Size } from "@/enums/common.enum"

interface IInputViewProps {
  size?: Size.SMALL | Size.MEDIUM
  children?: React.ReactNode
  customClass?: string
}
const InputView = (props: IInputViewProps): JSX.Element => {
  const { size = Size.MEDIUM, children, customClass } = props
  const inputSizes = {
    [Size.SMALL]: "input-view-small",
    [Size.MEDIUM]: "input-view-medium"
  }

  const inputSize = inputSizes[size]
  return (
    <div className={`input-view ${inputSize} ${customClass}`}>{children}</div>
  )
}

export default InputView
