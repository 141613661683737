import { FedExLogo, PurolatorLogo, UpsLogo } from "@/assets"
import { ESHIPPING_METHOD } from "@/enums/shipping.enums"
import { DEFAULT_COUNTRY_ID } from "./common.constant"

export const SHIPPING_METHODS = [
  {
    value: ESHIPPING_METHOD.FEDEX,
    title: "FedEx",
    description: "Next day delivery",
    logoSrc: FedExLogo
  },
  {
    value: ESHIPPING_METHOD.UPS,
    title: "UPS",
    description: "2-4 days delivery",
    logoSrc: UpsLogo
  },
  {
    value: ESHIPPING_METHOD.PUROLATOR,
    title: "Purolator",
    description: "3-5 days delivery",
    logoSrc: PurolatorLogo
  }
]

export const DEFAULT_FORM_VALUES = {
  country: { id: DEFAULT_COUNTRY_ID, name: "Canada", iso2: "CA" },
  phone: {}
}

export const MOCK_DATA_SHIPPING_2 = {
  id: 1,
  firstName: "Emily",
  lastName: "Address 1",
  company: "Company 1",
  country: {
    iso2: "VN",
    name: "Vietnam",
    id: 240
  },
  state: {
    iso2: "57",
    name: "Bình Dương",
    id: 3785
  },
  city: {
    name: "Dĩ An",
    id: 130198
  },
  zipCode: "100000",
  address: "Nbr 22, road number 3, Tran Thai Tong Street",
  addressNote: "Near the park",
  delivery: "delivery 1",
  phone: {
    countryCode: "226",
    countryCodeId: "35",
    phoneNum: "+22609123444"
  },
  email: "davidtt@skiff.com"
}
export const MOCK_DATA_SHIPPING_1 = {
  id: 2,
  firstName: "Emily",
  lastName: "Address 2",
  company: "Company 2",
  country: {
    iso2: "VN",
    name: "Vietnam",
    id: 240
  },
  zipCode: "100000",
  address: "Nbr 22, road number 3, Tran Thai Tong Street",
  addressNote: "Near the park",
  delivery: "delivery 1",
  phone: {
    countryCode: "226",
    countryCodeId: "35",
    phoneNum: "+22623453444"
  },
  email: "davidtt1@skiff.com"
}
export const MOCK_DATA_SHIPPINGS = [MOCK_DATA_SHIPPING_2, MOCK_DATA_SHIPPING_1]
