import React from "react"
import { Navigate } from "react-router-dom"

interface ProtectedRouteProps {
  children: any
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // const token = localStorage.getItem("accessToken")

  // if (!token) return <Navigate to={"/auth/sign-in"} />

  return children
}

export default ProtectedRoute
