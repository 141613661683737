import { useEffect } from "react"
import "./RegisterSuccessPage.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { useSearchParams } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { TokenType, useResendEmailMutation } from "@/services/api/auth"
import { useAppLoading } from "@/hooks/useLoading"
import { showError, showSuccess } from "@/utils/Toast"

function RegisterSuccessPage() {
  const [resendEmail, { isLoading, isSuccess, isError }] =
    useResendEmailMutation()
  const [searchParams] = useSearchParams()

  useAppLoading([isLoading])

  useEffect(() => {
    if (isError) {
      showError("Error", "cannot send email")
    }

    if (isSuccess) {
      showSuccess("Success", "sent email")
    }
  }, [isSuccess, isError])

  const handleResendEmail = () => {
    resendEmail({
      resendEmailDto: {
        email: String(searchParams.get("email")),
        tokenType: TokenType.VerifyEmail
      }
    })
  }

  return (
    <div className="register-success-page-wrapper">
      <h3>Register</h3>
      <p>
        We have sent you an email with a link to verify your email. <br />
        Please check your email.
      </p>
      <p>
        Haven't received the verification email yet?{" "}
        <Button
          hierarchy={ButtonHierarchy.LINK}
          customSize={Size.EXTRA_LARGE}
          customClassName="btn-resend-email"
          onClick={handleResendEmail}
        >
          Resend Email
        </Button>
      </p>

      <a href="/auth/register">
        <div className="btn-navigate">
          <ArrowLeftOutlined />
          <div className="btn-navigate-text">Back to Register page</div>
        </div>
      </a>
    </div>
  )
}

export default RegisterSuccessPage
