import { Form, Radio, RadioChangeEvent } from "antd"
import { useEffect, useState } from "react"
import { Button, FormField, RadioButton, TextField } from "@/components"
import "./MethodCollect.scss"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { METHOD_SCHEMA } from "@/validations/method-shipping.validation"
import { mockMethod } from "@/constants/method-shipping.constant"
import { debounce } from "@/utils/functionHelper"
import { errorStatus } from "@/constants/common.constant"
interface IAccountNumberFormInput {
  accountNumber: string
}
interface IMethodCollectProps {
  onChangeCarrier: (e: string) => void
  onChangeNote: (e: string) => void
}

function MethodCollect(props: IMethodCollectProps) {
  const { onChangeCarrier, onChangeNote } = props
  const [value, setValue] = useState("Purolator")
  const debounceChangeNote = debounce(onChangeNote, 500)

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IAccountNumberFormInput>({
    defaultValues: {
      accountNumber: ""
    },
    resolver: yupResolver(METHOD_SCHEMA)
  })

  useEffect(() => {
    onChangeCarrier(value)
  }, [value])

  const handleChangeMethod = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  const onSubmit = (data: IAccountNumberFormInput) => {
    debounceChangeNote(data.accountNumber)
  }

  return (
    <div className="shipping-method-box-collect">
      <p className="title-method">SELECT COURIER</p>
      {mockMethod.map((item, index) => (
        <div className="shipping-method-box-collect-item" key={index + 1}>
          <div>
            <Radio.Group onChange={handleChangeMethod} value={value}>
              <RadioButton value={item.name}></RadioButton>
            </Radio.Group>
          </div>
          <div className="img-box">
            <img src={item.img} alt="" />
            <p className="img-box-name">{item.name}</p>
          </div>
          <p className="time-text">{item.time}</p>
        </div>
      ))}
      {value === "FedEx" && (
        <Form onFinish={handleSubmit(onSubmit)}>
          <FormField
            textLabel="ACCOUNT NUMBER"
            errorText={errors.accountNumber?.message}
          >
            <Controller
              name="accountNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  status={errorStatus(errors.accountNumber)}
                  placeholder="Enter shipping account number"
                ></TextField>
              )}
            ></Controller>
          </FormField>
          <div className="btn-save-account-number">
            <Button htmlType="submit">Save</Button>
            <Button customType={Type.NEUTRAL} hierarchy={ButtonHierarchy.LINK}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}

export default MethodCollect
