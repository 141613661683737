import React from "react"
import "./HeadingCollapse.scss"

interface IHeadingCollapseProps {
  prefix?: string
  title: string
  subtitle?: string
}
const HeadingCollapse: React.FC<IHeadingCollapseProps> = ({
  prefix,
  title,
  subtitle
}) => {
  return (
    <div className="heading-collapse-container">
      {prefix && <img src={prefix} />}
      <div className="">
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}

export default HeadingCollapse
