import { REGEX_EMAIL, REQUIRED_ERROR } from "@/constants/common.constant"
import { object, ref, string } from "yup"

export const infoSchema = object({
  firstName: string().required(REQUIRED_ERROR("First name")),
  lastName: string().required(REQUIRED_ERROR("Last name")),
  email: string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format")
})

export const changePasswordSchema = object({
  currentPassword: string().required(REQUIRED_ERROR("Current password")),
  newPassword: string()
    .required(REQUIRED_ERROR("New password"))
    .min(10, "Password must be at least 10 characters"),
  confirmPassword: string()
    .oneOf([ref("newPassword")], "Confirm new password does not match")
    .required(REQUIRED_ERROR("Confirm password"))
})
