import "./ShippingPage.scss"
import ShippingContainer from "../components/ShippingContainer/ShippingContainer"
import ShippingAddress from "../components/ShippingAddress/ShippingAddress"
import ShippingMethod from "../components/ShippingMethod/ShippingMethod"

export default function ShippingPage(): JSX.Element {
  return (
    <ShippingContainer>
      <div className="shipping-address shipping-content">
        <ShippingAddress />
      </div>

      <div className="shipping-method shipping-content">
        <ShippingMethod />
      </div>
    </ShippingContainer>
  )
}
