// export enum QuoteStatus {
//   ACTIVE = "Active",
//   EXPIRED = "Expired",
//   REJECT = "Reject"
// }

export enum QuoteStatus {
  QUOTE_SENT = "Quote Sent",
  WAITING_FOR_REVIEW = "Waiting for review",
  REJECT = "Reject"
}

export enum EOrderStatus {
  WAITING_FOR_REVIEW = "waiting_for_review",
  PLACED = "placed",
  FIRM_OFFER_SENT = "firm_offer_sent",
  INITIAL = "initial",
  CREATED = "created",
  PO_PROVIDED = "po_provided"
}
