export enum EShippingSplitOptions {
  TOGETHER = 1,
  AS_SOON_AS_POSSIBLE = 2
}

export enum EShippingMethod {
  DELIVERY = "delivery",
  COLLECT = "collect",
  LOCAL_PICKUP = "pick_up"
}

export enum EPaymentMethod {
  CREDIT_CARD = "Credit Card",
  PURCHASE_ORDER = "Purchase Order"
}

export enum ECollapseKey {
  ORDER_REVIEW = 1,
  DELIVERY_ADDRESS = 2,
  SHIPPING_METHOD = 3,
  PAYMENT = 4
}
