import {
  REQUIRED_ERROR,
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER
} from "@/constants/common.constant"
import * as yup from "yup"

export const REGISTER_SCHEMA = yup.object().shape({
  firstName: yup.string().required(REQUIRED_ERROR("First name")),
  lastName: yup.string().required(REQUIRED_ERROR("Last name")),
  email: yup
    .string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format"),

  phone: yup
    .string()
    .required(REQUIRED_ERROR("Phone number"))
    .test("phone-number", "Invalid format", (value) => {
      if (value) {
        return REGEX_PHONE_NUMBER.test(value)
      }
      return true
    }),

  password: yup
    .string()
    .required(REQUIRED_ERROR("Password"))
    .min(10, "Password must be at least 10 characters")
})
