import { ISaveQuote } from "@/interfaces/saveQuote.interface"
import "./BillQuoteDetail.scss"
import { priceDisplay } from "@/utils/functionHelper"

interface IBillQuoteDetailProps {
  data: ISaveQuote
}

function BillQuoteDetail({ data }: IBillQuoteDetailProps) {
  const {
    shipping,
    tax,
    totalAmount,
    totalExcludeTax,
    discount = 0
  } = data.billDetail
  return (
    <div className="box">
      <div className="bill">
        <div className="bill-detail">
          <p className="bill-detail-title">Total excl. Tax</p>
          <p className="bill-detail-price">${priceDisplay(totalExcludeTax)}</p>
        </div>
        <div className="bill-detail">
          <p className="bill-detail-title">Discount</p>
          <p className="bill-detail-price">${priceDisplay(discount)}</p>
        </div>
        <div className="bill-detail">
          <p className="bill-detail-title">Taxes</p>
          <p className="bill-detail-price">${priceDisplay(tax)}</p>
        </div>
        <div className="bill-detail">
          <p className="bill-detail-title">Shipping</p>
          <p className="bill-detail-price">${priceDisplay(shipping)}</p>
        </div>
        <div className="bill-detail">
          <p className="bill-detail-title">Total Amount</p>
          <p className="bill-detail-amount">${totalAmount}</p>
        </div>
      </div>
    </div>
  )
}

export default BillQuoteDetail
