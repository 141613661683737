import { useEffect, useMemo, useState } from "react"
import { Pagination } from "@/components"
import RootContainer from "@/components/RootContainer/RootContainer"
import "./OrdersPage.scss"
import OrderListTable from "./components/OrderListTable/OrderListTable"
import { useGetListQuoteQuery } from "@/services/apiDigifabster/quote"
import { Order } from "@/interfaces/orderListTable.interface"
import { useLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useNavigate } from "react-router-dom"
import { priceDisplay } from "@/utils/functionHelper"

export default function OrdersPage(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(15)

  const { data, isError, refetch } = useGetListQuoteQuery({
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
    ordering: "-date",
    status__in: [],
    status__not__in: [
      EOrderStatus.INITIAL,
      EOrderStatus.CREATED,
      EOrderStatus.WAITING_FOR_REVIEW,
      EOrderStatus.FIRM_OFFER_SENT
    ]
  })

  const { openLoading, closeLoading } = useLoading()
  const navigate = useNavigate()

  useEffect(() => {
    const loadingOrdersList = async () => {
      openLoading()
      try {
        await refetch()
      } catch {
        closeLoading()
      } finally {
        closeLoading()
      }
    }
    loadingOrdersList()
  }, [currentPage, pageSize, refetch])

  const orderData: Order[] = useMemo(() => {
    return (
      data?.results?.map((order) => ({
        no: order.company_order_id,
        orderDate: order.date,
        total: `$${priceDisplay(order.total_price)}`,
        quoteStatus: order.status_display,
        status: order.status,
        id: order.id
      })) || []
    )
  }, [data, currentPage, pageSize])

  const handleSizeChange = (current: number, size: number) => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const handleChange = (page: number) => {
    setCurrentPage(page)
  }

  const onDetail = (id: number) => {
    navigate(`/orders/${id}`)
  }

  if (isError) return <div>Error fetching orders</div>

  return (
    <RootContainer headerText={`All Orders (${data?.count || 0})`}>
      <div className="order">
        <div className="order-container">
          <OrderListTable data={orderData} onDetail={onDetail} />
          <div className="order-container-pagination">
            {!!data?.count && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data?.count || 0}
                onChange={handleChange}
                onShowSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
