import { createSlice } from "@reduxjs/toolkit"

export interface IAppStore {
  appInited: boolean
}

const initialState: IAppStore = { appInited: false }
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {}
})
export const {} = appSlice.actions
export const appReducer = appSlice.reducer
