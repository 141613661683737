import "./ShippingMethod.scss"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { SHIPPING_METHODS } from "@/constants/shipping.constants"
import ShippingMethodItem from "../ShippingMethodItem/ShippingMethodItem"
import { Radio, RadioChangeEvent } from "antd"
import { FC, useEffect, useState } from "react"
import {
  Button,
  FormField,
  TextField,
  InputView,
  RadioButton
} from "@/components"
import { ESHIPPING_METHOD } from "@/enums/shipping.enums"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useUpdateUserMutation } from "@/services/api/user"
import { useAppLoading } from "@/hooks/useLoading"
import { showError, showSuccess } from "@/utils/Toast"

interface IShippingAddressProps {}

const ShippingMethod: FC<IShippingAddressProps> = () => {
  const [isEditMethod, setIsEditMethod] = useState<boolean>(false)
  const [methodValue, setMethodValue] = useState(ESHIPPING_METHOD.FEDEX)
  const { userInfo } = useSelector<RootState, IUserStore>((s) => s.user)
  const [fedExAcc, setFedExAcc] = useState("")
  const [updateUserInfo, { isLoading, isSuccess, isError }] =
    useUpdateUserMutation()
  useAppLoading([isLoading])

  useEffect(() => {
    if (isError) {
      showError("Update shipping method error")
    }

    if (isSuccess) {
      showSuccess("Update shipping method success")
      setIsEditMethod(false)
    }
  }, [isError, isSuccess])

  console.log(userInfo)
  useEffect(() => {
    if (!userInfo || !userInfo.shippingMethod) return
    setMethodValue(userInfo.shippingMethod as any)
    if (userInfo.FedExAcc) setFedExAcc(userInfo.FedExAcc)
  }, [userInfo])

  const onEditMethod = () => {
    setIsEditMethod(true)
  }

  const onSaveMethod = () => {
    if (!userInfo) return

    updateUserInfo({
      updateUserDto: {
        ...userInfo,
        shippingMethod: methodValue as any,
        FedExAcc: fedExAcc
      }
    })
  }

  const onCancelSaveMethod = () => {
    setIsEditMethod(false)
  }

  const onChangeMethod = (e: RadioChangeEvent) => {
    setMethodValue(e.target.value)
  }

  return (
    <>
      <div className="shipping-method-header">
        <h3>Set Default Shipping Method</h3>
      </div>

      <div className="shipping-method-content">
        {SHIPPING_METHODS.map((method, index) => (
          <div className="shipping-method-content-item" key={index}>
            <Radio.Group onChange={onChangeMethod} value={methodValue}>
              <div className="shipping-method-content-btn">
                <RadioButton value={method.value} disabled={!isEditMethod} />
                <ShippingMethodItem method={method} />
              </div>
            </Radio.Group>
          </div>
        ))}

        {methodValue === ESHIPPING_METHOD.FEDEX && (
          <FormField textLabel="FedEx account">
            {isEditMethod ? (
              <TextField
                value={fedExAcc}
                onChange={(e) => setFedExAcc(e.target.value)}
                placeholder="Enter shipping FedEx account"
              />
            ) : (
              <InputView>{userInfo.FedExAcc}</InputView>
            )}
          </FormField>
        )}
      </div>

      <div className="shipping-method-footer">
        {isEditMethod ? (
          <>
            <Button
              customType={Type.NEUTRAL}
              hierarchy={ButtonHierarchy.LINK}
              customSize={Size.MEDIUM}
              onClick={onCancelSaveMethod}
              customClassName="shipping-method-footer-btn"
            >
              Cancel
            </Button>
            <Button
              customSize={Size.MEDIUM}
              onClick={onSaveMethod}
              customClassName="shipping-method-footer-btn"
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            customType={Type.PRIMARY}
            hierarchy={ButtonHierarchy.OUTLINE}
            customSize={Size.MEDIUM}
            onClick={onEditMethod}
            customClassName="shipping-method-footer-btn"
          >
            Edit
          </Button>
        )}
      </div>
    </>
  )
}

export default ShippingMethod
