import "@google/model-viewer"

interface IModelProps {
  style?: React.CSSProperties
  src: string
}
const Model: React.FC<IModelProps> = ({ style, src }) => {
  return (
    <div id="card" style={{ height: "100%", width: "100%" }}>
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        src={`https://app.digifabster.com/forge-labs-inc/widget/viewer?fileUrl=${src}`}
      />
    </div>
  )
}

export default Model
