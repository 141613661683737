import * as React from "react"
import "./ShippingAddressItem.scss"
import { DeleteIcon, EditAddressIcon } from "@/assets"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { Button, FormField, InputView } from "@/components"
import { Address } from "@/store/user/type"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"

interface IShippingAddressItemProps {
  isEdit?: boolean
  labelL?: string
  labelR?: string
  onClickEdit?: Function
  onClickDelete?: Function
  data: Address
}

const ShippingAddressItem: React.FC<IShippingAddressItemProps> = ({
  isEdit,
  labelL = "",
  labelR = "",
  data,
  onClickEdit,
  onClickDelete
}) => {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const {
    full_address: address,
    apartment: addressNote,
    postcode,
    country,
    state
  } = data
  return (
    <div className="shipping-address-item-wrapper">
      <div className="shipping-address-item-container">
        <div className="shipping-address-item-left">
          <FormField textLabel={labelL}>
            <InputView>
              {userInfoNew.name} {userInfoNew.surname}
            </InputView>
          </FormField>
        </div>

        <div className="shipping-address-item-right">
          <FormField textLabel={labelR}>
            <InputView>
              <p>{address || ""}</p>
              <p>{addressNote || ""}</p>
              <p>{postcode || ""}</p>
              <p>
                {country || ""}
                {state ? `, ${state}` : ""}
              </p>
              <p>{userInfoNew.email || ""}</p>
              <p>{111111 || ""}</p>
            </InputView>
          </FormField>
        </div>
      </div>

      {isEdit && onClickEdit && onClickDelete && (
        <div className="shipping-address-item-action">
          <Button
            onClick={() => onClickEdit()}
            customType={Type.NEUTRAL}
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.OUTLINE}
          >
            <img src={EditAddressIcon} alt="edit" />
          </Button>
          <Button
            onClick={() => onClickDelete()}
            customType={Type.NEUTRAL}
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.OUTLINE}
          >
            <img src={DeleteIcon} alt="delete" />
          </Button>
        </div>
      )}
    </div>
  )
}

export default ShippingAddressItem
