export enum EManufacturingProcess {
  SLS = 1,
  MJF = 8,
  SLA = 7,
  FDM = 2,
  DMLS = 3
}

export enum EMaterial {
  NYLON_PA12 = "1",
  NYLON_PA12_ESD = "2",
  GLASS_FILLED_NYLON = "3",
  TPU_88A = "4",
  REQUEST_REVIEW = "5"
}

export enum EColor {
  WHITE = "color1",
  BLACK_DYE = "color2"
}

export enum EMeasurement {
  MM = 1,
  CM = 2,
  DM = 3
}

export enum ESurfaceFinish {
  STANDARD_FINISH = "finish1",
  VAPOUR_SMOOTHING = "finish2"
}

export enum ELayerHeight {
  normal = "1"
}
