import {
  ConfigProvider,
  Table as AntTable,
  TableProps as AntTableProps
} from "antd"
import EmptyTable from "@/components/EmptyTable/EmptyTable"
import { ColumnType } from "antd/es/table"
import "./Table.scss"
import "@/assets/scss/_styles.scss"
import AppLoading from "../common/atom/AppLoading/AppLoading"

interface TableProps<T> extends AntTableProps<T> {
  data: T[]
  columns: ColumnType<T>[]
  rowKey: string | ((record: T) => React.Key)
}

const Table = <T extends {}>({
  data,
  columns,
  rowKey,
  ...props
}: TableProps<T>): JSX.Element => {
  return data.length === 0 ? (
    <AppLoading isLoading={true}></AppLoading>
  ) : (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `"Open Sans", sans-serif`
        },
        components: {
          Table: {
            headerBg: "var(--bg-base-gray)",
            headerSplitColor: "none"
          }
        }
      }}
    >
      <AntTable
        {...props}
        className="custom-table"
        dataSource={data}
        columns={columns}
        rowKey={rowKey}
        pagination={false}
        showSorterTooltip={false}
        rowHoverable={false}
        scroll={
          data.length !== 0
            ? { y: "calc(100vh - 230px)", scrollToFirstRowOnChange: false }
            : undefined
        }
        rowClassName={"row"}
        locale={{
          emptyText: data?.length === 0 ? <EmptyTable /> : undefined
        }}
      />
    </ConfigProvider>
  )
}

export default Table
