import "./App.scss"
import "react-toastify/dist/ReactToastify.css"
import { RouterProvider } from "react-router-dom"
import { router } from "./routers"
import { ToastContainer } from "react-toastify"
import { LoadingProvider } from "./contexts/LoadingContext"
import "@/config/firebase"

function App() {
  return (
    <>
      <LoadingProvider>
        <RouterProvider router={router} />
        <ToastContainer
          toastClassName="toast-container-style"
          position="bottom-right"
          hideProgressBar
          autoClose={3000}
          closeButton={false}
        ></ToastContainer>
      </LoadingProvider>
    </>
  )
}

export default App
