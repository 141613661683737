import { DEFAULT_WIDTH } from "@/constants/common.constant"
import { SelectDropdownProps } from "../SelectDropdown/SelectDropdown"
import "../SelectDropdown/SelectDropdown.scss"
import { Flex, Select, Tag } from "antd"
import { CheckOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons"
import Button from "../Button/Button"
import { useEffect, useMemo, useState } from "react"
import { IMapSelectDropdownItem } from "@/interfaces/common.interface"
import { mapItemsToSelectOptions } from "@/utils/functionHelper"
import CustomCheckbox from "../Checkbox/Checkbox"

const { Option } = Select

const MultiSelectDropdown: React.FC<SelectDropdownProps> = ({
  width = DEFAULT_WIDTH,
  options,
  prefixIcon,
  placeholder = "Select",
  addNewValue,
  searchBar,
  placeholderSearch = "Search",
  defaultValue,
  valueChangeToClear,
  ...props
}) => {
  const mapOptions: IMapSelectDropdownItem[] = useMemo(
    () => mapItemsToSelectOptions(options),
    [options]
  )
  const [items, setItems] = useState<IMapSelectDropdownItem[]>(mapOptions)
  const [selected, setSelected] = useState<IMapSelectDropdownItem[]>([])
  const [searchText, setSearchText] = useState("")
  const [itemsReady, setItemsReady] = useState(false)

  const customTagRender = (props: {
    label: string
    value: string
    closable: boolean
    onClose: () => void
    color?: string
  }) => {
    const { label, closable, onClose } = props

    const handleClose = (e: { preventDefault: () => void }) => {
      e.preventDefault()
      onClose()
    }

    return (
      <Tag
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        closable={closable}
        onClose={handleClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    )
  }

  useEffect(() => {
    setItems(mapOptions)
    setItemsReady(mapOptions.length > 0)
  }, [mapOptions])

  useEffect(() => {
    if (itemsReady) {
      setSelected([])
      const updateSelectedMultiple = (keys: (string | number)[]) => {
        const keyStrings = keys.map((key) => key.toString())
        const updatedItems = mapOptions.map((item) =>
          keyStrings.includes(item.key.toString())
            ? { ...item, checked: true }
            : { ...item, checked: false }
        )
        setItems(updatedItems)

        const newSelection = updatedItems.filter((item) => item.checked)
        setSelected(newSelection)
      }
      if (defaultValue?.length) {
        updateSelectedMultiple(defaultValue)
      }
    }
  }, [valueChangeToClear, setItemsReady, itemsReady])

  const handleClearSelection = () => {
    const mapChecked = items.map((item) => ({ ...item, checked: false }))
    setItems(mapChecked)
    setSelected([])
  }

  const filterOptions = (input: string, option: IMapSelectDropdownItem) => {
    if (!option) return false
    return (
      option.label.toLowerCase().includes(input.toLowerCase()) ||
      option?.subTitle?.toLowerCase().includes(input.toLowerCase())
    )
  }

  const handleOnChange = (
    value: string[] | number[] | IMapSelectDropdownItem[]
  ) => {
    const keyStrings = value.map((key) => key.toString())
    const updatedItems = items.map((item) =>
      keyStrings.includes(item.key.toString())
        ? { ...item, checked: true }
        : { ...item, checked: false }
    )
    setItems(updatedItems)
    const newSelection = updatedItems.filter((item) => item.checked)
    setSelected(newSelection)
  }

  const filteredOptions = useMemo(() => {
    return items.filter((item) => filterOptions(searchText, item))
  }, [items, searchText])

  const handleSearch = (value: string) => {
    setSearchText(value)
  }
  return (
    <div
      className="select-wrapper"
      style={{
        width: `${typeof width === "string" ? width : `${width}px`}`
      }}
    >
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select
        {...props}
        className="select-dropdown"
        mode="multiple"
        value={selected}
        showSearch={false}
        filterOption={false}
        placeholder={<div className="placeholder-text">{placeholder}</div>}
        style={{ width: "100%" }}
        popupClassName="dropdown-menu multiple"
        menuItemSelectedIcon={<CheckOutlined />}
        onChange={handleOnChange}
        tagRender={(props) =>
          customTagRender({ ...props, label: props.label as string })
        }
        notFoundContent={<div className="not-found-text">No results found</div>}
        dropdownRender={(menu) => (
          <>
            <div>
              {searchBar ? (
                <Select
                  showSearch
                  open={false}
                  searchValue={searchText}
                  placeholder={
                    <div className="placeholder-search-text">
                      {placeholderSearch}
                    </div>
                  }
                  onSearch={handleSearch}
                  style={{
                    width: `98%`,
                    margin: 3,
                    borderColor: "var(--border-normal)",
                    borderRadius: 2,
                    marginBottom: 8
                  }}
                />
              ) : null}
              {filteredOptions.length ? (
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    margin: "6px 12px"
                  }}
                >
                  <div className="count-number-text">
                    {selected?.length}/{items?.length}
                  </div>
                  <span
                    className="clear-button-text"
                    onClick={handleClearSelection}
                    style={{
                      outline: "none",
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer"
                    }}
                  >
                    Clear selection
                  </span>
                </Flex>
              ) : null}
            </div>
            {menu}
            {addNewValue ? (
              <Flex className="footer-dropdown" align="center" justify="center">
                <Button
                  icon={<PlusOutlined />}
                  danger
                  type="text"
                  onClick={addNewValue}
                >
                  <div className="add-btn-text">Add new value</div>
                </Button>
              </Flex>
            ) : null}
          </>
        )}
      >
        {filteredOptions.map((item) => (
          <Option key={item.key} value={item.value} label={item.label}>
            <Flex align="center" justify="space-between">
              {item?.prefix ? <>{item.prefix}</> : null}
              <div style={{ flex: 1 }}>
                <div className="item-text">{item.label}</div>
                {item?.subTitle ? (
                  <div style={{ marginTop: 8 }} className="sub-item-text">
                    {item.subTitle}
                  </div>
                ) : null}
              </div>
              <CustomCheckbox
                style={{ marginRight: 2 }}
                checked={item?.checked}
              />
            </Flex>
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default MultiSelectDropdown
