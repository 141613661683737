import { ConfigProvider, Radio, RadioProps } from "antd"
import React from "react"
import "../RadioButton/RadioButton.scss"
import { RadioButtonSize, Size } from "@/enums/common.enum"

interface IRadioButtonProps extends RadioProps {
  children?: React.ReactNode
  size?: Size.SMALL | Size.MEDIUM
  customClassName?: string
}

const RadioButton: React.FC<IRadioButtonProps> = (props) => {
  const { size = Size.SMALL, children, customClassName, ...rest } = props

  const radioSize =
    size === Size.SMALL
      ? RadioButtonSize.RADIO_SIZE_SMALL
      : RadioButtonSize.RADIO_SIZE_MEDIUM
  const dotSize =
    size === Size.SMALL
      ? RadioButtonSize.DOT_SIZE_SMALL
      : RadioButtonSize.DOT_SIZE_MEDIUM

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            radioSize,
            dotSize
          }
        }
      }}
    >
      <Radio {...rest} className={`radio-style ${customClassName}`}>
        {children}
      </Radio>
    </ConfigProvider>
  )
}

export default RadioButton
