import { formatFileSize } from "@/utils/functionHelper"
import { UploadFile } from "antd"
import { useEffect, useMemo, useState } from "react"
import Button from "../Button/Button"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { AddFileIcon, ErrorFieldIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import Loading from "../Loading/Loading"
interface ICustomItemFileRender {
  file: UploadFile
  fileList: UploadFile[]
  actions: {
    download?: () => void
    preview?: () => void
    remove: (file: UploadFile) => void
  }
  showErrorText?: boolean
}

const CustomItemFileRender: React.FC<ICustomItemFileRender> = ({
  file,
  actions,
  showErrorText = true
}) => {
  const { name, fileName, size, status, error } = file
  const formattedSize = useMemo(() => formatFileSize(size || 0), [size])
  const [remove, setRemove] = useState(false)

  const isLoading = useMemo(
    () => status === "uploading" && !remove,
    [status, remove]
  )

  return (
    <>
      {status !== "done" && (
        <div
          className={`file-container ${
            status === "error" ? "ant-upload-item-error" : ""
          } ${isLoading ? "file-container-uploading" : ""}`}
        >
          <div className="file-container-info">
            <Button
              customClassName="file-container-info-btn"
              customSize={Size.EXTRA_SMALL}
              hierarchy={ButtonHierarchy.TONAL}
              customType={Type.PRIMARY}
            >
              <img width={16} height={16} src={AddFileIcon} />
            </Button>
            <div className="file-container-info-detail">
              <span className="file-container-info-name">
                {fileName || name}
              </span>
              {!!size && (
                <div className="file-container-info-size">{formattedSize}</div>
              )}
            </div>
          </div>

          <div className="file-container-remove">
            {isLoading ? (
              <div className="file-uploading-icon">
                <Loading />
              </div>
            ) : (
              <>
                <Button
                  customClassName="file-container-remove-btn"
                  customSize={Size.EXTRA_SMALL}
                  hierarchy={ButtonHierarchy.LINK}
                  customType={Type.NEUTRAL}
                  onClick={(e) => {
                    e.preventDefault()
                    setRemove(true)
                    actions.remove(file)
                  }}
                >
                  <CloseOutlined />
                </Button>
              </>
            )}
          </div>
        </div>
      )}

      {error && showErrorText && (
        <span className="error">
          <img src={ErrorFieldIcon} alt="error-icon" className="error-icon" />
          <span className="error-text">{error}</span>
        </span>
      )}
    </>
  )
}

export default CustomItemFileRender
