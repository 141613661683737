import { RootContainer } from "@/components"
import "./InvoicePage.scss"
import { useLazyGetInvoiceQuery } from "@/services/apiDigifabster/order"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import InvoiceItem from "./components/InvoiceItem/InvoiceItem"
import { format } from "date-fns"
import InvoiceSummary from "./components/InvoiceSummary/InvoiceSummary"
import {
  billingAddressIcon,
  billingContactIcon,
  contactIcon,
  deliveryInvoiceIcon
} from "@/assets"

function InvoicePage() {
  const { invoiceId, invoice_hash } = useParams()
  const [getInvoiceDetail, { isLoading }] = useLazyGetInvoiceQuery()
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { pathname } = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (invoiceId && invoice_hash) {
      getInvoiceDetail({ id: invoiceId, hash: invoice_hash })
    }
  }, [])

  useAppLoading([isLoading])

  const product = invoiceDetail?.order?.line_items
  const order = invoiceDetail?.order
  const createdAt = order?.date
    ? format(new Date(order.date), "dd-MM-yyyy")
    : ""
  const additionalContact =
    order?.additional_billing_contacts?.length > 0
      ? order.additional_billing_contacts.pop()
      : ""

  const hideBillingAddress = useMemo(() => {
    return (
      order?.billing_address?.company_name ||
      order?.billing_address?.street_address ||
      order?.billing_address?.apartment ||
      order?.billing_address?.city ||
      order?.billing_address?.state ||
      order?.billing_address?.country ||
      order?.billing_address?.postcode
    )
  }, [order?.billing_address])

  const hideBillingContact = useMemo(() => {
    return (
      order?.billing_fullname || order?.billing_email || order?.billing_phone
    )
  }, [order?.billing_address])

  const handleBack = () => {
    if (pathname.includes("quotes")) return navigate(`/quotes/${order.id || 0}`)
    if (pathname.includes("orders")) return navigate(`/orders/${order.id || 0}`)
    if (pathname.includes("checkout"))
      return navigate(`/new-quote/checkout/${order.id || 0}/submitted`)
  }

  return (
    <RootContainer onBack={handleBack} headerText="Invoice">
      <div className="invoice">
        <div className="invoice-left">
          <div className="invoice-left-content">
            <div className="invoice-left-content-detail">
              <div className="invoice-left-content-detail-header">
                <div className="invoice-left-content-detail-header-title">
                  <p>Order #{invoiceDetail?.order?.company_order_id}</p>
                  <p>Created {createdAt}</p>
                </div>
                <div className="invoice-left-content-detail-header-status">
                  <p>
                    <span>
                      Status: <b>{order?.status_display}</b>
                    </span>
                  </p>
                </div>
              </div>
              <div className="invoice-left-content-detail-product">
                <div className="invoice-left-content-detail-product-header">
                  <p>MODEL</p>
                  <p>SPECIFICATIONS</p>
                  <p>QUANTITY</p>
                  <p>TOTAL</p>
                </div>
                {product?.map((item) => (
                  <InvoiceItem key={item.id} item={item}></InvoiceItem>
                ))}
              </div>
              <div className="invoice-left-content-detail-estimated">
                <p>
                  <strong>Estimated Manufacturing Time:</strong>{" "}
                  {order?.delivery_time} Days
                </p>
              </div>
            </div>
            <div className="invoice-left-content-info">
              <div className="invoice-left-content-info-top">
                <div className="invoice-left-content-info-top-contact">
                  <div className="invoice-left-content-info-top-contact-header">
                    <div>
                      <img src={contactIcon} alt="" />
                    </div>
                    <p>CONTACT</p>
                  </div>
                  <p>
                    <b>Name: </b>
                    {order?.buyers_full_name}
                  </p>
                  <p>
                    <b>Phone: </b>
                    {order?.phone}
                  </p>
                  {additionalContact && (
                    <p>
                      <b>Additional contacts: </b>
                      {additionalContact}
                    </p>
                  )}
                </div>
                <div className="invoice-left-content-info-top-delivery">
                  <div className="invoice-left-content-info-top-delivery-header">
                    <div>
                      <img src={deliveryInvoiceIcon} alt="" />
                    </div>
                    <p>DELIVERY</p>
                  </div>
                  {order?.shipping_address?.company_name && (
                    <p>
                      {order?.shipping_address?.company_name &&
                      order?.shipping_address?.company_name !== "blank"
                        ? order?.shipping_address?.company_name
                        : ""}
                    </p>
                  )}

                  {(order?.shipping_address?.street_address ||
                    order?.shipping_address?.apartment) && (
                    <p>
                      {order?.shipping_address?.street_address || ""}
                      {order?.shipping_address?.apartment &&
                        `, ${order?.shipping_address?.apartment || ""}`}
                    </p>
                  )}

                  {(order?.shipping_address?.city ||
                    order?.shipping_address?.state) && (
                    <p>
                      {order?.shipping_address?.city || ""}
                      {order?.shipping_address?.state &&
                        `, ${order?.shipping_address?.state || ""}`}
                    </p>
                  )}

                  {(order?.shipping_address?.country ||
                    order?.shipping_address?.postcode) && (
                    <p>
                      {order?.shipping_address?.country || ""}
                      {order?.shipping_address?.postcode &&
                        `, ${order?.shipping_address?.postcode || ""}`}
                    </p>
                  )}
                  <p>
                    <b>Shipping Method: </b>
                    {order?.delivery_title || order?.delivery_type_title || "-"}
                  </p>
                </div>
              </div>
              <div className="invoice-left-content-info-bottom">
                {hideBillingContact && (
                  <div className="invoice-left-content-info-bottom-billing-contact">
                    <div className="invoice-left-content-info-bottom-billing-contact-header">
                      <div>
                        <img src={billingContactIcon} alt="" />
                      </div>
                      <p>BILLING CONTACT</p>
                    </div>
                    <p>
                      <b>Name: </b> {order?.billing_fullname}
                    </p>
                    <p>
                      <b>Email: </b> {order?.billing_email}
                    </p>
                    <p>
                      <b>Phone: </b> {order?.billing_phone}
                    </p>
                  </div>
                )}
                {hideBillingAddress && (
                  <div className="invoice-left-content-info-bottom-billing-address">
                    <div className="invoice-left-content-info-bottom-billing-address-header">
                      <div>
                        <img src={billingAddressIcon} alt="" />
                      </div>
                      <p>BILLING ADDRESS</p>
                    </div>
                    <p>{order?.billing_address?.company_name}</p>
                    <p>
                      {order?.billing_address?.street_address}
                      {order?.billing_address?.apartment &&
                        `, ${order?.billing_address?.apartment}`}
                    </p>
                    <p>
                      {order?.billing_address?.city}
                      {order?.billing_address?.state &&
                        `, ${order?.billing_address?.state}`}
                    </p>
                    <p>
                      {order?.billing_address?.country}
                      {order?.billing_address?.postcode &&
                        `, ${order?.billing_address?.postcode}`}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {order?.notes && (
              <div className="invoice-left-content-note">
                <p>NOTES</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${order?.notes}`
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        <div className="invoice-right">
          <InvoiceSummary></InvoiceSummary>
        </div>
      </div>
    </RootContainer>
  )
}

export default InvoicePage
