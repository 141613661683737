import { ArrowLeftOutlined } from "@ant-design/icons"
import { Flex } from "antd"

interface IBackToLogin {
  justify?: string
}

const BackToLogin: React.FC<IBackToLogin> = ({ justify = "center" }) => {
  return (
    <a href="/auth/sign-in">
      <Flex
        className="forgot-pw-page-btn"
        gap={6}
        align="center"
        justify={justify}
      >
        <ArrowLeftOutlined />
        <div className="forgot-pw-page-btn-text">Back to login page</div>
      </Flex>
    </a>
  )
}

export default BackToLogin
