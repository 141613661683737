import * as React from "react"
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue
} from "react-hook-form"
import { TextField, Button, FormField } from "@/components"
import "./RegisterForm.scss"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { IFormRegister } from "@/interfaces/register.interface"
import { useNavigate } from "react-router-dom"
import { errorStatus } from "@/constants/common.constant"

interface ISideBarProps {
  onSubmit: SubmitHandler<IFormRegister>
  control: Control<IFormRegister, any>
  handleSubmit: UseFormHandleSubmit<IFormRegister, undefined>
  setValue: UseFormSetValue<IFormRegister>
  errors: FieldErrors<IFormRegister>
}
const RegisterForm: React.FC<ISideBarProps> = ({
  onSubmit,
  control,
  handleSubmit,
  setValue,
  errors
}) => {
  const navigate = useNavigate()

  const onNavigateToLogin = () => {
    navigate("/auth/sign-in")
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-section boder-bottom">
        <FormField
          textLabel="First Name"
          errorText={errors?.firstName?.message}
        >
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                status={errorStatus(errors.firstName)}
                placeholder="Enter your first name"
                {...field}
              />
            )}
          />
        </FormField>

        <FormField textLabel="Last Name" errorText={errors?.lastName?.message}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Enter your last name"
                status={errorStatus(errors.lastName)}
                {...field}
              />
            )}
          />
        </FormField>

        <FormField textLabel="Phone number" errorText={errors?.phone?.message}>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Enter your phone number"
                status={errorStatus(errors.phone)}
                {...field}
              />
            )}
          />
        </FormField>

        <FormField textLabel="Email address" errorText={errors?.email?.message}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Enter your email address"
                status={errorStatus(errors.email)}
                {...field}
              />
            )}
          />
        </FormField>

        <FormField
          textLabel="Enter Password"
          errorText={errors?.password?.message}
        >
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                status={errorStatus(errors.password)}
                placeholder="Enter your password"
                type="password"
                {...field}
              />
            )}
          />
        </FormField>
      </div>

      <div className="form-action">
        <Button
          htmlType="submit"
          customSize={Size.EXTRA_LARGE}
          customClassName="form-action-button"
        >
          Register
        </Button>
        <div className="form-action-item">
          <p className="form-action-item-text">Already have account?</p>
          <Button
            onClick={onNavigateToLogin}
            hierarchy={ButtonHierarchy.LINK}
            customSize={Size.EXTRA_LARGE}
            customClassName="form-action-item-button"
          >
            Log in
          </Button>
        </div>
      </div>
    </form>
  )
}
export default RegisterForm
