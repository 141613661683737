import { api } from "../apiInstance"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createQuote: build.mutation<CreateQuoteApiResponse, CreateQuoteApiArg>({
      query: (queryArg) => ({
        url: `/quote`,
        method: "POST",
        body: queryArg.createQuoteDto
      })
    }),
    getQuote: build.query<GetQuoteApiResponse, GetQuoteApiArg>({
      query: (queryArg) => ({ url: `/quote/${queryArg.quoteId}` })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as quoteService }
export type GetListQuoteApiResponse = /** status 200  */ IResponse & {
  data?: Pageable
}
export type GetListQuoteApiArg = {
  page?: number
  size?: number
}
export type CreateQuoteApiResponse = /** status 200  */ IResponse & {
  data: QuoteResponseDto
}
export type CreateQuoteApiArg = {
  createQuoteDto: CreateQuoteDto
}
export type GetQuoteApiResponse = /** status 200  */ IResponse & {
  data: QuoteResponseDto
}
export type GetQuoteApiArg = {
  quoteId: string
}
export type IResponse = {
  /** Application code */
  code: Code
  /** Can be success or error message */
  message: string
  /** Is API success */
  success: boolean
  path?: string
  timestamp?: string
}
export type Pageable = {
  result: any[]
  page: number
  size: number
  itemCount: number
  pageCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}
export type Option = {
  id: string
  image?: string
  name: string
  description?: string
}
export type ColorOption = {
  id: string
  image?: string
  name: string
  description?: string
  hex: string
}
export type ModelEntity = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  fileName: string
  originalFile: string
  convertedFile: string
  thumbnail?: string
  height?: number
  width?: number
  length?: number
  size?: string
}
export type ProductDetailsDto = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  modelId?: string
  technology: Option
  material: Option
  unit: number
  additionalFilesId?: string[]
  color: ColorOption
  surfaceFinish: Option
  layerHeight: number
  price: number
  note?: string
  quoteId: string
  /** This is the same with the project ID using to call AMFG API */
  ticketId?: string
  status: string
  model: ModelEntity
}
export type QuoteResponseDto = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  name: string
  status: Status
  grossPrice: number
  deliveryAddress?: string
  billingAddress?: string
  userId?: string
  orderNotification?: string
  products: ProductDetailsDto[]
}
export type CreateQuoteDto = {
  modelIds: string[]
  technology?: string
}
export enum Code {
  Success = 1000,
  InternalServerError = 1001,
  InvalidUserToken = 1002,
  InvalidToken = 1003,
  Unauthorized = 1004,
  UserNotVerified = 2001,
  VerifyEmailError = 2002,
  ResetPasswordError = 2003,
  CredentialError = 2004,
  UserNotFound = 2005,
  SameEmailError = 2006,
  BillingAddAvailableError = 3001
}
export enum Status {
  NewQuote = "New Quote",
  Quote = "Quote",
  Order = "Order"
}
export const {
  useCreateQuoteMutation,
  useGetQuoteQuery,
  useLazyGetQuoteQuery
} = injectedRtkApi
