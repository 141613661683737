import {
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER,
  REQUIRED_ERROR
} from "@/constants/common.constant"
import { number, object, ref, string } from "yup"

export const MANUAL_REQUEST_SCHEMA = object({
  service: string().required(REQUIRED_ERROR("This field")),
  projectOverview: string().required(REQUIRED_ERROR("This field")),
  isHaveCADFile: string().required(REQUIRED_ERROR("This field")),
  budget: string().required(REQUIRED_ERROR("This field")),
  timeline: string().required(REQUIRED_ERROR("This field")),
  unit: string().required(REQUIRED_ERROR("This field"))
})

export const MANUAL_REQUEST_EXISTING_SCHEMA = object({
  name: string().required(REQUIRED_ERROR("This field")),
  phone: string()
    .required(REQUIRED_ERROR("This field"))
    .matches(REGEX_PHONE_NUMBER, "Invalid format")
    .test("phone-number-max", "Phone number must be 10 character", (value) => {
      if (value) {
        return value?.length === 10
      }
      return true
    }),
  email: string()
    .required(REQUIRED_ERROR("This field"))
    .matches(REGEX_EMAIL, "Invalid format"),
  description: string().required(REQUIRED_ERROR("This field"))
})
