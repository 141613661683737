import { useAppLoading } from "@/hooks/useLoading"
import { useVerifyEmailMutation } from "@/services/api/auth"
import { getQueryParams } from "@/utils/stringHelper"
import { showError } from "@/utils/Toast"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

function EmailVerifiedPage() {
  const { pathname } = useLocation()
  const [handleVerifyEmail, { isLoading, error, data }] =
    useVerifyEmailMutation()

  useAppLoading([isLoading])

  useEffect(() => {
    if (!error) return

    showError("Error", "Cannot verify email")
  }, [error])

  useEffect(() => {
    const params = getQueryParams(decodeURIComponent(pathname))
    handleVerifyEmail({ verifyEmailDto: { token: params.token } })
  }, [])

  useEffect(() => {
    if (!error && !data) return

    window.location.href = "/"
  }, [error, data])

  return <div></div>
}

export default EmailVerifiedPage
