import * as React from "react"
import "./ShippingForm.scss"
import Button from "@/components/common/atom/Button/Button"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form"
import { EMTY_STATE, errorStatus } from "@/constants/common.constant"
import {
  TextField,
  FormField,
  CountrySelect,
  SelectDropdown
} from "@/components"
import { CountryOptions, IPhoneData } from "@/interfaces/common.interface"
import { useEffect, useState } from "react"
import { getCitiesList, getStatesList } from "@/constants/countries"
import { IShippingForm } from "@/interfaces/shipping.interface"
import { PhoneInput } from "@/components/common/atom/PhoneInput/PhoneInput"
import { transformPhoneNumber } from "@/utils/functionHelper"

interface IShippingFormContainerProps {
  onSubmit: SubmitHandler<IShippingForm>
  errors: FieldErrors<IShippingForm>
  handleSubmit: UseFormHandleSubmit<IShippingForm, undefined>
  setValue: UseFormSetValue<IShippingForm>
  getValues?: UseFormGetValues<IShippingForm>
  onCancel?: () => void
  control: Control<IShippingForm> | undefined
  watch: UseFormWatch<IShippingForm>
}

const ShippingForm: React.FC<IShippingFormContainerProps> = ({
  onSubmit,
  handleSubmit,
  setValue,
  errors,
  onCancel,
  control,
  watch
}) => {
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [stateList, setStateList] = useState<CountryOptions[]>([])
  const [cityList, setCityList] = useState<CountryOptions[]>([])

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  useEffect(() => {
    const getCities = async (countryId: number, stateId: number) => {
      const cities = await getCitiesList(countryId, stateId)
      setCityList(cities)
    }

    const stateId = watch("state")?.id
    const countryId = watch("country").id
    if (stateId) {
      getCities(Number(countryId), Number(stateId))
      return
    }
    setCityList([])
  }, [watch("state")])

  const handleChangeTel = (
    phone: IPhoneData,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const { phoneNum, countryCode, countryCodeId } = phone
    const phoneData = {
      phoneNum: countryCode ? `+${countryCode}${phoneNum}` : phoneNum,
      countryCode,
      countryCodeId
    }

    onChangeCallback(phoneData)
  }

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
    setValue("city", EMTY_STATE)
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || {})
    setValue("state", EMTY_STATE)
    setValue("city", EMTY_STATE)
  }

  const handleChangeCity = (
    cityId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const city = cityList.find((country) => country.id === cityId)
    const data = { name: city?.title, id: city?.id }

    onChangeCallback(data || "")
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shipping-form-content">
        <div className="shipping-form-address">
          <p className="shipping-form-label">Address</p>

          <div className="shipping-form-row">
            <div className="shipping-form-row-item">
              <FormField
                textLabel="First Name"
                errorText={errors.firstName?.message}
                size="medium"
              >
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.firstName)}
                      placeholder="Enter your first name"
                      {...field}
                    />
                  )}
                ></Controller>
              </FormField>
            </div>

            <div className="shipping-form-row-item">
              <FormField
                textLabel="Last Name"
                errorText={errors.lastName?.message}
                size="medium"
              >
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.lastName)}
                      placeholder="Enter your last name"
                      {...field}
                    />
                  )}
                ></Controller>
              </FormField>
            </div>
          </div>

          <FormField
            clasName="shipping-form-field"
            textLabel="Company (optional)"
            errorText={errors.company?.message}
            size="medium"
          >
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.company)}
                  placeholder="Enter your company name"
                  {...field}
                />
              )}
            ></Controller>
          </FormField>

          <div className="shipping-form-section">
            <FormField textLabel="Country" errorText={errors?.country?.message}>
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CountrySelect
                    countriesList={countriesList}
                    status={errorStatus(errors?.country as FieldError)}
                    setCountriesList={setCountriesList}
                    flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                    value={value.id}
                    width={"100%"}
                    dropdownClassName="dropdown-menu"
                    handleChange={(countryId) =>
                      handleChangeCountry(countryId, onChange)
                    }
                    placeholder="Select your country"
                  />
                )}
              />
            </FormField>

            <FormField textLabel="State">
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    width={"100%"}
                    dropdownClassName="dropdown-menu"
                    value={value?.id}
                    searchBar={true}
                    valueChangeToClear={watch("country")?.id}
                    onChange={(stateId) => handleChangeState(stateId, onChange)}
                    options={stateList}
                    virtual={false}
                  />
                )}
              />
            </FormField>

            <FormField textLabel="City">
              <Controller
                name="city"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    width={"100%"}
                    searchBar={true}
                    value={value?.id}
                    valueChangeToClear={watch("country")?.id}
                    dropdownClassName="dropdown-menu"
                    onChange={(cityId) => handleChangeCity(cityId, onChange)}
                    options={cityList}
                    virtual={false}
                  />
                )}
              />
            </FormField>

            <FormField
              textLabel="Zip code"
              errorText={errors?.zipCode?.message}
            >
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="Input zip code"
                    status={errorStatus(errors.zipCode)}
                    {...field}
                  />
                )}
              />
            </FormField>
          </div>

          <FormField
            clasName="shipping-form-field"
            textLabel="Address"
            errorText={errors.address?.message}
            size="medium"
          >
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.address)}
                  placeholder="Enter address"
                  {...field}
                />
              )}
            ></Controller>
          </FormField>
          <div className="shipping-form-field">
            <Controller
              name="addressNote"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.addressNote)}
                  placeholder="Enter"
                  {...field}
                />
              )}
            ></Controller>
          </div>

          <FormField
            clasName="shipping-form-field"
            textLabel="Delivery instruction (optional)"
            errorText={errors.delivery?.message}
            size="medium"
          >
            <Controller
              name="delivery"
              control={control}
              render={({ field }) => (
                <TextField
                  status={errorStatus(errors.delivery)}
                  placeholder="Enter instruction"
                  {...field}
                />
              )}
            ></Controller>
          </FormField>
        </div>

        <div className="shipping-form-contact">
          <p className="shipping-form-label">CONTACT INFORMATION</p>

          <div className="shipping-form-row">
            <div className="shipping-form-row-item">
              <FormField
                textLabel="Phone number"
                errorText={errors?.phone?.message}
              >
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      data={{ ...value, phoneNum: transformPhoneNumber(value) }}
                      errors={errors.phone as FieldError}
                      handleChange={(phone) => handleChangeTel(phone, onChange)}
                    />
                  )}
                />
              </FormField>
            </div>

            <div className="shipping-form-row-item">
              <FormField
                textLabel="Email address"
                errorText={errors.email?.message}
                size="medium"
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.email)}
                      placeholder="Enter your Enter email address"
                      {...field}
                    />
                  )}
                ></Controller>
              </FormField>
            </div>
          </div>
        </div>
      </div>

      <div className="shipping-form-footer">
        <Button
          hierarchy={ButtonHierarchy.LINK}
          customType={Type.NEUTRAL}
          customClassName="shipping-form-footer-btn"
          onClick={onCancel}
        >
          Cancle
        </Button>
        <Button htmlType="submit" customClassName="shipping-form-footer-btn">
          Save
        </Button>
      </div>
    </form>
  )
}

export default ShippingForm
