import "./ViewerModal.scss"
import { Modal, Model, Loading } from "@/components"
import { ModelResponse } from "@/services/apiDigifabster/model"
import { Collapse } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import DFMCheck from "../DFMCheck/DFMCheck"
import { IViewerModalData } from "@/interfaces/order.interface"
import { EModelAnalysisStatus, IModelAnalysis } from "@/store/product"
import { useEffect, useState } from "react"
import {
  BlockIcon,
  OrderReviewIcon,
  SuccessDFMIcon,
  WarningDFMIcon
} from "@/assets"

const dfmAnalysis: IViewerModalData[] = [
  {
    status: EModelAnalysisStatus.SUCCESS,
    isDisabled: false,
    title: "Part Size",
    value: "This part fits within the build volume of the selected technology.",
    id: 0
  },
  {
    status: EModelAnalysisStatus.WARNING,
    isDisabled: false,
    title: "Thin Walls",
    value: "Walls are too thin, minimum recommended thickness value is 10.0 mm",
    id: 1
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Hole Size",
    value: "Minimum hole diameter is at least 1.0 mm",
    id: 3
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Minimum Detail Size",
    value: "Minimum detail size is 0.5 mm.",
    id: 2
  },
  {
    status: EModelAnalysisStatus.LOADING,
    isDisabled: true,
    title: "Trapped Volume",
    value: "The part cannot contain any trapped volumes.",
    id: 4
  }
]

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "viewer-modal-analysis-heading-title-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessDFMIcon,
    className: ""
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "viewer-modal-analysis-heading-title-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Manufacturing Issues Detected",
    icon: BlockIcon,
    className: "viewer-modal-analysis-heading-title-error"
  }
}
interface ViewerModalProps {
  isModalOpen: boolean
  onCancel: Function
  currentPart?: ModelResponse
  loading?: boolean
  analysis?: IModelAnalysis
}

function ViewerModal({
  isModalOpen,
  onCancel,
  currentPart,
  loading,
  analysis
}: ViewerModalProps) {
  const { title, size, volume, file_model_viewer_url, units } =
    currentPart || {}
  const [dfmAnalysisData, setDfmAnalysisData] =
    useState<IViewerModalData[]>(dfmAnalysis)
  useEffect(() => {
    if (!analysis) return

    let _dfmAnalysis = [...dfmAnalysis]
    _dfmAnalysis[1] = {
      status: analysis.status,
      isDisabled: false,
      title: "Thin Walls",
      value: analysis.result?.wallThickness
        ? `Walls are at least ${parseInt(
            analysis?.result?.minimal_wall_thickness || "0"
          )}`
        : `Walls are too thin, minimum recommended thickness value is ${parseInt(
            analysis?.result?.minimal_wall_thickness || "0"
          )} mm.`,
      id: 1
    }
    setDfmAnalysisData([..._dfmAnalysis])
  }, [analysis])

  const analysisList = [
    {
      key: "1",
      label: (
        <>
          <div className="viewer-modal-analysis-heading">
            <img
              src={
                tagStatusClass[analysis?.status || EModelAnalysisStatus.LOADING]
                  .icon
              }
              width={32}
              height={32}
              alt=""
            />

            <div
              className={`viewer-modal-analysis-heading-title ${
                tagStatusClass[analysis?.status || EModelAnalysisStatus.LOADING]
                  .className
              }`}
            >
              <h4>Design for Manufacturing Analysis</h4>
              <p>
                {
                  tagStatusClass[
                    analysis?.status || EModelAnalysisStatus.LOADING
                  ].text
                }
              </p>
            </div>
          </div>
        </>
      ),
      children: <DFMCheck data={dfmAnalysisData} />
    }
  ]
  return (
    <Modal
      open={isModalOpen}
      title="DFM Analysis"
      onCancel={() => onCancel()}
      footer={null}
      width={1307}
    >
      <div className="viewer-modal-wrapper">
        <div className="viewer-modal">
          <div className="viewer-modal-infor">
            <div className="">
              <div className="viewer-modal-infor-content">
                <h3>{title}</h3>
                <p>
                  {size?.x.toFixed(2) || 0.0} x {size?.y.toFixed(2) || 0.0} x
                  {size?.z.toFixed(2) || 0.0} {units}
                </p>
                <p>
                  {volume?.toFixed(2) || 0.0} {units}3
                </p>
              </div>

              <div className="viewer-modal-analysis">
                <Collapse
                  ghost
                  items={analysisList}
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                />
              </div>
            </div>
          </div>
          <div className="viewer-modal-model">
            {loading ? (
              <div className="viewer-modal-model-loading">
                <Loading />
              </div>
            ) : (
              <Model src={file_model_viewer_url || ""} />
            )}
          </div>
        </div>
        <div className="viewer-modal-warning">
          <ExclamationCircleOutlined
            color="var(--bg-gray-stronger)"
            width={24}
            height={24}
          />
          <p>
            Cannot be determined automatically. See full design guidelines for
            more information.
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ViewerModal
