import { noDataIcon } from "@/assets"
import "./EmptyTable.scss"

function EmptyTable(): JSX.Element {
  return (
    <div className="empty">
      <div className="empty-img">
        <img src={noDataIcon} alt="" />
      </div>
      <p className="empty-text">There are no quotes in this list yet</p>
    </div>
  )
}

export default EmptyTable
