import { ISaveQuote } from "@/interfaces/saveQuote.interface"

export const quoteDetails: ISaveQuote = {
  quoteId: "A08113",
  billingAddress: {
    address: "8337 Eastlake Drive",
    country: "Canada",
    unit: "111",
    state: "BC",
    city: "Burnaby",
    zipCode: "V5A 4W2"
  },
  deliverAddress: {
    address: "8337 Eastlake Drive",
    country: "Canada",
    unit: "111",
    state: "BC",
    city: "Burnaby",
    zipCode: "V5A 4W2"
  },
  shippingMethod: "",
  orderNotifications: "",
  paymentMethod: "",
  PONumber: "",
  accountPayable: "",
  billDetail: {
    totalExcludeTax: 99.96,
    discount: 0.0,
    tax: 13.04,
    shipping: 15.0,
    totalAmount: 128
  }
}
