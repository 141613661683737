import { REQUIRED_ERROR } from "@/constants/common.constant"
import { IPostProductionData } from "@/interfaces/editSpecifications.interface"
import { mixed, number, object, ref, string } from "yup"

export const EDIT_SPECIFICATIONS_SCHEMA = object({
  manufacturingProcess: number().required(REQUIRED_ERROR("This field")),
  material: number().required(REQUIRED_ERROR("This field")),
  color: string().required(REQUIRED_ERROR("This field")),
  layerHeight: string().required(REQUIRED_ERROR("This field")),
  filling: string().required(REQUIRED_ERROR("This field")),
  quantity: number().required(REQUIRED_ERROR("This field")),
  postProduction: mixed<IPostProductionData[]>().optional(),
  postProductionCount: number().optional(),
  leadTime: string().required(REQUIRED_ERROR("This field"))
})
