import { Button, Table } from "@/components"
import { BadgeColor, ButtonHierarchy, Size } from "@/enums/common.enum"
import { ColumnType } from "antd/es/table"
import { Quotes } from "@/interfaces/quotesListTable.interface"
import { quotesListColumn } from "@/constants/quoteListTable.constant"
import { QuoteStatus } from "@/enums/quotesList.enum"
import "./QuotesListTable.scss"

interface QuotesListTableProps {
  data: Quotes[]
  onDetail: (id: number) => void
}

const QuotesListTable = ({
  data,
  onDetail
}: QuotesListTableProps): JSX.Element => {
  const columns: ColumnType<Quotes>[] = [
    ...quotesListColumn,
    {
      title: <div className="status-col">STATUS</div>,
      key: "quoteStatus",
      width: "20%",
      render: (_: any, record: Quotes) => (
        <div className="status-text">{record.quoteStatus}</div>
      )
    },
    {
      title: "",
      key: "action",
      width: "15%",
      render: (_: any, record: Quotes) => (
        <div style={{ textAlign: "center" }}>
          <Button
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.TONAL}
            onClick={() => onDetail(record.id)}
          >
            Details
          </Button>
        </div>
      )
    }
  ]

  return <Table data={data} columns={columns} rowKey="id" />
}

export default QuotesListTable
