import { Outlet } from "react-router-dom"
import "./ProfileLayout.scss"
import SideBar from "@/components/common/SideBar/SideBar"
import { TABS } from "@/constants/common.constant"
import ProfileNavigation from "@/components/ProfileNavigation/ProfileNavigation"
import Header from "@/components/Header/Header"
import ProtectedRoute from "@/components/common/ProtectedRoute/ProtectedRoot"

const ProfileLayout: React.FC = () => {
  return (
    <ProtectedRoute>
      <div className="profile-layout flex h-screen bg-no-repeat">
        <SideBar tabs={TABS} />
        <div className="profile-layout-content">
          <Header>
            <h3 className="header-text">Profile</h3>
          </Header>
          <div className="content-wrapper">
            <div className="left">
              <ProfileNavigation />
            </div>
            <main className="content">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  )
}

export default ProfileLayout
