import { ErrorFieldIcon } from "@/assets"
import React from "react"
import "./FormField.scss"

interface FormFieldPropsProps {
  children: React.ReactNode
  required?: boolean
  textLabel?: string
  errorText?: string
  warningText?: string
  size?: "small" | "medium"
  clasName?: string
}

const FormField: React.FC<FormFieldPropsProps> = ({
  children,
  textLabel,
  required,
  errorText,
  warningText,
  clasName,
  size = "medium"
}) => {
  const SIZE_CLASSNAME = {
    small: "text-label-small",
    medium: "text-label-medium"
  }

  return (
    <div className={clasName}>
      {textLabel && (
        <label className={`text-label ${SIZE_CLASSNAME[size]}`}>
          {textLabel}&nbsp;
          {required ? <span className="text-label-required">*</span> : ""}
        </label>
      )}
      {children}
      {warningText && (
        <span className="warning">
          <span className="warning-text">{warningText}</span>
        </span>
      )}
      {errorText && (
        <span className="error">
          <img src={ErrorFieldIcon} alt="error-icon" className="error-icon" />
          <span className="error-text">{errorText}</span>
        </span>
      )}
    </div>
  )
}

export default FormField
