import {
  REQUIRED_ERROR,
  REGEX_EMAIL,
  REGEX_PHONE_NUMBER,
  REGEX_ONLY_NUMBER
} from "@/constants/common.constant"
import { IPhoneData, IState } from "@/interfaces/common.interface"
import { transformPhoneNumber } from "@/utils/functionHelper"
import * as yup from "yup"

export const BILLING_SCHEMA = yup.object().shape({
  firstName: yup.string().required(REQUIRED_ERROR("First name")),
  lastName: yup.string().required(REQUIRED_ERROR("Last name")),
  company: yup.string(),
  country: yup.mixed<IState>().required(REQUIRED_ERROR("Country")),
  city: yup.mixed<IState>(),
  state: yup.mixed<IState>(),
  zipCode: yup
    .string()
    .required(REQUIRED_ERROR("Zip code"))
    .matches(REGEX_ONLY_NUMBER, "Invalid format"),
  address: yup.string().required(REQUIRED_ERROR("Address")),
  addressNote: yup.string(),
  email: yup
    .string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format"),
  phone: yup
    .mixed<IPhoneData>()
    .required(REQUIRED_ERROR("Phone number"))
    .test("phone-number-valid", REQUIRED_ERROR("Phone number"), (value) => {
      if (value) {
        return (
          Object.keys(value).length !== 0 ||
          transformPhoneNumber(value)?.length === 0
        )
      }
      return true
    })
    .test("phone-number", "Invalid format", (value) => {
      if (value) {
        return REGEX_PHONE_NUMBER.test(value.phoneNum)
      }
      return true
    })
    .test("phone-number-max", "Phone number must be 10 character", (value) => {
      if (value) {
        return transformPhoneNumber(value)?.length === 10
      }
      return true
    }),
  tax: yup.number(),
  payableEmail: yup.string().matches(REGEX_EMAIL, "Invalid format")
})

export const BILLING_INFORMATION_SCHEMA = yup.object().shape({
  firstName: yup.string().required(REQUIRED_ERROR("First Name")),
  lastName: yup.string().required(REQUIRED_ERROR("Last Name")),
  phoneNumber: yup
    .string()
    .required(REQUIRED_ERROR("Phone Number"))
    .matches(REGEX_PHONE_NUMBER, "Invalid format")
    .test("phone-number-max", "Phone number must be 10 character", (value) => {
      if (value) {
        return value?.length === 10
      }
      return true
    }),
  accountPayEmail: yup
    .string()
    .required(REQUIRED_ERROR("Account Payable Email"))
    .matches(REGEX_EMAIL, "Invalid format"),
  company: yup.string(),
  taxNumber: yup.string(),
  addressLine1: yup.string().required(REQUIRED_ERROR("Address Line 1")),
  addressLine2: yup.string(),
  city: yup.string().required(REQUIRED_ERROR("City")),
  zipCode: yup.string().required(REQUIRED_ERROR("Zip Code")),
  country: yup.mixed<IState>().required(REQUIRED_ERROR("Country")),
  state: yup.mixed<IState>().required(REQUIRED_ERROR("State"))
})
