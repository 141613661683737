import {
  UploadModalsResponse,
  useLazyGetModelsDetailQuery,
  useLazyGetModelsStatusQuery,
  useUploadModalsMutation
} from "@/services/apiDigifabster/model"
import {
  PreselectionResponse,
  useCreateOrderMutation,
  useCreateUploadJobMutation,
  usePreselectModelMutation,
  usePurchaseProductMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import { UploadFile } from "antd"
import { useRef } from "react"
import { useSelector } from "react-redux"

export interface IUploadData {
  orderId?: number
  files: Record<string, UploadFile>
  error?: string
}

export const useUploadModels = () => {
  const [uploadJob] = useCreateUploadJobMutation()
  const [uploadModals] = useUploadModalsMutation()
  const [preselectMaterial] = usePreselectModelMutation()
  const [createOrder] = useCreateOrderMutation()
  const [purchaseProduct] = usePurchaseProductMutation()
  const [getModelsStatus] = useLazyGetModelsStatusQuery()
  const [getModelsDetail] = useLazyGetModelsDetailQuery()

  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const childModels = useRef<number[]>([])

  const getConfig = (resData: PreselectionResponse) => {
    const configData = Object.keys(resData).map((modelId) => {
      const data = resData[modelId as any]
      const config: Record<string, string> = {}
      const material = technologies
        .flatMap((e) => e.materials)
        .find((e) => e.id === data.material)
      if (material) {
        const { filling, layerThickness, leadTime, color } = material
        if (filling && filling.length) config["filling"] = filling[0].uuid
        if (layerThickness && layerThickness.length)
          config["layer_thickness"] = layerThickness[0].uuid
        if (leadTime && leadTime.length) config["lead_time"] = leadTime[0].uuid
        if (color && color.length) config["color"] = color[0].uuid
      }

      return {
        model_id: Number(modelId),
        material_id: data.material,
        config
      }
    })

    return configData
  }

  const checkStatus = async (
    modelIds: number[]
  ): Promise<{ failed: number[]; ready: number[] }> => {
    const { data } = await getModelsStatus({ models: modelIds })
    if (!data) throw Error()

    const isNotDone = Object.values(data).some((e) => e === "in_progress")
    if (isNotDone) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          checkStatus(modelIds).then(res).catch(rej)
        }, 1000)
      })
    }

    const failed = Object.keys(data)
      .filter((e: any) => data[e] === "failed")
      .map<number>((e) => parseInt(e))
    const ready = Object.keys(data)
      .filter((e: any) => data[e] === "ready")
      .map<number>((e) => parseInt(e))

    return {
      failed,
      ready
    }
  }

  const checkModels = async (modelIds: number[]) => {
    const { data } = await getModelsDetail({
      id: [...childModels.current, ...modelIds].join(",")
    })
    if (!data) throw Error()

    childModels.current = data.results.map((e) => e.child_models).flat()
    const isInProgress = data.results.some(
      (e) => e.thumb_status === "in_progress"
    )

    if (isInProgress) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          checkModels(modelIds).then(res).catch(rej)
        }, 1000)
      })
    }

    return
  }

  const getUploadSuccessModels = (models: UploadModalsResponse[]) => {
    const failedModels = models.filter((e) => e.detail)
    const successModels = models.filter((e) => !e.detail)

    return {
      failedModels,
      successModels
    }
  }

  const uploadModels = async (
    files: UploadFile[],
    unit: string,
    option?: {
      orderId?: number
      uploadJobId?: string
      uploadInterupted?: (failedFile: UploadFile[]) => void
      failedForAll?: boolean
      onlyUploadFiles?: boolean
      onlyPurchase?: boolean
    }
  ): Promise<IUploadData> => {
    try {
      let orderId: number
      let uploadJobId: string

      if (option?.uploadJobId) {
        uploadJobId = option.uploadJobId
      } else {
        const { data: uploadJobData } = await uploadJob()
        if (!uploadJobData) throw new Error("Upload fail")

        uploadJobId = uploadJobData.uj
      }
      if (option?.orderId) {
        orderId = option.orderId
      } else {
        const { data: order } = await createOrder({
          uploadJobId: uploadJobId
        })
        if (!order) throw new Error("Failed to create order")

        orderId = order.id
      }

      let models: UploadModalsResponse[] = []
      if (option?.onlyPurchase) {
        models = files
          .map<UploadModalsResponse>((e) => e.response?.data)
          .filter(Boolean)
        console.log("models", models)
      } else {
        // UPLOAD STEP
        const uploadPayload = new FormData()
        files.forEach((file) => {
          if (!file.originFileObj) return
          uploadPayload.append("models", file.originFileObj, file.fileName)
        })

        uploadPayload.append("upload_job_id", uploadJobId)
        const units = files.reduce(
          (pre, cur) => ({
            ...pre,
            [String(cur.fileName || cur.name)]: unit
          }),
          {}
        )
        uploadPayload.append("models_units", JSON.stringify(units))

        const { data: modelsData } = await uploadModals(uploadPayload)
        if (!modelsData) throw new Error("Failed to upload models")

        const { failedModels, successModels } =
          getUploadSuccessModels(modelsData)
        models = successModels
        if (option?.failedForAll && failedModels.length) {
          throw new Error(failedModels[0].detail)
        }
        if (option?.uploadInterupted && failedModels.length) {
          const failedFiles = failedModels
            .map<UploadFile | undefined>((e) => {
              const file = files.find((f) => e.file_name === f.name)
              if (file) {
                file.status = "error"
                file.error = e.detail
              }

              return file
            })
            .filter(Boolean)
          option.uploadInterupted(failedFiles as UploadFile[])
        }

        if (option?.onlyUploadFiles) {
          const uploadedFiles = successModels
            .map<UploadFile | undefined>((e) => {
              const file = files.find((f) => e.file_name === f.name)
              if (file) {
                file.status = e.detail ? "error" : "uploading"
                file.error = e.detail
                file.response = {
                  orderId,
                  data: e
                }
              }

              return file
            })
            .filter(Boolean) as UploadFile[]

          return {
            orderId,
            files: uploadedFiles.reduce(
              (pre, cur) => ({ ...pre, [cur.uid]: cur }),
              {}
            )
          }
        }
      }

      // CHECK STATUS
      const modelIds = models.flatMap((e) =>
        e.object_models.map((model) => model.id)
      )
      const { failed, ready } = await checkStatus(modelIds)

      const statusFailedModels = models.filter((e) =>
        failed.find((r) => r === e.object_models[0].id)
      )
      if (option?.failedForAll && statusFailedModels.length) {
        throw new Error("Upload failed")
      }
      if (statusFailedModels.length && option?.uploadInterupted) {
        const statusFailedFiles = statusFailedModels
          .map<UploadFile | undefined>((e) => {
            const file = files.find((f) => e.file_name === f.name)
            if (file) {
              file.status = "error"
              file.error = "Upload failed."
            }

            return file
          })
          .filter(Boolean)
        option.uploadInterupted(statusFailedFiles as UploadFile[])
      }
      // PURCHASE
      await checkModels(ready)
      const { data: preselectData } = await preselectMaterial({
        models: ready
      })
      if (!preselectData) throw new Error("Failed to preselect materials")

      const configs = getConfig(preselectData)
      const failedPurchase: UploadFile[] = []
      const successPurchase: UploadFile[] = []
      await Promise.all(
        configs.map(async (config) => {
          const { data: purchaseData } = await purchaseProduct({
            orderId: orderId,
            arg: { ...config, count: 1 }
          })
          if (!purchaseData) {
            const model = models.find(
              (e) => e.object_models[0].id === config.model_id
            )
            const file = files.find((f) => f.name === model?.file_name)
            if (file) {
              file.status = "error"
              file.error = "The technology analysis process failed."
              failedPurchase.push(file)
            }
            return
          } else {
            const model = models.find(
              (e) => e.object_models[0].id === config.model_id
            )
            const file = files.find((f) => f.name === model?.file_name)
            if (file) {
              const _file: UploadFile = { ...file, status: "done" }
              successPurchase.push(_file)
            }
          }
        })
      )
      if (option?.failedForAll && failedPurchase.length) {
        throw new Error("The technology analysis process failed.")
      }
      if (option?.uploadInterupted && failedPurchase.length) {
        option.uploadInterupted(failedPurchase)
      }

      return {
        orderId,
        files: successPurchase.reduce<Record<string, UploadFile>>(
          (pre, cur) => ({ ...pre, [cur.uid]: cur }),
          {}
        )
      }
    } catch (err: any) {
      return {
        files: files.reduce<Record<string, UploadFile>>(
          (pre, cur) => ({
            ...pre,
            [cur.uid]: { ...cur, status: "error", error: "Upload Failed." }
          }),
          {}
        ),
        error: err.message || err
      }
    }
  }

  return { uploadModels }
}
