import { DEFAULT_WIDTH } from "@/constants/common.constant"
import {
  IMapSelectDropdownItem,
  ISelectDropdownItem
} from "@/interfaces/common.interface"
import { mapItemsToSelectOptions } from "@/utils/functionHelper"
import { CheckOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Flex, Select, SelectProps } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { FieldError } from "react-hook-form"
import "./SelectDropdown.scss"

const { Option } = Select

export interface SelectDropdownProps extends SelectProps {
  options: ISelectDropdownItem[]
  error?: FieldError | undefined
  multiple?: boolean
  width?: number | string
  height?: number | string
  addNewValue?: () => void
  placeholderSearch?: string
  prefixIcon?: React.ReactNode
  searchBar?: boolean
  dropdownClassName?: string
  valueChangeToClear?: string | number
  listHeight?: number
  customCheckedIcon?: React.ReactNode
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  width = DEFAULT_WIDTH,
  height,
  placeholder = "Select",
  addNewValue,
  placeholderSearch = "Search",
  prefixIcon,
  searchBar = false,
  dropdownClassName,
  valueChangeToClear,
  defaultValue = null,
  listHeight = 198,
  customCheckedIcon,
  value,
  ...props
}) => {
  const mapOptions: IMapSelectDropdownItem[] = useMemo(
    () => mapItemsToSelectOptions(options),
    [options]
  )
  const [items, setItems] = useState<IMapSelectDropdownItem[]>(mapOptions)
  const [selected, setSelected] = useState<IMapSelectDropdownItem>()
  const [searchText, setSearchText] = useState("")
  const [itemsReady, setItemsReady] = useState(false)

  const filterOptions = (input: string, option: IMapSelectDropdownItem) => {
    if (!option) return false
    return (
      option.label.toLowerCase().includes(input.toLowerCase()) ||
      option?.subTitle?.toLowerCase().includes(input.toLowerCase())
    )
  }

  useEffect(() => {
    const mappedItems = mapOptions
    setItems(mappedItems)
    setItemsReady(mappedItems.length > 0)
  }, [mapOptions])

  useEffect(() => {
    if (itemsReady) {
      setSelected(undefined)
      const updateSelected = (key: string | number) => {
        const selectedItem = items.find(
          (item) => item.key.toString() === key.toString()
        )
        setSelected(selectedItem ? selectedItem : undefined)
      }

      if (defaultValue) {
        updateSelected(defaultValue)
      }
      if (value) {
        updateSelected(value)
      }
    }
  }, [valueChangeToClear, itemsReady, setItemsReady, value, items])

  useEffect(() => {
    if (!defaultValue) {
      setSelected(undefined)
    }
  }, [defaultValue])

  const filteredOptions = useMemo(() => {
    return items.filter((item) => filterOptions(searchText, item))
  }, [items, searchText])

  const handleSelect = (
    value: string | IMapSelectDropdownItem,
    option: IMapSelectDropdownItem
  ) => {
    const itemToSelect = items.find(
      (item) => item.key.toString() === option.key.toString()
    )
    setSearchText("")
    if (itemToSelect) {
      setSelected(itemToSelect)
      const updatedItems = items.map((item) =>
        item.key.toString() === option.key.toString()
          ? { ...item, checked: true }
          : { ...item, checked: false }
      )
      setItems(updatedItems)
    }
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  return (
    <div
      className="select-wrapper"
      style={{
        height: `${typeof height === "string" ? height : `${height}px`}`,
        width: `${typeof width === "string" ? width : `${width}px`}`
      }}
    >
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select
        {...props}
        listHeight={listHeight}
        className="select-dropdown"
        placeholder={<div className="placeholder-text">{placeholder}</div>}
        style={{ width: "100%" }}
        showSearch={false}
        value={selected}
        onSelect={(value, option) =>
          handleSelect(value, option as IMapSelectDropdownItem)
        }
        filterOption={false}
        popupClassName={`dropdown-menu ${dropdownClassName}`}
        menuItemSelectedIcon={<CheckOutlined />}
        notFoundContent={<div className="not-found-text">No results found</div>}
        dropdownRender={(menu) => (
          <>
            <div>
              {searchBar ? (
                <Select
                  showSearch
                  open={false}
                  suffixIcon={null}
                  searchValue={searchText}
                  placeholder={
                    <div className="placeholder-search-text">
                      {placeholderSearch}
                    </div>
                  }
                  onSearch={handleSearch}
                  style={{
                    width: `98%`,
                    margin: 3,
                    borderColor: "var(--border-normal)",
                    borderRadius: 2
                  }}
                />
              ) : null}
            </div>
            {menu}
            {addNewValue ? (
              <Flex className="footer-dropdown" align="center" justify="center">
                <Button
                  icon={<PlusOutlined />}
                  danger
                  type="text"
                  onClick={addNewValue}
                >
                  <div className="add-btn-text">Add new value</div>
                </Button>
              </Flex>
            ) : null}
          </>
        )}
      >
        {filteredOptions.map((item) => (
          <Option key={item.key} value={item.value} label={item.label}>
            <div
              className={`item-container ${
                item?.subTitle && item?.prefix ? "flex-start" : "center"
              }`}
            >
              {item?.prefix ? <>{item.prefix}</> : null}
              <div style={{ flex: 1 }}>
                <div className="item-text">{item.label}</div>
                {item?.subTitle ? (
                  <div style={{ marginTop: 8 }} className="sub-item-text">
                    {item.subTitle}
                  </div>
                ) : null}
              </div>
              {selected?.id === item.id &&
                (customCheckedIcon ? customCheckedIcon : <CheckOutlined />)}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default SelectDropdown
