import React from "react"
import "./SideBar.scss"
import { NavLink, useLocation } from "react-router-dom"
import { avatarDefaultImage, Logo, Logout } from "@/assets"
import { ITabs } from "@/interfaces/common.interface"
import { useDispatch, useSelector } from "react-redux"
import { LOGOUT_ACTION } from "@/store/action"
import { IUserStore } from "@/store/user"
import { RootState } from "@/store"
interface ISideBarProps {
  tabs: ITabs[]
}
const SideBar: React.FC<ISideBarProps> = ({ tabs }) => {
  const location = useLocation()
  const isProfileRoute = location.pathname.includes("/profile")
  const dispatch = useDispatch()
  const { userInfo } = useSelector<RootState, IUserStore>((s) => s.user)

  const onLogOut = () => {
    dispatch({ type: LOGOUT_ACTION })
  }
  return (
    <div className={`sidebar-wrapper`}>
      <div className="sidebar-container">
        <div className="sidebar-top">
          <img src={Logo} alt="img" width={52} height={52} />

          <div className="sidebar-items">
            {tabs.map((tab, index) => (
              <NavLink
                className={({ isActive }) => {
                  return isActive || location.pathname.includes(tab.routerLink)
                    ? `sidebar-item-active sidebar-item-container`
                    : "sidebar-item-container"
                }}
                to={tab.routerLink}
                key={index}
                end
              >
                {({ isActive }) => (
                  <div className="sidebar-item">
                    <img
                      src={
                        isActive || location.pathname.includes(tab.routerLink)
                          ? tab.imgSrcActive
                          : tab.imgSrc
                      }
                      alt="img"
                      width={24}
                      height={24}
                    />
                    <p className="sidebar-item-text">{tab.name}</p>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="sidebar-bottom">
          <NavLink
            className={
              isProfileRoute
                ? `sidebar-bottom-avatar-active`
                : "sidebar-bottom-avatar"
            }
            to={"/profile/infor"}
            end
          >
            <img
              src={userInfo?.avatar || avatarDefaultImage}
              width={48}
              height={48}
              alt=""
            />
          </NavLink>

          <div className="sidebar-bottom-icon" onClick={onLogOut}>
            <img src={Logout} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
