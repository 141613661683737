import { authService } from "@/services/apiDigifabster/auth"
import {
  ActionReducerMapBuilder,
  createSlice,
  isAnyOf,
  PayloadAction
} from "@reduxjs/toolkit"

export interface IStorageState {
  accessToken?: string
  refreshToken?: string
}

const initialState: IStorageState = { accessToken: "", refreshToken: "" }
const storageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    setToken: (state: IStorageState, action: PayloadAction<IStorageState>) => {
      state = action.payload
    }
  },
  extraReducers: (builders: ActionReducerMapBuilder<IStorageState>) => {
    builders.addMatcher(
      isAnyOf(
        authService.endpoints.register.matchFulfilled,
        authService.endpoints.login.matchFulfilled,
        authService.endpoints.googleLogIn.matchFulfilled
      ),
      (state, action) => {
        const { token } = action.payload
        state.accessToken = token
      }
    )
  }
})
export const { setToken } = storageSlice.actions
export const storageReducer = storageSlice.reducer
