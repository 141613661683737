import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import OrderReviewItem from "../OrderReviewItem/OrderReviewItem"
import "./OrderReview.scss"
import { useNavigate } from "react-router-dom"
import { FormField, Textarea } from "@/components"
import { Controller, useForm } from "react-hook-form"
import { Form } from "antd"
import { INotesForm } from "@/interfaces/notes.interface"

interface IOrderReviewProps {
  onSubmit: (data: INotesForm) => void
}

const OrderReview: React.FC<IOrderReviewProps> = ({ onSubmit }) => {
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const navigate = useNavigate()

  const { control, handleSubmit } = useForm<INotesForm>({
    defaultValues: {
      notes: ""
    }
  })

  useEffect(() => {
    if (currentQuote?.products?.length === 0)
      navigate(`/new-quote/specification/${currentQuote?.id}`)
  }, [currentQuote])

  if (!currentQuote?.products) return <></>
  return (
    <>
      <div className="order-review">
        <div className="order-review-test">
          <p>PART</p>
          <p>SPECIFICATION</p>
          <p>POST PROCESSING</p>
          <p>UNITS</p>
          <p>PRICE</p>
        </div>
        {currentQuote?.products?.map((order) => (
          <OrderReviewItem key={order.id} item={order}></OrderReviewItem>
        ))}
      </div>

      <div className="order-note">
        <Form
          layout="vertical"
          rootClassName="order-note-form"
          onChange={handleSubmit(onSubmit)}
        >
          <FormField textLabel="Note">
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <Textarea
                  placeholder="Additional note"
                  size="large"
                  autoSize={{ minRows: 5 }}
                  {...field}
                />
              )}
            />
          </FormField>
        </Form>
      </div>
    </>
  )
}

export default OrderReview
