import "./Header.scss"

interface IHeaderProps {
  children?: React.ReactNode
  customClassName?: string
}
export default function Header({
  children,
  customClassName
}: IHeaderProps): JSX.Element {
  return (
    <>
      <div className={`heading ${customClassName}`}>{children}</div>
    </>
  )
}
