import { Button } from "@/components"
import "./BillingInfoDetail.scss"
import { IBillingFormValues } from "@/interfaces/billing.interface"

interface IBillingInfoDetailProps {
  onEdit: () => void
  prefillData?: IBillingFormValues
}

function BillingInfoDetail({ onEdit, prefillData }: IBillingInfoDetailProps) {
  return (
    <div className="billing-detail">
      <div className="billing-detail-info">
        <p className="billing-detail-title">Billing Infomation</p>
        <div className="billing-detail-info-contact">
          <p className="contact-title">BILLING CONTACT</p>
          <div className="contact-detail">
            <p>
              {prefillData?.firstName || ""}, {prefillData?.lastName || ""}
            </p>
            <p>
              {prefillData?.phoneNumber}, {prefillData?.accountPayEmail}
            </p>
          </div>
        </div>
        <div className="billing-detail-info-address">
          <p className="address-title">BILLING ADDRESS</p>
          <div className="address-detail">
            <p>{prefillData?.company || ""}</p>
            <p>
              {prefillData?.addressLine1}
              {`${
                prefillData?.addressLine2
                  ? `, ${prefillData?.addressLine2}`
                  : ""
              }`}
            </p>
            <p>
              {prefillData?.city || ""},{prefillData?.state.name || ""}
            </p>
            <p>
              {prefillData?.country.name || ""}, {prefillData?.zipCode || ""}
            </p>
          </div>
        </div>
        <div>
          <Button onClick={onEdit}>Edit</Button>
        </div>
      </div>
    </div>
  )
}

export default BillingInfoDetail
