import {
  Button,
  CountrySelect,
  FormField,
  SelectDropdown,
  TextField
} from "@/components"
import { Controller, FieldError, SubmitHandler, useForm } from "react-hook-form"
import {
  IBillingFormValues,
  IFormValuesBillingAddress,
  IFormValuesBillingContact
} from "@/interfaces/billing.interface"
import { Col, ConfigProvider, Form, Row } from "antd"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { yupResolver } from "@hookform/resolvers/yup"
import { BILLING_INFORMATION_SCHEMA } from "@/validations/billing.validation"
import {
  DEFAULT_COUNTRY_ID,
  EMTY_STATE,
  errorStatus
} from "@/constants/common.constant"
import { useEffect, useState } from "react"
import { CountryOptions } from "@/interfaces/common.interface"
import { getStatesList } from "@/constants/countries"
import "./BillingInfoEditForm.scss"
import { copyIcon } from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { ICheckoutForm } from "@/interfaces/checkout.interface"

interface BillingInfoEditFormProps {
  onSubmit: SubmitHandler<IBillingFormValues>
  prefillData?: ICheckoutForm
}

function BillingInfoEditForm({
  onSubmit,
  prefillData
}: BillingInfoEditFormProps) {
  const { settings } = useSelector<RootState, IUserStore>((s) => s.user)
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [stateList, setStateList] = useState<CountryOptions[]>([])

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue
  } = useForm<IBillingFormValues>({
    resolver: yupResolver(BILLING_INFORMATION_SCHEMA),
    defaultValues: {
      firstName: prefillData?.billing?.firstName || "",
      lastName: prefillData?.billing?.lastName || "",
      phoneNumber: prefillData?.billing?.phoneNumber || "",
      accountPayEmail: prefillData?.billing?.accountPayEmail || "",
      state: prefillData?.billing?.state || EMTY_STATE,
      country: prefillData?.billing?.country || EMTY_STATE,
      addressLine1:
        prefillData?.billing?.addressLine1 ||
        settings?.company_address?.full_address ||
        "",
      addressLine2:
        prefillData?.billing?.addressLine2 !== undefined
          ? prefillData?.billing?.addressLine2
          : settings?.company_address?.apartment,
      company:
        prefillData?.billing?.company !== undefined
          ? prefillData?.billing?.company
          : settings?.company_full_title,
      city: prefillData?.billing?.city || settings.company_address?.city || "",
      zipCode:
        prefillData?.billing?.zipCode ||
        settings?.company_address?.postcode ||
        ""
    }
  })

  useEffect(() => {
    if (!isDirty) {
      const prefillCountry = countriesList.find(
        (item) => item.iso2 === settings?.company_address?.country
      )
      const mapprefillCountry = {
        name: prefillCountry?.title || "",
        id: prefillCountry?.id || "",
        iso2: prefillCountry?.iso2 || ""
      }
      setValue(
        "country",
        prefillData?.billing?.country ||
          mapprefillCountry || {
            id: DEFAULT_COUNTRY_ID,
            name: "Canada",
            iso2: "CA"
          }
      )
    }
  }, [countriesList, isDirty])

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
      if (!isDirty) {
        const prefillState = states.find(
          (item) => item.iso2 === settings?.company_address?.state
        )
        const mapprefillState = {
          name: prefillState?.title || "",
          id: prefillState?.id || "",
          iso2: prefillState?.iso2 || ""
        }
        setValue(
          "state",
          prefillData?.billing?.state || mapprefillState || EMTY_STATE
        )
      }
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country"), isDirty])

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || {})
  }

  const copyBillingContactData = () => {
    const existingData: IFormValuesBillingContact = {
      firstName: prefillData?.delivery_address?.name || "",
      lastName: prefillData?.delivery_address?.surname || "",
      phoneNumber: prefillData?.delivery_address?.phoneNumber || "",
      accountPayEmail: prefillData?.delivery_address?.email || ""
    }

    Object.keys(existingData).forEach((key) => {
      setValue(
        key as keyof IFormValuesBillingContact,
        existingData[key as keyof IFormValuesBillingContact],
        { shouldDirty: true }
      )
    })
  }

  const copyBillingAdressData = () => {
    const existingData: IFormValuesBillingAddress = {
      company: prefillData?.delivery_address?.company || "",
      state: prefillData?.delivery_address?.state || EMTY_STATE,
      country: prefillData?.delivery_address?.country || {
        id: DEFAULT_COUNTRY_ID,
        name: "Canada",
        iso2: "CA"
      },
      addressLine1: prefillData?.delivery_address?.addressLine1 || "",
      addressLine2: prefillData?.delivery_address?.addressLine2 || "",
      city: prefillData?.delivery_address?.city || "",
      zipCode: prefillData?.delivery_address?.zipCode || ""
    }
    Object.keys(existingData).forEach((key) => {
      setValue(
        key as keyof IFormValuesBillingAddress,
        existingData[key as keyof IFormValuesBillingAddress],
        { shouldDirty: true }
      )
    })
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", sans-serif'
        }
      }}
    >
      <Form onFinish={handleSubmit(onSubmit)}>
        <div className="billing-contact">
          <p className="billing-contact-title">Billing Contact</p>
          <div style={{ paddingBottom: "8px" }}>
            <Button
              customSize={Size.SMALL}
              hierarchy={ButtonHierarchy.TONAL}
              onClick={copyBillingContactData}
            >
              <img src={copyIcon} alt="" /> Copy from contact
            </Button>
          </div>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="First name"
                errorText={errors.firstName?.message}
              >
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Input first name"
                      status={errorStatus(errors?.firstName)}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Last name"
                errorText={errors.lastName?.message}
              >
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Input last name"
                      status={errorStatus(errors.lastName)}
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Phone Number"
                errorText={errors.phoneNumber?.message}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="(xxx) number"
                      status={errorStatus(errors.phoneNumber)}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Accounts Payable Email"
                errorText={errors.accountPayEmail?.message}
              >
                <Controller
                  name="accountPayEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="To receive invoices"
                      status={errorStatus(errors.accountPayEmail)}
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </div>
        <div className="billing-address">
          <p className="billing-address-title">Billing Address</p>
          <div style={{ paddingBottom: "8px" }}>
            <Button
              customSize={Size.SMALL}
              hierarchy={ButtonHierarchy.TONAL}
              onClick={copyBillingAdressData}
            >
              <img src={copyIcon} alt="" /> Copy from delivery address
            </Button>
          </div>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField textLabel="Company (optional)">
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter company name"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField textLabel="Tax Number (optional)">
                <Controller
                  name="taxNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Tax Number (optional)"
                      type="number"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Address Line 1"
                errorText={errors.addressLine1?.message}
              >
                <Controller
                  name="addressLine1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.addressLine1)}
                      {...field}
                      placeholder="Street Address"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Address Line 2"
                errorText={errors.addressLine2?.message}
              >
                <Controller
                  name="addressLine2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.addressLine2)}
                      {...field}
                      placeholder="Unit"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField textLabel="City" errorText={errors.city?.message}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.city)}
                      placeholder="City"
                      {...field}
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="State / Province"
                errorText={errors.state?.message}
              >
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      status={errorStatus(errors?.state as FieldError)}
                      width={"100%"}
                      dropdownClassName="dropdown-menu"
                      value={value?.id}
                      searchBar={true}
                      valueChangeToClear={watch("country")?.id}
                      onChange={(stateId) =>
                        handleChangeState(stateId, onChange)
                      }
                      options={stateList}
                      virtual={false}
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Zip / Postal Code"
                errorText={errors.zipCode?.message}
              >
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      status={errorStatus(errors.zipCode)}
                      placeholder="Postal Code"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Country"
                errorText={errors.country?.message}
              >
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CountrySelect
                      countriesList={countriesList}
                      status={errorStatus(errors?.country as FieldError)}
                      setCountriesList={setCountriesList}
                      flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                      value={value.id}
                      width={"100%"}
                      dropdownClassName="dropdown-menu"
                      handleChange={(countryId) =>
                        handleChangeCountry(countryId, onChange)
                      }
                      placeholder="Select your country"
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </div>
        <Button htmlType="submit">Done</Button>
      </Form>
    </ConfigProvider>
  )
}

export default BillingInfoEditForm
