import Modal from "../Modal/Modal"
import Button from "../common/atom/Button/Button"
import { Size } from "@/enums/common.enum"
import SelectDropdown from "../common/atom/SelectDropdown/SelectDropdown"
import { UNIT_OPTIONS } from "@/constants/unit.constant"
import { EUnit } from "@/enums/unit.enum"
import "./ModalSelectUnit.scss"
import { closeModalIcon } from "@/assets"

interface IModalSelectUnitProps {
  openModal: boolean
  closeModal: () => void
  onChangeUnit: (id: EUnit) => void
  unit: EUnit
  onSelectedUnit: () => void
}

function ModalSelectUnit({
  openModal,
  closeModal,
  onChangeUnit,
  unit,
  onSelectedUnit
}: IModalSelectUnitProps) {
  return (
    <Modal
      title="Select Design Units"
      width={401}
      hideCancel
      footer={false}
      open={openModal}
      onCancel={closeModal}
      className="modal-resize-container"
      closeIcon={<img src={closeModalIcon}></img>}
    >
      <div className="modal-resize-container-box">
        <div className="modal-resize-container-box-select">
          <SelectDropdown
            width={"100%"}
            placeholder="Please select"
            value={unit}
            onChange={onChangeUnit}
            options={UNIT_OPTIONS}
          />
        </div>
        <Button
          onClick={onSelectedUnit}
          customSize={Size.LARGE}
          customClassName="modal-resize-container-box-btn"
        >
          Next
        </Button>
      </div>
    </Modal>
  )
}

export default ModalSelectUnit
