import { Order } from "@/interfaces/orderListTable.interface"
import { Button, Table } from "@/components"
import { ColumnType } from "antd/es/table"
import { Size, ButtonHierarchy } from "@/enums/common.enum"
import { quotesListColumn } from "@/constants/quoteListTable.constant"
import { EOrderStatus } from "@/enums/quotesList.enum"

interface OrderListTableProps {
  data: Order[]
  onDetail: (id: number) => void
}

const OrderListTable = ({
  data,
  onDetail
}: OrderListTableProps): JSX.Element => {
  const orderListColumns: ColumnType<Order>[] = [
    ...quotesListColumn,
    {
      title: <div className="status-col">STATUS</div>,
      key: "quoteStatus",
      width: "20%",
      render: (_: any, record: Order) => (
        <div
          className={`${
            record.status === EOrderStatus.PLACED
              ? "order-status-success"
              : "order-status"
          }`}
        >
          {record.quoteStatus}
        </div>
      )
    },
    {
      title: "",
      key: "action",
      width: "15%",
      render: (_: any, record: Order) => (
        <div style={{ textAlign: "center" }}>
          <Button
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.TONAL}
            onClick={() => onDetail(record.id)}
          >
            Details
          </Button>
        </div>
      )
    }
  ]
  return <Table data={data} columns={orderListColumns} rowKey="id" />
}

export default OrderListTable
