import { Code } from "@/services/api/auth"

export const ErrorMessage: Record<Code, string> = {
  [Code.Success]: "Success",
  [Code.InternalServerError]: "Internal Server Error",
  [Code.CredentialError]: "Password or email does not match",
  [Code.InvalidUserToken]: "",
  [Code.InvalidToken]: "",
  [Code.UserNotVerified]: "",
  [Code.VerifyEmailError]: "",
  [Code.ResetPasswordError]: "",
  [Code.UserNotFound]: "User not found",
  [Code.SameEmailError]: "This email address has been taken",
  [Code.Unauthorized]: "",
  [Code.BillingAddAvailableError]: ""
}
