import { api } from "../apiInstance"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadModel: build.mutation<UploadModelApiResponse, UploadModelApiArg>({
      query: (queryArg) => ({
        url: `/upload/model`,
        method: "POST",
        body: queryArg.modelUploadDto
      })
    }),
    uploadAvatar: build.mutation<UploadAvatarApiResponse, UploadAvatarApiArg>({
      query: (queryArg) => ({
        url: `/upload/avatar`,
        method: "POST",
        body: queryArg.fileUploadDto
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as uploadService }
export type UploadModelApiResponse = /** status 200  */ IResponse & {
  data: ModelEntity
}
export type UploadModelApiArg = {
  /** Model file to be uploaded */
  modelUploadDto: ModelUploadDto
}
export type UploadAvatarApiResponse = /** status 200  */ IResponse & {
  data: string
}
export type UploadAvatarApiArg = {
  /** Avatar file to be uploaded */
  fileUploadDto: FileUploadDto
}
export type IResponse = {
  /** Application code */
  code: Code
  /** Can be success or error message */
  message: string
  /** Is API success */
  success: boolean
  path?: string
  timestamp?: string
}
export type ModelEntity = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  fileName: string
  originalFile: string
  convertedFile: string
  thumbnail?: string
  height?: number
  width?: number
  length?: number
  size?: string
}
export type ModelUploadDto = {
  file: Blob
}
export type FileUploadDto = {
  file: Blob
}
export enum Code {
  Success = 1000,
  InternalServerError = 1001,
  InvalidUserToken = 1002,
  InvalidToken = 1003,
  Unauthorized = 1004,
  UserNotVerified = 2001,
  VerifyEmailError = 2002,
  ResetPasswordError = 2003,
  CredentialError = 2004,
  UserNotFound = 2005,
  SameEmailError = 2006,
  BillingAddAvailableError = 3001
}
export const { useUploadModelMutation, useUploadAvatarMutation } =
  injectedRtkApi
