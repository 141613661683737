import {
  REGEX_EMAIL,
  REGEX_ONLY_NUMBER,
  REQUIRED_ERROR
} from "@/constants/common.constant"
import { IState } from "@/interfaces/common.interface"
import { mixed, object, string } from "yup"

export const DELIVERY_SCHEMA = object({
  name: string().required(REQUIRED_ERROR("First name")),
  surname: string().required(REQUIRED_ERROR("Last name")),
  phoneNumber: string()
    .matches(REGEX_ONLY_NUMBER, "Phone number can only contain numbers")
    .length(10, "Phone number must be exactly 10 digits")
    .required(REQUIRED_ERROR("Phone number")),
  email: string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format"),
  additionalEmail: string(),
  company: string(),
  addressLine1: string().required(REQUIRED_ERROR("Address Line 1")),
  addressLine2: string(),
  city: string().required(REQUIRED_ERROR("City")),
  state: mixed<IState>()
    .required(REQUIRED_ERROR("State"))
    .test("required", REQUIRED_ERROR("State"), (value) => {
      if (value) {
        return !!value.id || !!value.name
      }
      return true
    }),
  zipCode: string().required(REQUIRED_ERROR("Zip Code")),
  country: mixed<IState>().required(REQUIRED_ERROR("Country"))
})
