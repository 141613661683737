import {
  Product,
  useDeleteDrawingsMutation,
  useDeletePurchaseMutation
} from "@/services/apiDigifabster/quote"
import { useMemo, useState } from "react"
import "./OrderReviewItem.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { ModelResponse } from "@/services/apiDigifabster/model"
import { Button } from "@/components"
import { ButtonHierarchy } from "@/enums/common.enum"
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { useAppLoading } from "@/hooks/useLoading"
import { IQuoteStore } from "@/store/quote"
import { priceDisplay } from "@/utils/functionHelper"

interface IOrderReviewItemProps {
  item: Product
}

function OrderReviewItem({ item }: IOrderReviewItemProps) {
  const {
    config_formatted_json,
    model_thumbnail,
    model_title,
    model_id,
    material_display_name,
    config,
    id,
    total_price,
    price_per_part,
    drawing_files,
    accept_drawing,
    count
  } = item
  const [deletePurchase, { isLoading: isLoadingDelete }] =
    useDeletePurchaseMutation()
  const [deleteDrawings, { isLoading: isLoadingDeleteDrawing }] =
    useDeleteDrawingsMutation()

  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[model_id]
  )
  const tech = useMemo(
    () => config_formatted_json?.find((item) => item.key === "Technology"),
    [config_formatted_json]
  )

  useAppLoading([isLoadingDelete, isLoadingDeleteDrawing])

  const handleDeleteItem = (id: number) => {
    deletePurchase({
      orderId: currentQuote?.id || 0,
      purchaseId: id
    })
  }

  const handleDeleteDrawing = (drawingId: number) => {
    deleteDrawings({
      order_id: currentQuote?.id || 0,
      purchase_id: id || 0,
      drawings_id: drawingId
    })
  }

  return (
    <div className="order-checkout-container">
      <div className="order-review-box-test">
        <div className="order-col-1">
          <div className="img-box-order">
            <img src={model_thumbnail} style={{ verticalAlign: "middle" }} />
          </div>
          <div className="order-col-1-info">
            <p className="text-model-title">{model_title}</p>
            <p>{`${currentModel?.size.x.toFixed(2) || 0} x 
                  ${currentModel?.size.y.toFixed(2) || 0}
                 x 
                  ${currentModel?.size.z.toFixed(2) || 0} ${
              currentModel?.units || ""
            }
                `}</p>
            <p>
              {currentModel?.volume.toFixed(2) ?? 0}{" "}
              {currentModel?.units ?? "mm"}3
            </p>
          </div>
        </div>
        <div className="order-col-2">
          <p className="order-col-2-tech">{tech?.value || ""}</p>
          <p>
            {material_display_name || ""}, {config.color.name_for_user || ""}
          </p>
          <p>{config.layer_thickness.value || 0} um</p>
          <p>{config.filling.name_for_user || ""}</p>
          {/* <p>Production Note</p> */}
        </div>
        <div className="order-col-3">
          {config?.post_production?.length > 0
            ? config.post_production.map((item) => (
                <p key={item.title}>
                  {item.title} {item.countable && `(${item.quantity})`}
                </p>
              ))
            : ""}
        </div>
        <div className="order-col-4">
          <p>{count}</p>
        </div>
        <div className="order-col-5">
          <div className="order-col-5-text">
            <p>${priceDisplay(total_price || 0)}</p>
            <p>${priceDisplay(price_per_part || 0)} / part</p>
          </div>
          <div className="order-col-5-action">
            <Button
              hierarchy={ButtonHierarchy.LINK}
              onClick={() => handleDeleteItem(id)}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </div>
      {accept_drawing && (
        <div className="order-drawings">
          {drawing_files?.map((item: any) => (
            <div key={item.id} className="order-drawings-item">
              <p>{item.name}</p>
              <CloseOutlined
                width={12}
                height={12}
                onClick={() => handleDeleteDrawing(item.id)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="order-production-time">
        <p>Production Time:</p>
        <p>{config.lead_time.name_for_user || ""}</p>
      </div>
    </div>
  )
}

export default OrderReviewItem
