import { Checkbox, CheckboxProps, Divider } from "antd"
import "./Checkbox.scss"
import { useMemo, useState } from "react"
import { Size } from "@/enums/common.enum"

const CheckboxGroup = Checkbox.Group

interface CustomCheckboxProps extends CheckboxProps {
  size?: Size
  group?: boolean
  options?: string[]
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  size = Size.SMALL,
  group = false,
  options = [],
  ...props
}) => {
  const [checkedList, setCheckedList] = useState<string[]>([])

  const checkAll = useMemo(() => {
    return options.length === checkedList.length
  }, [checkedList])

  const indeterminate = useMemo(() => {
    return checkedList.length > 0 && checkedList.length < options.length
  }, [checkedList])

  const onChange = (list: string[]) => {
    setCheckedList(list)
  }

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    setCheckedList(e.target.checked ? options : [])
  }
  return (
    <>
      {!group && (
        <Checkbox className={`custom-checkbox ${size}`} {...props}></Checkbox>
      )}
      {group && (
        <>
          <Checkbox
            className="custom-checkbox"
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Check all
          </Checkbox>
          <Divider />
          <CheckboxGroup
            className="custom-checkbox"
            options={options}
            value={checkedList}
            onChange={onChange}
          />
        </>
      )}
    </>
  )
}

export default CustomCheckbox
