import React, { useEffect, useState } from "react"
import "./ShippingMethod.scss"
import { RadioButton } from "@/components"
import { Radio, RadioChangeEvent } from "antd"
import MethodDelivery from "./components/MethodDelivery/MethodDelivery"
import MethodCollect from "./components/MethodCollect/MethodCollect"
import MethodLocalPickUp from "./components/MethodLocalPickUp/MethodLocalPickUp"
import { EShippingMethod } from "@/enums/checkout.enum"
import { ShippingRateResponse } from "@/services/apiDigifabster/shipping"

interface IShippingMethodProps {
  onChange: (data: {
    method: EShippingMethod
    carrier?: string
    note?: string
  }) => void
  value: string
  shippingRate?: ShippingRateResponse[]
}
const ShippingMethod: React.FC<IShippingMethodProps> = ({
  onChange,
  value,
  shippingRate
}) => {
  const [data, setData] = useState<{
    method: EShippingMethod
    carrier?: string
    note?: string
  } | null>(null)
  const onChangeCarrier = (value: string) => {
    setData((pre) => ({
      method: EShippingMethod.DELIVERY,
      ...pre,
      carrier: value
    }))
  }

  const onChangeNote = (value: string) => {
    setData((pre) => ({
      method: EShippingMethod.DELIVERY,
      ...pre,
      note: value
    }))
  }

  const onChangeValue = (e: RadioChangeEvent) => {
    setData({ method: e.target.value })
  }

  useEffect(() => {
    if (!data) return

    onChange(data)
  }, [data])

  const getMethodUI = (value: string) => {
    switch (value) {
      case EShippingMethod.DELIVERY:
        return (
          <MethodDelivery
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
            data={shippingRate || []}
          />
        )
      case EShippingMethod.COLLECT:
        return (
          <MethodCollect
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
          />
        )
      case EShippingMethod.LOCAL_PICKUP:
        return <MethodLocalPickUp />
      default:
        return (
          <MethodDelivery
            onChangeCarrier={onChangeCarrier}
            onChangeNote={onChangeNote}
            data={shippingRate || []}
          />
        )
    }
  }

  return (
    <div className="shipping-method-box">
      <Radio.Group onChange={onChangeValue} value={value}>
        <div className="shipping-method-box-detail">
          <RadioButton value={EShippingMethod.DELIVERY}>Delivery</RadioButton>
          <RadioButton value={EShippingMethod.COLLECT}>Collect</RadioButton>
          <RadioButton value={EShippingMethod.LOCAL_PICKUP}>
            Local Pickup
          </RadioButton>
        </div>
      </Radio.Group>
      {getMethodUI(value)}
    </div>
  )
}

export default ShippingMethod
