import * as React from "react"
import "./ShippingAddress.scss"
import { PlusOutlined } from "@ant-design/icons"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { useNavigate } from "react-router-dom"
import ShippingAddressItem from "../ShippingAddressItem/ShippingAddressItem"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import { deleteShippingAddress, IUserStore } from "@/store/user"
import {
  useDeleteShippingAddressMutation,
  useGetShippingAddressesQuery
} from "@/services/api/user"
import { useAppLoading } from "@/hooks/useLoading"

interface IShippingAddressProps {}

const ShippingAddress: React.FC<IShippingAddressProps> = ({}) => {
  const [isEditAddress, setIsEditAddress] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { shippingAddresses } = useSelector<RootState, IUserStore>(
    (s) => s.user
  )
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const deliveryAddresses = userInfoNew.last_delivery_address
    ? [userInfoNew.last_delivery_address]
    : []

  useGetShippingAddressesQuery()
  const [deleteShippingAddressService, { isLoading: deleteLoading }] =
    useDeleteShippingAddressMutation()

  const onCreateNewAddress = () => {
    navigate("/profile/shipping-address/create")
  }
  const dispatch = useDispatch()
  useAppLoading([deleteLoading])

  const onEditNewAddress = () => {
    setIsEditAddress(!isEditAddress)
  }

  const onNavigateToEdit = (id?: string) => {
    if (!id) return

    navigate(`/profile/shipping-address/${id}`)
  }

  const handleDelete = async (id?: string) => {
    console.log(id)
    if (!id) return

    await deleteShippingAddressService({ id })
    dispatch(deleteShippingAddress(id))

    setIsEditAddress(false)
  }

  return (
    <div>
      <div className="shipping-address-header">
        <h3>Shipping Address</h3>
        <Button
          type="primary"
          onClick={onCreateNewAddress}
          hierarchy={ButtonHierarchy.TONAL}
          size={"small"}
        >
          <div className="">
            <PlusOutlined
              height={20}
              style={{ marginRight: "8px" }}
              width={20}
              color="var(--bg-danger-solid-normal)"
            />
            Add Address
          </div>
        </Button>
      </div>

      <div className="shipping-address-content">
        {deliveryAddresses.map((shipping, index) => (
          <ShippingAddressItem
            key={`${userInfoNew.id}-${index}`}
            isEdit={isEditAddress}
            // onClickEdit={() => onNavigateToEdit(shipping._id)}
            // onClickDelete={() => handleDelete(shipping._id)}
            labelL={`Address ${index + 1}`}
            labelR="Detail"
            data={shipping}
          />
        ))}
      </div>

      {deliveryAddresses.length ? (
        <div className="shipping-address-footer">
          <Button
            customType={isEditAddress ? Type.NEUTRAL : Type.PRIMARY}
            hierarchy={ButtonHierarchy.OUTLINE}
            customSize={Size.MEDIUM}
            onClick={onEditNewAddress}
            customClassName="shipping-address-footer-btn"
          >
            {isEditAddress ? "Cancel" : "Edit"}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ShippingAddress
