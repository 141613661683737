import { RootState } from "@/store"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const paramsSerializer = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams()

  for (const key in params) {
    const value = params[key]

    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(`${key}`, item)
      })
    } else {
      searchParams.append(key, value)
    }
  }

  return searchParams.toString()
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_ENDPOINT,
  prepareHeaders: async (headers, api) => {
    const token = (api.getState() as RootState).storage.accessToken
    if (token) {
      headers.set("Authorization", `Token ${token}`)
    }
    return headers
  },
  paramsSerializer
})

export const api = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({})
})
