import React from "react"
import "./Modal.scss"
import "../common/atom/Button/Button.scss"
import { Modal as AntModal, ConfigProvider, ModalProps } from "antd"
import Button from "../common/atom/Button/Button"

interface IModalProps extends ModalProps {
  children?: React.ReactNode
  hideCancel?: boolean
}
const Modal: React.FC<IModalProps> = ({ ...props }) => {
  const classNames = {
    body: "custom-modal-body",
    mask: "custom-modal-mask",
    header: "custom-modal-header",
    footer: "custom-modal-footer",
    content: "custom-modal-content"
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", sans-serif'
        }
      }}
    >
      <AntModal
        classNames={classNames}
        footer={
          <div className="custom-modal-footer">
            {!props.hideCancel ? (
              <Button onClick={props.onCancel}>
                {props.cancelText || "cancel"}
              </Button>
            ) : (
              <></>
            )}
            <Button onClick={props.onOk} type="primary">
              {props.okText || "ok"}
            </Button>
          </div>
        }
        centered
        {...props}
      >
        {props.children}
      </AntModal>
    </ConfigProvider>
  )
}

export default Modal
