import { Form, UploadFile, UploadProps } from "antd"
import "./InvoiceSummary.scss"
import { Button, FormField, TextField } from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { attachFileIcon, removeFileIcon } from "@/assets"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore, updateStatusInvoice } from "@/store/quote"
import { debounce, priceDisplay } from "@/utils/functionHelper"
import { useCallback, useEffect, useMemo, useState } from "react"
import Upload from "antd/es/upload/Upload"
import {
  useGetPaymentLinkMutation,
  useSubmitOrderMutation,
  useUpdateInvoiceMutation
} from "@/services/apiDigifabster/order"
import * as toast from "@/utils/Toast"
import { errorStatus } from "@/constants/common.constant"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { IInvoiceForm } from "@/interfaces/checkout.interface"
import { PO_SCHEMA } from "@/validations/credit.validation"
import { EOrderStatus } from "@/enums/quotesList.enum"

enum EStatusPayment {
  QUOTE = "quote",
  PO_PROVIDE = "po_provide",
  READY = "ready",
  PAID = "paid"
}
const statusPayment = {
  [EStatusPayment.QUOTE]: {
    title: "Preliminary Quote",
    classname: "invoice-summary-box-header-status-quote"
  },
  [EStatusPayment.PO_PROVIDE]: {
    title: "PO provided",
    classname: ""
  },
  [EStatusPayment.READY]: {
    title: "Ready for payment",
    classname: ""
  },
  [EStatusPayment.PAID]: {
    title: "Payment succeeded",
    classname: "invoice-summary-box-header-status-paid"
  }
}

const getFileName = (urlString: string) => {
  if (urlString && urlString.startsWith("http")) {
    try {
      const url = new URL(urlString)
      return url.pathname.split("/").pop()
    } catch (error) {
      return ""
    }
  } else {
    return
  }
}

function InvoiceSummary() {
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  const [listFiles, setListFiles] = useState<UploadFile[]>([])
  const [getPaymentLink, { isLoading: isLoadingGetPaymentLink }] =
    useGetPaymentLinkMutation()
  const [updateInvoice, { isLoading: isLoadingUpdateInvoice }] =
    useUpdateInvoiceMutation()
  const order = invoiceDetail?.order
  const isPaid = invoiceDetail?.is_paid
  const { invoice_hash } = useParams()

  useAppLoading([isLoadingUpdateInvoice, isLoadingGetPaymentLink])

  const {
    control,
    formState: { errors, isDirty },
    trigger,
    setValue,
    watch
  } = useForm<IInvoiceForm>({
    defaultValues: {
      poNumber: order?.po_number,
      poFile: []
    },
    resolver: yupResolver(PO_SCHEMA),
    mode: "onChange"
  })
  const poNumber = watch("poNumber")
  const poFile = watch("poFile")

  const debounceFn = useCallback(
    debounce((onChange: () => void) => {
      onChange()
    }, 800),
    []
  )

  const statusPaid = useMemo(() => {
    if (!isPaid) {
      switch (order?.status) {
        case EOrderStatus.WAITING_FOR_REVIEW:
          return EStatusPayment.QUOTE
        case EOrderStatus.PO_PROVIDED:
          return EStatusPayment.PO_PROVIDE
        default:
          return EStatusPayment.READY
      }
    }
    return EStatusPayment.PAID
  }, [invoiceDetail, order?.status])

  const validateAndCheckPO = async () => {
    const result = await trigger()
    if (result && poNumber && poNumber !== order?.po_number) {
      try {
        const uploadPayload = new FormData()
        uploadPayload.append("po_number", poNumber || "")
        if (poFile.length > 0 && poFile[0].originFileObj) {
          uploadPayload.append(
            "attached_po",
            poFile[0].originFileObj as any,
            poFile[0].name
          )
        }
        const payloadUpdateInvoice = {
          invoice_id: Number(invoiceDetail.id),
          invoice_hash: invoice_hash || "",
          arg: uploadPayload
        }
        const { data, error } = await updateInvoice(payloadUpdateInvoice)
        if (!data && error) throw new Error()
      } catch (err) {
        console.error("Failed to check PO number", err)
        toast.showError("Updated invoice fail")
      }
    }
  }

  useEffect(() => {
    if (isDirty) debounceFn(validateAndCheckPO)
  }, [poNumber, poFile])

  useEffect(() => {
    if (order?.po_number) setValue("poNumber", order?.po_number)
    if (order?.attached_po) {
      setListFiles([
        { name: getFileName(order?.attached_po || "") } as UploadFile
      ])
      setValue("poFile", [
        { name: getFileName(order?.attached_po || "") } as UploadFile
      ])
    }
  }, [invoiceDetail])

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setListFiles([file])
    setValue("poFile", [file], { shouldValidate: true })
  }

  const handleRemoveFile = (file: UploadFile) => {
    const updatedFiles = listFiles.filter((item) => item.uid !== file.uid)
    setListFiles(updatedFiles)
    setValue("poFile", updatedFiles, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const customItemRender = (file: UploadFile) => {
    return (
      <div key={file.uid} className="po-file-render">
        <p>{file.name}</p>
        <img
          src={removeFileIcon}
          alt=""
          height={16}
          width={16}
          onClick={() => handleRemoveFile(listFiles[0])}
        />
      </div>
    )
  }

  const handlePlaceOrder = async () => {
    const { data, error } = await getPaymentLink({ orderId: order.id || 0 })
    const errorMsg = (error as any)?.data?.message || ""
    if (error || !data) return toast.showError(errorMsg)
    window.location.href = data.payUrl
  }

  return (
    <Form>
      <div className="invoice-summary">
        <div className="invoice-summary-box">
          <div className="invoice-summary-box-header">
            <div
              className={`invoice-summary-box-header-status ${statusPayment?.[statusPaid].classname}`}
            >
              <p>{statusPayment?.[statusPaid].title}</p>
            </div>
            <p className="invoice-summary-box-header-title">Total</p>
          </div>
          <div className="invoice-summary-box-content">
            <div className="invoice-summary-box-content-price">
              <p>Subtotal</p>
              <p>${priceDisplay(invoiceDetail?.order?.models_naked_price)}</p>
            </div>
            <div className="invoice-summary-box-content-price">
              <p>Delivery</p>
              <p>${priceDisplay(invoiceDetail?.order?.delivery_price)}</p>
            </div>
            <div className="invoice-summary-box-content-price">
              <p>Tax</p>
              <p>${priceDisplay(invoiceDetail?.order?.tax)}</p>
            </div>
          </div>
          <div className="invoice-summary-box-total">
            <div className="invoice-summary-box-total-price">
              <p>Total</p>
              <p>${priceDisplay(invoiceDetail?.order?.total_price)}</p>
            </div>
          </div>
          <div className="invoice-summary-box-btn">
            <Button
              hierarchy={ButtonHierarchy.OUTLINE}
              customSize={Size.LARGE}
              customClassName="invoice-btn"
              onClick={() => {
                window.open(invoiceDetail?.pdf_url || "", "_blank")
              }}
            >
              Download Invoice
            </Button>
          </div>
          <div className="invoice-summary-box-po">
            <FormField
              textLabel="Account Number"
              errorText={isDirty ? errors.poNumber?.message : ""}
            >
              <Controller
                name="poNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder="PO Number"
                    {...field}
                    // onChange={debounce((e) => field.onChange(e), 500)}
                    status={errorStatus(isDirty ? errors.poNumber : undefined)}
                  />
                )}
              />
            </FormField>
            <FormField
              clasName="invoice-summary-box-po-form"
              errorText={isDirty ? errors.poFile?.message : ""}
            >
              <div className="invoice-summary-box-po-form-file">
                {listFiles.length > 0 ? (
                  <>{customItemRender(listFiles[0])}</>
                ) : (
                  <Upload
                    accept="*/*"
                    onChange={handleChangeFile}
                    fileList={listFiles}
                    showUploadList={false}
                  >
                    <Button
                      customClassName="btn-attach-file"
                      hierarchy={ButtonHierarchy.LINK}
                      customSize={Size.EXTRA_SMALL}
                    >
                      <img src={attachFileIcon} alt="Attach file" />
                      Attach PO file
                    </Button>
                  </Upload>
                )}
              </div>
            </FormField>
          </div>
        </div>
        <div className="invoice-summary-footer">
          {!isPaid && order?.status !== EOrderStatus.WAITING_FOR_REVIEW && (
            <Button
              customClassName="btn-place-order"
              customSize={Size.LARGE}
              onClick={handlePlaceOrder}
            >
              Place Order
            </Button>
          )}
          <p>
            Have Questions?
            <a href={`mailto:${settings.support_email}`}>Ask for help</a>
          </p>
        </div>
      </div>
    </Form>
  )
}

export default InvoiceSummary
