import { RootContainer } from "@/components"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import "./OrderDetailPage.scss"
import { orderSuccessIcon } from "@/assets"
import OrderDetailItem from "./components/OrderDetailItem/OrderDetailItem"
import { useLazyGetQuoteDetailQuery } from "@/services/apiDigifabster/quote"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { format } from "date-fns"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import OrderDetailSummary from "./components/OrderDetailSummary/OrderDetailSummary"

function OrderDetailPage() {
  const navigate = useNavigate()
  const { quoteId } = useParams()
  const [getQuoteDetail, { isLoading }] = useLazyGetQuoteDetailQuery()
  const { pathname } = useLocation()

  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const trackingNumber = quoteDetail?.tracking_number || "-"

  const deliveryAddress =
    quoteDetail?.delivery_address?.[quoteDetail.delivery_address.length - 1]

  const orderDate = quoteDetail?.date
    ? format(new Date(quoteDetail.date), "MM.dd.yyyy")
    : ""

  const notes = quoteDetail?.notes || ""

  const status = quoteDetail?.status

  const companyName =
    deliveryAddress?.company_name && deliveryAddress?.company_name !== "blank"
      ? deliveryAddress?.company_name
      : ""

  const street = deliveryAddress?.street_address || ""

  const city = deliveryAddress?.city || ""

  const state = deliveryAddress?.state || ""

  const zipcode = deliveryAddress?.postcode || ""

  const country = deliveryAddress?.country || ""

  const deliveryTitle =
    quoteDetail?.delivery_title || quoteDetail?.delivery_type_title

  const address = [street, companyName, city, state, zipcode, country]
    .filter(Boolean)
    .join(", ")

  useAppLoading([isLoading])

  useEffect(() => {
    if (quoteDetail?.status === EOrderStatus.INITIAL)
      return navigate("/new-quote")
  }, [quoteDetail])

  useEffect(() => {
    if (quoteId) {
      getQuoteDetail({ quoteId })
    }
  }, [quoteId, getQuoteDetail])

  const getColorsStatus: Partial<Record<EOrderStatus, string>> = {
    [EOrderStatus.PLACED]: "status-text-success",
    [EOrderStatus.FIRM_OFFER_SENT]: "status-text-warning",
    [EOrderStatus.WAITING_FOR_REVIEW]: "status-text-warning"
  }

  const defaultColorStatus = "status-text-default"

  const getColorStatus =
    getColorsStatus[quoteDetail?.status as EOrderStatus] ?? defaultColorStatus

  const handleBack = () => {
    if (pathname.includes("quotes")) return navigate("/quotes")
    if (pathname.includes("orders")) return navigate("/orders")
    if (pathname.includes("checkout")) return navigate("/new-quote")
  }

  return (
    <RootContainer
      onBack={() => handleBack()}
      headerText="Details"
      subHeaderText={``}
    >
      <div className="order-detail-container">
        <div className="order-detail-left">
          <div className="order-detail-box">
            <div className="order-detail-box-header">
              <div className="img-box">
                <img src={orderSuccessIcon} alt="" />
              </div>
              <div className="text-box">
                <p>
                  Your order number{" "}
                  <span>#{quoteDetail?.company_order_id}</span> has been
                  successfully placed.
                </p>
                <p>
                  We are currently reviewing your order and will send you a
                  confirmation email shortly.
                </p>
              </div>
            </div>
            <div className="order-detail-info">
              <p className="order-detail-info-title">ORDER DETAILS</p>
              <div className="order-detail-info-column">
                <div className="column-1">
                  <p className="column-title">Order date</p>
                  <p>{orderDate}</p>
                </div>
                <div className="column-2">
                  <p className="column-title">Total</p>
                  <p>${quoteDetail?.total_price}</p>
                </div>
                <div className="column-3">
                  <p className="column-title">Tracking number</p>
                  <p>{trackingNumber}</p>
                </div>
                <div className="column-4">
                  <p className="column-title">Status</p>
                  <p className={getColorStatus}>
                    {quoteDetail?.status_display}
                  </p>
                </div>
              </div>
            </div>
            <div className="order-detail-product">
              {quoteDetail?.line_items?.map((product, index) => (
                <OrderDetailItem
                  item={product}
                  key={product.id}
                  isLastItem={
                    (status === "waiting_for_review" ||
                      status === "firm_offer_sent") &&
                    index === quoteDetail?.line_items.length - 1
                      ? true
                      : false
                  }
                ></OrderDetailItem>
              ))}
            </div>

            {status !== "waiting_for_review" &&
              status !== "firm_offer_sent" && (
                <>
                  <div className="order-detail-delivery">
                    <p className="order-detail-delivery-title">DELIVERY</p>
                    <div className="order-detail-delivery-text">
                      <div className="order-detail-delivery-text-type">
                        <p>Type</p>
                        <p>{deliveryTitle}</p>
                      </div>

                      {quoteDetail?.delivery_address?.length !== 0 ? (
                        <div className="order-detail-delivery-text-address">
                          <p>Address</p>
                          <p>{address}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {notes ? (
                    <div className="order-detail-delivery">
                      <div className="order-detail-delivery-text">
                        <div className="order-detail-delivery-text-notes">
                          <p>Notes</p>
                          <p>{notes}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="order-detail-right">
          <OrderDetailSummary></OrderDetailSummary>
        </div>
      </div>
    </RootContainer>
  )
}

export default OrderDetailPage
