import React from "react"
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd"
import "./Button.scss"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"

interface IButtonProps extends AntButtonProps {
  hierarchy?: ButtonHierarchy
  customType?: Type.PRIMARY | Type.NEUTRAL
  customSize?: Size
  customClassName?: string
}

const Button: React.FC<IButtonProps> = (props) => {
  const {
    hierarchy,
    customType,
    customSize,
    customClassName,
    disabled,
    children,
    ...rest
  } = props

  const buttonTypes = {
    [Type.PRIMARY]: "button-primary",
    [Type.NEUTRAL]: "button-neutral"
  }

  const buttonSizes = {
    [Size.EXTRA_SMALL]: "button-size-extra-small",
    [Size.SMALL]: "button-size-small",
    [Size.MEDIUM]: "button-size-medium",
    [Size.LARGE]: "button-size-large",
    [Size.EXTRA_LARGE]: "button-size-extra-large"
  }

  const buttonLines = {
    [ButtonHierarchy.FILLED]: "button-border-filled",
    [ButtonHierarchy.OUTLINE]: "button-border-outline",
    [ButtonHierarchy.TONAL]: "button-border-tonal",
    [ButtonHierarchy.LINK]: "button-border-link"
  }

  const buttonStyles = buttonTypes[customType ?? Type.PRIMARY]
  const buttonSize = buttonSizes[customSize ?? Size.MEDIUM]
  const buttonLine = buttonLines[hierarchy ?? ButtonHierarchy.FILLED]

  return (
    <AntButton
      {...rest}
      className={`${buttonStyles} ${buttonSize} ${buttonLine} ${customClassName} button`}
      disabled={disabled}
    >
      {children}
    </AntButton>
  )
}

export default Button
