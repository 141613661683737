import { PROFILE_TABS } from "@/constants/common.constant"
import { NavLink } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import "./ProfileNavigation.scss"
import { Upload } from "antd"
import { RcFile } from "antd/es/upload"
import { showError } from "@/utils/Toast"
import { avatarDefaultImage, UploadAvatarIcon } from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useUploadAvatarMutation } from "@/services/api/upload"
import { useLazyGetUserQuery } from "@/services/api/user"
import { useLazyGetMeQuery } from "@/services/apiDigifabster/user"

export default function ProfileNavigation(): JSX.Element {
  const { userInfo } = useSelector<RootState, IUserStore>((s) => s.user)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const [uploadAvatar] = useUploadAvatarMutation()
  const [getUser] = useLazyGetUserQuery()
  const [getMe] = useLazyGetMeQuery()

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
    if (!isJpgOrPng) {
      showError("Fail upload", "You can only upload JPG/PNG file!")
      return false
    }
    const fileData = new FormData()
    fileData.append("file", file, file.name)
    await uploadAvatar({ fileUploadDto: fileData as any })
    getMe()
    return false
  }

  return (
    <>
      <div className="content-sidebar">
        <div className="user-infor">
          <div className="upload-container">
            <img
              className="content-sidebar-avatar"
              src={userInfo?.avatar || avatarDefaultImage}
              width={108}
              height={108}
              alt="avatar"
              style={{ borderRadius: "50%" }}
            />
            {/* <div className="overlay"></div> */}
            {/* <div className="custom">
                <img src={UploadAvatarIcon} alt="" />
              </div> */}
          </div>
          {/* <Upload showUploadList={false} beforeUpload={beforeUpload}>
          </Upload> */}
          <p>{`${userInfoNew.name} ${userInfoNew.surname}`}</p>
        </div>
        <div className="content-sidebar-items">
          {PROFILE_TABS.map((tab, index) => (
            <NavLink
              to={tab.routerLink}
              key={index}
              className={({ isActive }) => {
                return isActive
                  ? `content-sidebar-item-active content-sidebar-item`
                  : "content-sidebar-item"
              }}
            >
              <p>{tab.name}</p>
              <RightOutlined height={20} color="var(--bg-gray-stronger)" />
            </NavLink>
          ))}
        </div>
      </div>
    </>
  )
}
