import "./CreateBillingPage.scss"
import ShippingContainer from "../../components/ShippingContainer/ShippingContainer"
import BillingForm from "../../components/BillingForm/BillingForm"
import { IBillingForm } from "@/interfaces/billing.interface"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { BILLING_SCHEMA } from "@/validations/billing.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useAddBillingAddressMutation,
  useLazyGetBillingAddressQuery
} from "@/services/api/user"
import { useAppLoading } from "@/hooks/useLoading"
import { useEffect } from "react"
import { showError, showSuccess } from "@/utils/Toast"
import { DEFAULT_FORM_VALUES } from "@/constants/shipping.constants"

export default function CreateBillingPage(): JSX.Element {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<IBillingForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(BILLING_SCHEMA)
  })
  const navigate = useNavigate()
  const [addBillAddress, { isLoading, isError, isSuccess }] =
    useAddBillingAddressMutation()
  const [getBillingAddress] = useLazyGetBillingAddressQuery()
  useAppLoading([isLoading])

  useEffect(() => {
    if (isError) {
      showError("Create billing address error", "")
    }

    if (isSuccess) {
      showSuccess("Create billing address success", "")
      getBillingAddress()
      navigate("/profile/billing-address")
    }
  }, [isError, isSuccess])

  const onSubmit = (data: IBillingForm) => {
    console.log("data", data)
    addBillAddress({ addUpdateBillingAddressDto: data })
  }

  const onCancel = () => {
    navigate("/profile/billing-address")
  }
  return (
    <ShippingContainer>
      <div className="billing-content">
        <div className="billing-address-header">
          <h3>Create Billing Address</h3>
        </div>
        <div className="">
          <BillingForm
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            setValue={setValue}
            watch={watch}
            onCancel={onCancel}
          />
        </div>
      </div>
    </ShippingContainer>
  )
}
