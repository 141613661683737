import React from "react"
import "./Drawer.scss"
import { Drawer as AntDrawer, Avatar, ConfigProvider, DrawerProps } from "antd"
import { DrawerClassNames } from "antd/es/drawer/DrawerPanel"

export interface ITtileDrawer {
  title: string
  subtitle?: string
  imageUrl?: string
}

interface IDrawerProps extends DrawerProps {
  children?: React.ReactNode
  customtitle?: ITtileDrawer
}
const Drawer: React.FC<IDrawerProps> = ({ title, ...props }) => {
  const classNames: DrawerClassNames = {
    body: "custom-drawer-body",
    mask: "custom-drawer-mask",
    header: "custom-drawer-header",
    footer: "custom-drawer-footer",
    content: "custom-drawer-content"
  }

  const generateTitle = () => (
    <>
      {title ? (
        title
      ) : (
        <div className="drawer-header">
          {props.customtitle?.imageUrl && (
            <Avatar src={props.customtitle.imageUrl} />
          )}
          <div>
            {props.customtitle?.title && (
              <h3 className="drawer-header-title">{props.customtitle.title}</h3>
            )}
            {props.customtitle?.subtitle && (
              <h5 className="drawer-header-subtitle">
                {props.customtitle.subtitle}
              </h5>
            )}
          </div>
        </div>
      )}
    </>
  )
  return (
    <AntDrawer classNames={classNames} title={generateTitle()} {...props}>
      {props.children}
    </AntDrawer>
  )
}

export default Drawer
