import {
  ActionReducerMapBuilder,
  createSlice,
  isAnyOf,
  PayloadAction
} from "@reduxjs/toolkit"
import {
  OrderDetailResponse,
  Product,
  QuoteResponse,
  quoteService
} from "@/services/apiDigifabster/quote"
import { modelService, ModelResponse } from "@/services/apiDigifabster/model"
import {
  IGetInvoiceResponse,
  orderService
} from "@/services/apiDigifabster/order"
import { UploadFile } from "antd"

export interface IQuoteStore {
  currentQuote?: QuoteResponse
  quoteDetail?: OrderDetailResponse
  selectedProduct?: Product
  currentPriceProduct?: {
    price_per_part: number
    total_price: number
  }
  currentModels: Record<number, ModelResponse>
  invoiceDetail: IGetInvoiceResponse
  isUploadingFile: boolean

  doneKeysCheckout: number[]
  newQuoteUploaded: UploadFile[]
}
const initialState: IQuoteStore = {
  currentQuote: {} as QuoteResponse,
  quoteDetail: {} as OrderDetailResponse,
  invoiceDetail: {} as IGetInvoiceResponse,
  currentModels: {},
  isUploadingFile: false,
  doneKeysCheckout: [1],
  newQuoteUploaded: []
}
const quoteSlice = createSlice({
  name: "quote",
  initialState: initialState,
  reducers: {
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploadingFile = action.payload
    },
    cleanState: (state) => {
      state = initialState
    },
    addQuoteData: (state, { payload }: PayloadAction<QuoteResponse>) => {
      state.currentQuote = { ...state.currentQuote, ...payload }
    },
    setQuoteData: (state, { payload }: PayloadAction<QuoteResponse>) => {
      state.currentQuote = payload
    },
    setEditProduct: (state, { payload }: PayloadAction<Product>) => {
      state.selectedProduct = payload
      state.currentPriceProduct = {
        price_per_part: payload.price_per_part,
        total_price: payload.total_price
      }
    },
    setQuoteShippingPrice: (state, { payload }: PayloadAction<number>) => {
      if (!state.currentQuote) return

      state.currentQuote.delivery_price = String(payload)
    },
    setDoneKeysCheckout: (state, { payload }: PayloadAction<number[]>) => {
      state.doneKeysCheckout = payload
    },
    updateStatusInvoice: (state, action: PayloadAction<string>) => {
      if (state.invoiceDetail && state.invoiceDetail.order) {
        state.invoiceDetail.order.status_display = action.payload
      }
    },
    setNewQuoteUploaded: (state, { payload }: PayloadAction<UploadFile[]>) => {
      state.newQuoteUploaded = payload
    }
  },
  extraReducers: (builders: ActionReducerMapBuilder<IQuoteStore>) => {
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getQuote.matchFulfilled),
      (state, { payload }) => {
        state.currentQuote = payload
      }
    )

    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getQuoteDetail.matchFulfilled),
      (state, { payload }) => {
        state.quoteDetail = payload
      }
    )
    builders.addMatcher(
      isAnyOf(
        quoteService.endpoints.purchaseProduct.matchFulfilled,
        quoteService.endpoints.updateProduct.matchFulfilled
      ),
      (state, { payload }) => {
        state.currentQuote = payload.order
      }
    )

    builders.addMatcher(
      isAnyOf(modelService.endpoints.getModelsDetail.matchFulfilled),
      (state, { payload }) => {
        payload.results.forEach((e) => {
          state.currentModels[e.id] = e
        })
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.deletePurchase.matchFulfilled),
      (state, { payload }) => {
        state.currentQuote = payload.order
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getPriceProduct.matchFulfilled),
      (state, { payload }) => {
        const { prices } = payload
        const pricePerItem =
          prices[0].priority_prices[0].price_info.price_per_item
        const total = prices[0].priority_prices[0].price_info.total
        state.currentPriceProduct = {
          price_per_part: pricePerItem,
          total_price: total
        }
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.uploadDrawings.matchFulfilled),
      (state, { payload, meta }) => {
        const { purchase_id } = meta.arg.originalArgs
        const product = state.currentQuote?.products?.find(
          (e) => e.id === purchase_id
        )
        const drawing_files = product?.drawing_files?.concat(payload)
        const newProduct = { ...product, drawing_files } as Product
        const newProducts = {
          ...state.currentQuote,
          products: state.currentQuote?.products.map((e) =>
            e.id === purchase_id ? newProduct : e
          )
        } as QuoteResponse
        state.currentQuote = newProducts
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.deleteDrawings.matchFulfilled),
      (state, { meta }) => {
        const { drawings_id, purchase_id } = meta.arg.originalArgs
        const product = state.currentQuote?.products.find(
          (e) => e.id === purchase_id
        )
        const drawing_files = product?.drawing_files.filter(
          (e: any) => e.id !== drawings_id
        )
        const newProduct = { ...product, drawing_files } as Product
        const newProducts = {
          ...state.currentQuote,
          products: state.currentQuote?.products.map((e) =>
            e.id === purchase_id ? newProduct : e
          )
        } as QuoteResponse
        state.currentQuote = newProducts
      }
    )
    builders.addMatcher(
      isAnyOf(
        orderService.endpoints.getInvoice.matchFulfilled,
        orderService.endpoints.updateInvoice.matchFulfilled
      ),
      (state, { payload }) => {
        state.invoiceDetail = payload
      }
    )
  }
})
export const {
  setQuoteData,
  addQuoteData,
  cleanState,
  setIsUploading,
  setEditProduct,
  updateStatusInvoice,
  setDoneKeysCheckout,
  setQuoteShippingPrice,
  setNewQuoteUploaded
} = quoteSlice.actions
export const quoteReducer = quoteSlice.reducer
