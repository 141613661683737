import { api } from "../apiInstance"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: () => ({ url: `/user/me` })
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/user/update`,
        method: "POST",
        body: queryArg.updateUserDto
      })
    }),
    updateUserPassword: build.mutation<
      UpdateUserPasswordApiResponse,
      UpdateUserPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/user/update-password`,
        method: "POST",
        body: queryArg.updateUserPasswordDto
      })
    }),
    getShippingAddresses: build.query<
      GetShippingAddressesApiResponse,
      GetShippingAddressesApiArg
    >({
      query: () => ({ url: `/user/shipping-address` })
    }),
    addShippingAddress: build.mutation<
      AddShippingAddressApiResponse,
      AddShippingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/user/shipping-address`,
        method: "POST",
        body: queryArg.addUpdateShippingAddressDto
      })
    }),
    getOneShippingAddress: build.query<
      GetOneShippingAddressApiResponse,
      GetOneShippingAddressApiArg
    >({
      query: (queryArg) => ({ url: `/user/shipping-address/${queryArg.id}` })
    }),
    updateShippingAddress: build.mutation<
      UpdateShippingAddressApiResponse,
      UpdateShippingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/user/shipping-address/${queryArg.id}`,
        method: "PUT",
        body: queryArg.addUpdateShippingAddressDto
      })
    }),
    deleteShippingAddress: build.mutation<
      DeleteShippingAddressApiResponse,
      DeleteShippingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/user/shipping-address/${queryArg.id}`,
        method: "DELETE"
      })
    }),
    getBillingAddress: build.query<
      GetBillingAddressApiResponse,
      GetBillingAddressApiArg
    >({
      query: () => ({ url: `/user/billing-address` })
    }),
    addBillingAddress: build.mutation<
      AddBillingAddressApiResponse,
      AddBillingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/user/billing-address`,
        method: "POST",
        body: queryArg.addUpdateBillingAddressDto
      })
    }),
    updateBillingAddress: build.mutation<
      UpdateBillingAddressApiResponse,
      UpdateBillingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/user/billing-address`,
        method: "PUT",
        body: queryArg.addUpdateBillingAddressDto
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as userService }
export type GetUserApiResponse = /** status 200  */ IResponse & {
  data: UserResponseDto
}
export type GetUserApiArg = void
export type UpdateUserApiResponse = /** status 200  */ IResponse & {
  data: UserResponseDto
}
export type UpdateUserApiArg = {
  updateUserDto: UpdateUserDto
}
export type UpdateUserPasswordApiResponse = /** status 200  */ IResponse & {
  data: boolean
}
export type UpdateUserPasswordApiArg = {
  updateUserPasswordDto: UpdateUserPasswordDto
}
export type GetShippingAddressesApiResponse = /** status 200  */ IResponse & {
  data: ShippingAddressResponseDto[]
}
export type GetShippingAddressesApiArg = void
export type AddShippingAddressApiResponse = /** status 200  */ IResponse & {
  data: string
}
export type AddShippingAddressApiArg = {
  addUpdateShippingAddressDto: AddUpdateShippingAddressDto
}
export type GetOneShippingAddressApiResponse = /** status 200  */ IResponse & {
  data: ShippingAddressResponseDto
}
export type GetOneShippingAddressApiArg = {
  id: string
}
export type UpdateShippingAddressApiResponse = /** status 200  */ IResponse & {
  data: ShippingAddressResponseDto
}
export type UpdateShippingAddressApiArg = {
  id: string
  addUpdateShippingAddressDto: AddUpdateShippingAddressDto
}
export type DeleteShippingAddressApiResponse = /** status 200  */ IResponse & {
  data: boolean
}
export type DeleteShippingAddressApiArg = {
  id: string
}
export type GetBillingAddressApiResponse = /** status 200  */ IResponse & {
  data: BillingAddressResponseDto
}
export type GetBillingAddressApiArg = void
export type AddBillingAddressApiResponse = /** status 200  */ IResponse & {
  data: string
}
export type AddBillingAddressApiArg = {
  addUpdateBillingAddressDto: AddUpdateBillingAddressDto
}
export type UpdateBillingAddressApiResponse = /** status 200  */ IResponse & {
  data: BillingAddressResponseDto
}
export type UpdateBillingAddressApiArg = {
  addUpdateBillingAddressDto: AddUpdateBillingAddressDto
}
export type IResponse = {
  /** Application code */
  code: Code
  /** Can be success or error message */
  message: string
  /** Is API success */
  success: boolean
  path?: string
  timestamp?: string
}
export type PhoneNumberDto = {
  /** Phone code of the user */
  countryCode?: string
  /** Phone code ID of the user */
  countryCodeId?: string
  /** Phone number with the code */
  phoneNum: string
}
export type StateAndCountryDto = {
  id: string | number
  iso2?: string
  name: string
}
export type UserResponseDto = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  firstName: string
  lastName: string
  email: string
  exId: string
  displayName?: string
  avatar?: string
  phone: string
  country?: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode?: string
  shippingMethod?: ShippingMethod
  FedExAcc?: string
  isApcceptPrivacyPolicy?: boolean
}
export type UpdateUserDto = {
  firstName: string
  lastName: string
  email: string
  password?: string
  displayName?: string
  avatar?: string
  phone: string
  country?: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode?: string
  shippingMethod?: ShippingMethod
  FedExAcc?: string
  isApcceptPrivacyPolicy?: boolean
}
export type UpdateUserPasswordDto = {
  currentPassword: string
  newPassword: string
}
export type ShippingAddressResponseDto = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  firstName: string
  lastName: string
  company?: string
  phone: PhoneNumberDto
  address: string
  addressSecondLine?: string
  userId: string
  email: string
  country: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode: string
  deliveryInstruction?: string
}
export type AddUpdateShippingAddressDto = {
  firstName: string
  lastName: string
  company?: string
  phone: PhoneNumberDto
  address: string
  addressSecondLine?: string
  email: string
  country: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode: string
  deliveryInstruction?: string
}
export type BillingAddressResponseDto = {
  _id?: string
  createdTime?: string
  updatedTime?: string
  lastUpdateDataTime?: string
  firstName: string
  lastName: string
  company?: string
  phone: PhoneNumberDto
  address: string
  addressSecondLine?: string
  userId: string
  email: string
  country: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode: string
  /** Tax Exemption Number */
  tax?: number
  /** Account payable email */
  payableEmail?: string
}
export type AddUpdateBillingAddressDto = {
  firstName: string
  lastName: string
  company?: string
  phone: PhoneNumberDto
  address: string
  addressSecondLine?: string
  email: string
  country: StateAndCountryDto
  state?: StateAndCountryDto
  city?: StateAndCountryDto
  zipCode: string
  /** Tax Exemption Number */
  tax?: number
  /** Account payable email */
  payableEmail?: string
}
export enum Code {
  Success = 1000,
  InternalServerError = 1001,
  InvalidUserToken = 1002,
  InvalidToken = 1003,
  Unauthorized = 1004,
  UserNotVerified = 2001,
  VerifyEmailError = 2002,
  ResetPasswordError = 2003,
  CredentialError = 2004,
  UserNotFound = 2005,
  SameEmailError = 2006,
  BillingAddAvailableError = 3001
}
export enum ShippingMethod {
  FedEx = "FedEx",
  Ups = "UPS",
  Purolator = "Purolator"
}
export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useGetShippingAddressesQuery,
  useLazyGetShippingAddressesQuery,
  useAddShippingAddressMutation,
  useGetOneShippingAddressQuery,
  useLazyGetOneShippingAddressQuery,
  useUpdateShippingAddressMutation,
  useDeleteShippingAddressMutation,
  useGetBillingAddressQuery,
  useLazyGetBillingAddressQuery,
  useAddBillingAddressMutation,
  useUpdateBillingAddressMutation
} = injectedRtkApi
