import { useEffect, useState } from "react"
import RootContainer from "@/components/RootContainer/RootContainer"
import QuotesListTable from "./components/QuotesListTable"
import { Pagination } from "@/components"
import { useNavigate } from "react-router-dom"
import { Quotes } from "@/interfaces/quotesListTable.interface"
import { useGetListQuoteQuery } from "@/services/apiDigifabster/quote"
import { useLoading } from "@/hooks/useLoading"
import "./QuotesPage.scss"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { priceDisplay } from "@/utils/functionHelper"

export default function QuotesPage(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(15)
  const [quoteData, setQuoteData] = useState<Quotes[]>([])
  const navigate = useNavigate()

  const { data, isError, refetch } = useGetListQuoteQuery({
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
    ordering: "-date",
    status__in: [EOrderStatus.WAITING_FOR_REVIEW, EOrderStatus.FIRM_OFFER_SENT],
    status__not__in: []
  })

  const { openLoading, closeLoading } = useLoading()

  useEffect(() => {
    const loadingQuotesList = async () => {
      openLoading()
      try {
        await refetch()
      } catch {
        closeLoading()
      } finally {
        closeLoading()
      }
    }
    loadingQuotesList()
  }, [currentPage, pageSize, refetch])

  useEffect(() => {
    if (data?.results) {
      const updatedData = data.results.map((quote, index) => ({
        no: quote.company_order_id,
        orderDate: quote.date,
        total: `$${priceDisplay(quote.total_price)}`,
        quoteStatus: quote.status_display,
        id: quote.id
      }))
      setQuoteData(updatedData)
    }
  }, [data, currentPage, pageSize])

  const handleSizeChange = (current: number, size: number) => {
    handleChange(1)
    setPageSize(size)
  }

  const onDetail = (id: number) => {
    navigate(`/quotes/${id}`)
  }

  const handleChange = (page: number) => {
    setCurrentPage(page)
  }

  if (isError) return <div>Error fetching quotes</div>

  return (
    <RootContainer headerText={`All Quotes (${data?.count || 0})`}>
      <div className="quote">
        <div className="quote-container">
          <QuotesListTable data={quoteData} onDetail={onDetail} />
          <div className="order-container-pagination">
            {!!data?.count && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data?.count || 0}
                onChange={handleChange}
                onShowSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
