import "./App.scss"
import { createBrowserRouter, redirect } from "react-router-dom"
import AuthLayout from "./layouts/AuthLayout/AuthLayout"
import MainLayout from "./layouts/MainLayout/MainLayout"
import NewQuotePage from "./pages/NewQuotePage/NewQuotePage"
import OrdersPage from "./pages/OrdersPage/OrdersPage"
import QuotesPage from "./pages/QuotesPage/QuotesPage"
import SignInPage from "./pages/SignInPage/SignInPage"
import RegisterPage from "./pages/RegisterPage/RegisterPage"
import { Suspense } from "react"
import ProfileLayout from "./layouts/ProfileLayout/ProfileLayout"
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage"
import ShippingPage from "./pages/ProfilePage/ShippingPage/ShippingPage"
import BillingPage from "./pages/ProfilePage/BillingPage/BillingPage"
import ProfilePage from "./pages/ProfilePage/InforPage/ProfilePage"
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage"
import SuccessResetPassword from "./pages/ResetPasswordPage/SuccessResetPassword/SuccessResetPassword"
import ForgotPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage"
import FormForgotPassword from "./pages/ResetPasswordPage/FormResetPassword/FormResetPassword"
import NotifyResetPassword from "./pages/ResetPasswordPage/NotifyResetPassword/NotifyResetPassword"
import EditShippingPage from "./pages/ProfilePage/ShippingPage/EditShippingPage/EditShippingPage"
import CreateShippingPage from "./pages/ProfilePage/ShippingPage/CreateShippingPage/CreateShippingPage"
import EditBillingPage from "./pages/ProfilePage/BillingPage/EditBillingPage/EditBillingPage"
import EmailResetPassword from "./pages/ResetPasswordPage/EmailResetPassword/EmailResetPassword"
import RegisterSuccessPage from "./pages/RegisterPage/RegisterSuccessPage/RegisterSuccessPage"
import RootLayout from "./layouts/RootLayout/RootLayout"
import EmailVerifiedPage from "./pages/RegisterPage/EmailVerifiedPage/EmailVerifiedPage"
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage"
import CreateBillingPage from "./pages/ProfilePage/BillingPage/CreateBillingPage/CreateBillingPage"
import SpecificationPage from "./pages/SpecificationPage/SpecificationPage"
import SaveQuoteSuccessPage from "./pages/SaveQuoteSuccessPage/SaveQuoteSuccessPage"
import OrderDetailPage from "./pages/OrderDetailPage/OrderDetailPage"
import InvoicePage from "./pages/InvoicePage/InvoicePage"

const redirectToLogin = () => {
  return redirect("/auth/sign-in")
}

const redirectToNewQuote = () => {
  return redirect("/new-quote")
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/auth",
        element: <AuthLayout />,
        children: [
          {
            path: "/auth/",
            loader: () => redirectToLogin()
          },
          {
            path: "/auth/sign-in",
            element: <SignInPage />
          },
          {
            path: "/auth/register",
            element: <RegisterPage />,
            children: [
              {
                path: "/auth/register/success",
                element: <RegisterSuccessPage />
              }
            ]
          },
          {
            path: "/auth/forgot-password",
            element: <ForgotPasswordPage />,
            children: [
              {
                path: "/auth/forgot-password/",
                element: <EmailResetPassword />
              },
              {
                path: "/auth/forgot-password/notify",
                element: <NotifyResetPassword />
              },
              {
                path: "/auth/forgot-password/form*",
                element: <FormForgotPassword />
              },
              {
                path: "/auth/forgot-password/success",
                element: <SuccessResetPassword />
              }
            ]
          }
        ]
      },
      {
        path: "/auth/email-verified*",
        element: <EmailVerifiedPage />
      },
      {
        path: "/",
        element: (
          <Suspense>
            <MainLayout />
          </Suspense>
        ),
        children: [
          {
            path: "/",
            loader: () => redirectToNewQuote()
          },
          {
            path: "/new-quote",
            element: <NewQuotePage />
          },
          {
            path: "/quotes",
            element: <QuotesPage />
          },
          {
            path: "/orders",
            element: <OrdersPage />
          },
          {
            path: "/orders/:quoteId",
            element: <OrderDetailPage />
          },
          {
            path: "/quotes/:quoteId",
            element: <OrderDetailPage />
          },
          {
            path: "/contact-us",
            element: <ContactUsPage />
          },
          {
            path: "/new-quote/specification/:quoteId",
            element: <SpecificationPage />
          },
          {
            path: "/new-quote/save-quote-success",
            element: <SaveQuoteSuccessPage />
          },
          {
            path: "/new-quote/checkout/:quoteId",
            element: <CheckoutPage />
          },
          {
            path: "/new-quote/checkout/:quoteId/submitted",
            element: <OrderDetailPage />
          },
          {
            path: "/new-quote/checkout/:quoteId/submitted/invoices/:invoiceId/:invoice_hash",
            element: <InvoicePage />
          },
          {
            path: "/orders/invoices/:invoiceId/:invoice_hash",
            element: <InvoicePage />
          },
          {
            path: "/quotes/invoices/:invoiceId/:invoice_hash",
            element: <InvoicePage />
          }
        ]
      },
      {
        path: "/profile",
        element: (
          <Suspense>
            <ProfileLayout />
          </Suspense>
        ),
        children: [
          {
            path: "/profile/infor",
            element: <ProfilePage />
          },
          {
            path: "/profile/shipping-address",
            children: [
              {
                path: "/profile/shipping-address",
                element: <ShippingPage />
              },
              {
                path: "/profile/shipping-address/create",
                element: <CreateShippingPage />
              },
              {
                path: "/profile/shipping-address/:id",
                element: <EditShippingPage />
              }
            ]
          },
          {
            path: "/profile/billing-address",
            children: [
              {
                path: "/profile/billing-address",
                element: <BillingPage />
              },
              {
                path: "/profile/billing-address/edit",
                element: <EditBillingPage />
              },
              {
                path: "/profile/billing-address/create",
                element: <CreateBillingPage />
              }
            ]
          }
        ]
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  }
])
