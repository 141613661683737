import { TextField, FormField, Button, InputView } from "@/components"
import { Row, Col, Form, ConfigProvider } from "antd"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { useForm, Controller, FieldError } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { infoSchema } from "@/validations/profile.validation"
import { showError, showSuccess } from "@/utils/Toast"
import "./InfoForm.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useAppLoading } from "@/hooks/useLoading"
import { useEffect } from "react"
import { useUpdateUserInfoMutation } from "@/services/apiDigifabster/user"

interface IInfoFormProps {
  setEdit: (isEdit: boolean) => void
  isEdit: boolean
}

interface IButtonSaveAndCancel {
  isEdit: boolean
  onCancel: () => void
  onSave: () => void
}

const ButtonSaveAndCancel = ({
  isEdit,
  onCancel,
  onSave
}: IButtonSaveAndCancel) => {
  return (
    <>
      {isEdit && (
        <Row justify="end" gutter={14}>
          <Col>
            <Button
              onClick={onCancel}
              customType={Type.NEUTRAL}
              hierarchy={ButtonHierarchy.LINK}
              customSize={Size.MEDIUM}
              customClassName="btn-save"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              customType={Type.PRIMARY}
              onClick={onSave}
              customSize={Size.MEDIUM}
              customClassName="btn-save"
            >
              Save
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

interface IInfoFormInput {
  firstName: string
  lastName: string
  email: string
}

function InfoForm({ setEdit, isEdit }: IInfoFormProps) {
  const { userInfo } = useSelector<RootState, IUserStore>((s) => s.user)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const errorStatus = (errors?: FieldError) => (errors ? "error" : "")
  const [updateUserInfo, { isLoading, isSuccess, isError }] =
    useUpdateUserInfoMutation()
  const userDefaultData = {
    firstName: userInfoNew.name,
    lastName: userInfoNew.surname,
    email: userInfoNew.email
  }

  useAppLoading([isLoading])

  useEffect(() => {
    if (isSuccess) {
      setEdit(false)
      showSuccess("Personal Infomation updated successfully", "")
    }

    if (isError) {
      showError("Personal Infomation updated unsuccessfully", "")
    }
  }, [isSuccess, isError])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm<IInfoFormInput>({
    defaultValues: userDefaultData,
    resolver: yupResolver(infoSchema)
  })

  const { firstName, lastName, email } = getValues()

  const changeEditStatus = () => {
    setEdit(true)
    reset(userDefaultData)
  }

  const handleCancel = () => {
    setEdit(false)
    reset()
  }

  const handleSave = async (data: IInfoFormInput) => {
    const body = {
      name: data.firstName,
      surname: data.lastName
    }
    updateUserInfo(body)
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `"Open Sans", sans-serif`
        }
      }}
    >
      <Form onFinish={handleSubmit(handleSave)} className="info-font-form">
        <Row>
          <Col span={12}>
            <FormField
              textLabel="First name"
              errorText={errors.firstName?.message}
            >
              {isEdit ? (
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.firstName)}
                      {...field}
                    />
                  )}
                />
              ) : (
                <InputView>{firstName}</InputView>
              )}
            </FormField>
          </Col>
          <Col span={12} style={{ paddingLeft: "10px" }}>
            <FormField
              textLabel="Last name"
              errorText={errors.lastName?.message}
            >
              {isEdit ? (
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.lastName)}
                      {...field}
                    />
                  )}
                />
              ) : (
                <InputView>{lastName}</InputView>
              )}
            </FormField>
          </Col>
        </Row>
        <Row style={{ paddingTop: "14px" }}>
          <Col span={12}>
            <FormField
              textLabel="Email Address"
              errorText={errors.email?.message}
            >
              <InputView>{email}</InputView>
            </FormField>
          </Col>
        </Row>
        {isEdit ? (
          <ButtonSaveAndCancel
            onSave={handleSubmit(handleSave)}
            isEdit={isEdit}
            onCancel={handleCancel}
          />
        ) : (
          <Row justify="end">
            <Col>
              <Button
                onClick={changeEditStatus}
                customType={Type.PRIMARY}
                hierarchy={ButtonHierarchy.OUTLINE}
                customSize={Size.MEDIUM}
                customClassName="btn-edit"
              >
                Edit
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </ConfigProvider>
  )
}

export default InfoForm
