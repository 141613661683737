import "./EditShippingPage.scss"
import { useForm } from "react-hook-form"
import { IShippingForm } from "@/interfaces/shipping.interface"
import { yupResolver } from "@hookform/resolvers/yup"
import { SHIPPING_SCHEMA } from "@/validations/shipping.validation"
import { useNavigate, useParams } from "react-router-dom"
import ShippingContainer from "../../components/ShippingContainer/ShippingContainer"
import ShippingForm from "../../components/ShippingForm/ShippingForm"
import { useEffect } from "react"
import {
  useGetOneShippingAddressQuery,
  useLazyGetOneShippingAddressQuery,
  useLazyGetShippingAddressesQuery,
  useUpdateShippingAddressMutation
} from "@/services/api/user"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useAppLoading } from "@/hooks/useLoading"
import { showError, showSuccess } from "@/utils/Toast"
import { DEFAULT_FORM_VALUES } from "@/constants/shipping.constants"

export default function EditShippingPage(): JSX.Element {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm<IShippingForm>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(SHIPPING_SCHEMA)
  })

  let { id } = useParams()
  const [getShippingAddressDetail, { isLoading: isDetailLoading }] =
    useLazyGetOneShippingAddressQuery()

  const [updateShippingAddress, { isLoading, isError, isSuccess }] =
    useUpdateShippingAddressMutation()
  useAppLoading([isLoading, isDetailLoading])
  const [getShippingAddresses] = useLazyGetShippingAddressesQuery()

  useEffect(() => {
    if (isError) {
      showError("Cannot udpate shipping address", "")
    }

    if (isSuccess) {
      showSuccess("Update shipping address successfully")
      getShippingAddresses()
      navigate("/profile/shipping-address")
    }
  }, [isError, isSuccess])

  const { shippingDetail } = useSelector<RootState, IUserStore>((s) => s.user)
  useEffect(() => {
    if (!shippingDetail) return

    reset(shippingDetail)
  }, [shippingDetail])

  useEffect(() => {
    if (!id) {
      navigate("/profile/shipping-address")
      return
    }

    getShippingAddressDetail({ id })
  }, [id])

  const navigate = useNavigate()

  const onSubmit = (data: IShippingForm) => {
    if (!shippingDetail || !shippingDetail._id) return

    updateShippingAddress({
      id: shippingDetail._id,
      addUpdateShippingAddressDto: data
    })
  }

  const onCancel = () => {
    navigate("/profile/shipping-address")
  }

  return (
    <ShippingContainer>
      <div className="shipping-content">
        <div className="shipping-address-header">
          <h3>Edit Shipping Address</h3>
        </div>
        <div className="">
          <ShippingForm
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            control={control}
            getValues={getValues}
            setValue={setValue}
            onCancel={onCancel}
            watch={watch}
          />
        </div>
      </div>
    </ShippingContainer>
  )
}
