import CustomUpload from "@/components/common/atom/Upload/Upload"
import { UploadFile } from "antd"
import "./UploadCADFile.scss"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { setIsUploading, IQuoteStore, setNewQuoteUploaded } from "@/store/quote"
import { RootState } from "@/store"
import { debounce } from "@/utils/functionHelper"
import { IProductStore } from "@/store/product"
import { useLazyTechnologyListQuery } from "@/services/apiDigifabster/technology"
import { useAppLoading } from "@/hooks/useLoading"
import { ModalSelectUnit } from "@/components"
import { EUnit } from "@/enums/unit.enum"
import { useUploadModels } from "@/hooks/useUploadModels"
import * as toast from "@/utils/Toast"

const UploadCADFile: React.FC = () => {
  const { currentQuote, newQuoteUploaded } = useSelector<
    RootState,
    IQuoteStore
  >((s) => s.quote)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const [open, setOpen] = useState(false)
  const [selectUnit, setSelectUnit] = useState<EUnit>(EUnit.MM)
  const filesRef = useRef<UploadFile[]>([])
  const dispatch = useDispatch()

  const [getTechnologies, { isLoading: getTechnologiesLoading }] =
    useLazyTechnologyListQuery()
  const { uploadModels } = useUploadModels()

  useAppLoading([getTechnologiesLoading])

  useEffect(() => {
    if (technologies.length) return

    getTechnologies({ page: 1 })
  }, [technologies])

  useEffect(() => {
    if (!currentQuote?.id || !newQuoteUploaded.length) return

    const uploadedFiles = newQuoteUploaded.filter(
      (f) => f.response?.orderId === currentQuote?.id
    )
    if (!uploadedFiles.length) return

    setFileList(uploadedFiles)
    // TODO: handle upload
    uploadModels(uploadedFiles, "", {
      orderId: currentQuote.id,
      uploadJobId: currentQuote.upload_job,
      onlyPurchase: true
    })
      .then(({ error, files: uploadedFiles }) => {
        console.log("error", error)
        if (error) {
          toast.showError(error)
        }
        console.log("uploadedFiles", uploadedFiles)
        setFileList((pre) => {
          return pre
            .map<UploadFile>((e) => uploadedFiles[e.uid] || e)
            .filter((e) => e.status !== "done")
        })
      })
      .finally(() => {
        dispatch(setNewQuoteUploaded([]))
      })
  }, [newQuoteUploaded, currentQuote])

  const handleSubmitFile = async (files: UploadFile[]) => {
    filesRef.current = files
    setOpen(true)
  }

  const debounceMultipleUpload = debounce((uploadFiles: UploadFile[]) => {
    const toBeUpload = uploadFiles.filter((e) => !e.status)

    const uploadingFiles = toBeUpload.map((e) => {
      return {
        ...e,
        status: "uploading"
      }
    }) as UploadFile[]
    setFileList((pre) => [...pre, ...uploadingFiles])

    handleSubmitFile(toBeUpload)
  }, 500)

  const handleUploadFiles = (info: {
    file: UploadFile
    fileList: UploadFile[]
  }) => {
    debounceMultipleUpload(info.fileList)
  }

  const handleRemoveFile = async (file: UploadFile) => {
    setFileList((pre) => pre.filter((e) => e.uid !== file.uid))
  }

  const handleSelectedUnit = async () => {
    setOpen(false)
    dispatch(setIsUploading(true))
    const files = filesRef.current
    try {
      const uploadJobId = currentQuote?.upload_job
      const orderId = currentQuote?.id
      if (!uploadJobId || !orderId) throw new Error()

      const { files: uploadedFiles, error } = await uploadModels(
        files,
        selectUnit,
        {
          uploadJobId,
          orderId,
          uploadInterupted(failedFile) {
            setFileList((pre) => {
              return pre.map<UploadFile>(
                (e) => failedFile.find((f) => f.uid === e.uid) || e
              )
            })
          }
        }
      )
      if (error) {
        toast.showError(error)
      }
      setFileList((pre) => {
        return pre
          .map<UploadFile>((e) => uploadedFiles[e.uid] || e)
          .filter((e) => e.status !== "done")
      })
    } catch (err: any) {
      toast.showError(err.message || err)
    } finally {
      dispatch(setIsUploading(false))
    }
  }

  const handleCancel = () => {
    setOpen(false)
    filesRef.current.forEach((file) => handleRemoveFile(file))
  }

  return (
    <>
      <div className="upload-cad-file">
        <div className="content-box">
          <p className="title">Upload CAD file</p>
          <CustomUpload
            isShowUploadList={true}
            uploadHint="File types: STL, OBJ, WRL, STEP, STP,  IGES, IGS, 3MF, DXF, DWG, ZIP"
            files={fileList}
            onUploadFiles={handleUploadFiles}
            onRemoveFile={handleRemoveFile}
          />
        </div>
      </div>
      <ModalSelectUnit
        openModal={open}
        closeModal={handleCancel}
        onChangeUnit={(id) => setSelectUnit(id)}
        unit={selectUnit}
        onSelectedUnit={handleSelectedUnit}
      ></ModalSelectUnit>
    </>
  )
}

export default UploadCADFile
