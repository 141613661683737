import { SelectProps } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { useEffect } from "react"
import SelectDropdown from "../SelectDropdown/SelectDropdown"
import { CountryOptions } from "@/interfaces/common.interface"
import { getCountriesList } from "@/constants/countries"

interface ISelectProps extends SelectProps {
  handleChange: (
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => void
  flag?: string
  setCountriesList: React.Dispatch<React.SetStateAction<CountryOptions[]>>
  countriesList: CountryOptions[]
  width?: number | string
  dropdownClassName?: string
  acceptCountries?: string[]
}

const CountrySelect: React.FC<ISelectProps> = ({
  flag,
  handleChange,
  setCountriesList,
  countriesList,
  width,
  dropdownClassName,
  acceptCountries = [],
  ...props
}) => {
  useEffect(() => {
    getCountries()
  }, [])

  const getCountries = async () => {
    const countries = await getCountriesList()

    let limitCountries = countries

    if (acceptCountries.length > 0) {
      limitCountries = countries.filter((country) =>
        acceptCountries.includes(country.iso2 || "")
      )
    }

    const mapData =
      limitCountries.map((country) => ({
        ...country,
        prefix: (
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${country?.iso2?.toLowerCase()}.png`}
            alt=""
          />
        )
      })) || []
    setCountriesList(mapData)
  }

  return (
    <SelectDropdown
      {...props}
      width={width}
      placeholderSearch="Search country"
      onChange={handleChange}
      options={countriesList}
      virtual={false}
      searchBar={true}
      dropdownClassName={dropdownClassName}
      prefixIcon={<img loading="lazy" src={flag} alt="" />}
    ></SelectDropdown>
  )
}

export default CountrySelect
