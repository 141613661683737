import ContactCard from "./components/ContactCard/ContactCard"
import RootContainer from "@/components/RootContainer/RootContainer"

export default function ContactUsPage(): JSX.Element {
  const data = {
    phone: "1-877-329-9131",
    email: "sales@forgelabs.com",
    sessionId: "48654"
  }
  return (
    <RootContainer headerText="Contact">
      <ContactCard data={data} />
    </RootContainer>
  )
}
