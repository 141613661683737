import React, { useEffect } from "react"
import "./AuthLayout.scss"
import { Outlet, useNavigate } from "react-router-dom"
import {
  LogoWithText,
  SponsorLogo1,
  SponsorLogo2,
  SponsorLogo3,
  SponsorLogo4
} from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IAuthStore } from "@/store/auth"
import { getPresistStorage } from "@/utils/storageHelper"

const AuthLayout: React.FC = () => {
  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenciated) return

    navigate("/new-quote", { replace: true })
  }, [])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      const persistStorageObj = getPresistStorage(event)
      if (!persistStorageObj) return

      const accessToken = persistStorageObj["accessToken"]
      if (accessToken) {
        window.location.href = "/new-quote"
      }
    }

    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  return (
    <>
      <div className="auth-layout flex h-screen bg-no-repeat">
        <div className="auth-layout-sidebar-wrapper">
          <div className="auth-layout-sidebar-top">
            <img src={LogoWithText} alt="logo" />
          </div>

          <div className="auth-layout-sidebar-bottom">
            <p className="auth-layout-sidebar-bottom-text">Trusted by</p>
            <div className="auth-layout-sidebar-bottom-logos">
              <img src={SponsorLogo1} alt="" />
              <img src={SponsorLogo2} alt="" />
              <img src={SponsorLogo3} alt="" />
              <img src={SponsorLogo4} alt="" />
            </div>
          </div>
        </div>
        <main className="auth-layout-content overflow-auto h-full w-full">
          <Outlet />
        </main>
      </div>
    </>
  )
}

export default AuthLayout
