import { FedExLogo, PurolatorLogo, UpsLogo } from "@/assets"

export const mockMethod = [
  {
    img: PurolatorLogo,
    name: "Purolator",
    time: "Next Day Express",
    price: 9.95
  },
  {
    img: UpsLogo,
    name: "UPS",
    time: "Next Day Air",
    price: 26.26
  },
  {
    img: FedExLogo,
    name: "FedEx",
    time: "Standard Overnight",
    price: 50.11
  }
]
