import { Navigate, Outlet, useNavigate } from "react-router-dom"
import "./MainLayout.scss"
import SideBar from "@/components/common/SideBar/SideBar"
import { TABS } from "@/constants/common.constant"
import ProtectedRoute from "@/components/common/ProtectedRoute/ProtectedRoot"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IAuthStore } from "@/store/auth"
import { useEffect } from "react"
import { getPresistStorage } from "@/utils/storageHelper"

const MainLayout: React.FC = () => {
  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenciated) return

    navigate("/auth/sign-in", { replace: true })
  }, [isAuthenciated])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      const persistStorageObj = getPresistStorage(event)
      if (!persistStorageObj) return

      const accessToken = persistStorageObj["accessToken"]
      if (!accessToken) {
        window.location.href = "/auth/sign-in"
      }
    }

    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  if (!isAuthenciated) return <></>

  return (
    <ProtectedRoute>
      <div className="layout flex h-screen bg-no-repeat">
        <SideBar tabs={TABS} />
        <main className="layout-content overflow-auto h-full w-full">
          <Outlet />
        </main>
      </div>
    </ProtectedRoute>
  )
}

export default MainLayout
