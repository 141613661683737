import { Button, FormField, RadioButton, TextField } from "@/components"
import { Radio, RadioChangeEvent, Upload, UploadFile, UploadProps } from "antd"
import React, { useEffect, useState } from "react"
import "./Payment.scss"
import { EPaymentMethod } from "@/enums/checkout.enum"
import { Controller, useForm } from "react-hook-form"
import { PURCHASE_SCHEMA } from "@/validations/credit.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  ICheckoutForm,
  IPaymentForm,
  IPurchaseForm
} from "@/interfaces/checkout.interface"
import { errorStatus } from "@/constants/common.constant"
import { IBillingFormValues } from "@/interfaces/billing.interface"
import BillingInfoDetail from "./components/BillingInfoDetail/BillingInfoDetail"
import BillingInfoEditForm from "./components/BillingInfoEditForm/BillingInfoEditForm"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { uploadPDFIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import { UploadChangeParam } from "antd/es/upload"
interface IPaymentProps {
  onSubmit: (data: IPaymentForm) => void
  prefillData?: ICheckoutForm
  fileList: UploadFile[]
  handleChange: (info: UploadChangeParam<UploadFile<any>>) => void
  handleRemove: (file: UploadFile) => void
  handleChangePaymentMethod: (e: RadioChangeEvent) => void
  handleChangePONumber: (value?: string) => void
  value: string
}
const Payment: React.FC<IPaymentProps> = ({
  onSubmit,
  prefillData,
  handleChange,
  handleRemove,
  handleChangePaymentMethod,
  handleChangePONumber,
  value,
  fileList
}) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useForm<IPurchaseForm>({
    defaultValues: {
      poNumber: prefillData?.po_number
    },
    resolver: yupResolver(PURCHASE_SCHEMA)
  })
  const [isEdit, setIsEdit] = useState<boolean>(true)

  const onSubmitDataBilling = (data: IBillingFormValues) => {
    setIsEdit(false)
    const mappedData: IPaymentForm = {
      billing: data,
      poNumber: watch("poNumber"),
      poFile: fileList[0]
    }
    onSubmit(mappedData)
  }

  useEffect(() => {
    handleChangePONumber(watch("poNumber"))
  }, [watch("poNumber")])

  const handleEdit = () => {
    setIsEdit(true)
  }

  const getBorder = (value: string) => {
    if (value !== EPaymentMethod.CREDIT_CARD) return "border-payment"
  }

  const customItemRender = (file: UploadFile) => {
    return (
      <div key={file.uid} className="item-render">
        <p>{file.name}</p>
        <div className="icon-box">
          <CloseOutlined
            onClick={() => handleRemove(file)}
            className="icon-box-render"
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={getBorder(value)}>
        <p className="payment-method-title">PAYMENT METHOD</p>
        <div className="payment-method">
          <div className="payment-method-left">
            <Radio.Group value={value} onChange={handleChangePaymentMethod}>
              <div className="payment-method-left-radio">
                <div>
                  <RadioButton value={EPaymentMethod.CREDIT_CARD}>
                    Credit Card
                  </RadioButton>
                </div>
                <div>
                  <RadioButton value={EPaymentMethod.PURCHASE_ORDER}>
                    Purchase Order
                  </RadioButton>
                </div>
              </div>
            </Radio.Group>
          </div>
          <div className="payment-method-right">
            {value === EPaymentMethod.CREDIT_CARD ? (
              <></>
            ) : (
              <>
                <FormField
                  textLabel="Purchase Order"
                  errorText={errors.poNumber?.message}
                >
                  <Controller
                    control={control}
                    name="poNumber"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        placeholder="PO Number"
                        status={errorStatus(errors.poNumber)}
                      ></TextField>
                    )}
                  ></Controller>
                </FormField>
                <div style={{ paddingTop: "8px" }}>
                  {fileList?.length > 0 ? (
                    <>{customItemRender(fileList[0])}</>
                  ) : (
                    <>
                      <Upload
                        accept="*/*"
                        fileList={fileList}
                        onChange={handleChange}
                        showUploadList={false}
                      >
                        <Button
                          customSize={Size.SMALL}
                          hierarchy={ButtonHierarchy.LINK}
                          customClassName="upload-btn"
                        >
                          <img src={uploadPDFIcon} alt="" />
                          <p className="title-upload">Upload PDF</p>
                        </Button>
                      </Upload>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {value !== EPaymentMethod.CREDIT_CARD && (
        <>
          <div className="billing-info">
            <div className="billing-info-box">
              {isEdit ? (
                <BillingInfoEditForm
                  onSubmit={onSubmitDataBilling}
                  prefillData={prefillData}
                />
              ) : (
                <BillingInfoDetail
                  onEdit={handleEdit}
                  prefillData={prefillData?.billing}
                ></BillingInfoDetail>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Payment
