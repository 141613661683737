import { NotFoundPageImage } from "@/assets"
import { Button } from "@/components"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import { ArrowLeftOutlined } from "@ant-design/icons"
import "./NotFoundPage.scss"

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <img src={NotFoundPageImage} alt="" />
      <div className="not-found-content">
        <h2>404 Error</h2>
        <br />
        <p className="message-error">
          Oh no, you’ve found our junior developer’s homepage!
        </p>
        <Button
          customType={Type.NEUTRAL}
          hierarchy={ButtonHierarchy.TONAL}
          onClick={() => window.location.replace("/")}
        >
          <ArrowLeftOutlined />
          Back to ForgeLabs
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
