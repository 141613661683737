export enum EService {
  CAD = "1",
  SHORT = "2",
  ASSEMBLY = "3",
  ART = "4",
  SCALE = "5"
}

export enum ETimeline {
  LESS_ONE = "1",
  ONE_TWO = "2",
  TWO_FOUR = "3",
  MORE_FOUR = "4"
}

export enum EHaveCADFile {
  YES = "1",
  NO = "2"
}

export enum EBudget {
  LESS_FIVE = "1",
  FIVE_TEN = "2",
  TEN_TWENTY = "3",
  TWENTY_FIFTY = "4",
  FIFTY_HUNDRED = "5",
  MORE_HUNDRED = "6"
}

export enum EDrawings {
  ACCEPT_NOT_REQUIRE = "accept_not_require",
  NOT_ACCEPT = "not_accept"
}
