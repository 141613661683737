import React, { useEffect, useState } from "react"
import "./DeliveryAddress.scss"
import {
  Button,
  CountrySelect,
  FormField,
  SelectDropdown,
  TextField
} from "@/components"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import { PlusOutlined } from "@ant-design/icons"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import { getStatesList } from "@/constants/countries"
import { DEFAULT_COUNTRY_ID } from "@/constants/common.constant"
import { CountryOptions, IState } from "@/interfaces/common.interface"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { DELIVERY_SCHEMA } from "@/validations/delivery.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { ConfigProvider, Row, Col, Form } from "antd"
import { FieldError, useForm, Controller } from "react-hook-form"
import { useSelector } from "react-redux"

interface IDeliveryAddressProps {
  onSubmit: (data: IDeliveryForm) => void
  prefillData?: IDeliveryForm
  isEdit: boolean
  handleEdit: () => void
}
const DeliveryAddress: React.FC<IDeliveryAddressProps> = ({
  onSubmit,
  prefillData,
  isEdit,
  handleEdit
}) => {
  const errorStatus = (errors?: FieldError) => (errors ? "error" : "")
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [stateList, setStateList] = useState<CountryOptions[]>([])
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const acceptCountries = ["CA", "US"]

  const { name, surname, email, phone_number, company_name } = userInfoNew

  const defaultDeliveryFormValues: IDeliveryForm = {
    name: prefillData?.name || name,
    surname: prefillData?.surname || surname,
    phoneNumber: prefillData?.phoneNumber || phone_number,
    email: prefillData?.email || email,
    additionalEmail: prefillData?.additionalEmail || "",
    city: prefillData?.city || "",
    state: prefillData?.state || ({} as IState),
    addressLine1: prefillData?.addressLine1 || "",
    addressLine2: prefillData?.addressLine2 || "",
    zipCode: prefillData?.zipCode || "",
    country: prefillData?.country || {
      id: DEFAULT_COUNTRY_ID,
      name: "Canada",
      iso2: "CA"
    },
    company: prefillData?.company || company_name
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<IDeliveryForm>({
    defaultValues: defaultDeliveryFormValues,
    resolver: yupResolver(DELIVERY_SCHEMA)
  })

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || {})
  }

  return (
    <div>
      {!isEdit ? (
        <div>
          <div className="info-box">
            <div className="contact">
              <p>CONTACT INFORMATION</p>
              <p>
                {prefillData?.name || ""}, {prefillData?.surname || ""}
              </p>
              <p>
                {prefillData?.phoneNumber || ""}, {prefillData?.email || ""}
              </p>
              <p>{prefillData?.additionalEmail || ""}</p>
            </div>
            <div className="address">
              <p>DELIVERY ADDRESS</p>
              <p>{prefillData?.company || ""}</p>
              <p>
                {prefillData?.addressLine1}
                {`${
                  prefillData?.addressLine2
                    ? `, ${prefillData?.addressLine2}`
                    : ""
                }`}
              </p>
              <p>
                {prefillData?.city || ""}, {prefillData?.state.name || ""}
              </p>
              <p>
                {prefillData?.country.name || ""}, {prefillData?.zipCode || ""}
              </p>
            </div>
          </div>
          <Button
            hierarchy={ButtonHierarchy.TONAL}
            customSize={Size.SMALL}
            onClick={handleEdit}
          >
            <PlusOutlined />
            Edit Delivery Address
          </Button>
        </div>
      ) : (
        <>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: '"Open Sans", sans-serif'
              }
            }}
          >
            <Form onFinish={handleSubmit(onSubmit)}>
              <p className="title">CONTACT</p>
              <Row gutter={12} className="spacing-12">
                <Col span={12}>
                  <FormField
                    textLabel="First Name"
                    errorText={errors.name?.message}
                  >
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.name)}
                          placeholder="First Name"
                        />
                      )}
                    />
                  </FormField>
                </Col>
                <Col span={12}>
                  <FormField
                    textLabel="Last Name"
                    errorText={errors.surname?.message}
                  >
                    <Controller
                      name="surname"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.surname)}
                          placeholder="Surname"
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row gutter={12} className="spacing-12">
                <Col span={12}>
                  <FormField
                    textLabel="Phone Number"
                    errorText={errors.phoneNumber?.message}
                  >
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.phoneNumber)}
                          placeholder="12312312312"
                        />
                      )}
                    />
                  </FormField>
                </Col>
                <Col span={12}>
                  <FormField
                    textLabel="Email Address"
                    errorText={errors.email?.message}
                  >
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.email)}
                          placeholder="mail@gmail.com"
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row className="spacing-12">
                <Col span={24}>
                  <FormField textLabel="Additional emails for order notifications (optional)">
                    <Controller
                      name="additionalEmail"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} placeholder="mail@gmail.com" />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <p className="title pt-12">DELIVERY ADDRESS</p>
              <Row className="spacing-12">
                <Col span={24}>
                  <FormField textLabel="Company (optional)">
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter company name"
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row gutter={12} className="spacing-12">
                <Col span={12}>
                  <FormField
                    textLabel="Address Line 1"
                    errorText={errors.addressLine1?.message}
                  >
                    <Controller
                      name="addressLine1"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.addressLine1)}
                          placeholder="Address"
                        />
                      )}
                    />
                  </FormField>
                </Col>
                <Col span={12}>
                  <FormField
                    textLabel="Address Line 2 (optional)"
                    errorText={errors.addressLine2?.message}
                  >
                    <Controller
                      name="addressLine2"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.addressLine2)}
                          placeholder="Unit"
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row gutter={12} className="spacing-12">
                <Col span={12}>
                  <FormField textLabel="City" errorText={errors.city?.message}>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.city)}
                          placeholder="City"
                        ></TextField>
                      )}
                    />
                  </FormField>
                </Col>
                <Col span={12}>
                  <FormField
                    textLabel="State / Province"
                    errorText={errors.state?.message}
                  >
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <SelectDropdown
                          status={errorStatus(errors?.state as FieldError)}
                          width={"100%"}
                          dropdownClassName="dropdown-menu"
                          value={value?.id}
                          searchBar={true}
                          valueChangeToClear={watch("country")?.id}
                          onChange={(stateId) =>
                            handleChangeState(stateId, onChange)
                          }
                          options={stateList}
                          virtual={false}
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Row gutter={12} className="spacing-12">
                <Col span={12}>
                  <FormField
                    textLabel="Zip / Postal Code"
                    errorText={errors.zipCode?.message}
                  >
                    <Controller
                      name="zipCode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          status={errorStatus(errors.zipCode)}
                          placeholder="Zip code"
                        />
                      )}
                    />
                  </FormField>
                </Col>
                <Col span={12}>
                  <FormField textLabel="Country">
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CountrySelect
                          countriesList={countriesList}
                          status={errorStatus(errors?.country as FieldError)}
                          setCountriesList={setCountriesList}
                          flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                          value={value.id}
                          width={"100%"}
                          dropdownClassName="dropdown-menu"
                          handleChange={(countryId) =>
                            handleChangeCountry(countryId, onChange)
                          }
                          acceptCountries={acceptCountries}
                          placeholder="Select your country"
                        />
                      )}
                    />
                  </FormField>
                </Col>
              </Row>
              <Button htmlType="submit" customSize={Size.MEDIUM}>
                Done
              </Button>
            </Form>
          </ConfigProvider>
        </>
      )}
    </div>
  )
}

export default DeliveryAddress
