import { Action, configureStore, Reducer } from "@reduxjs/toolkit"
import { api } from "@/services/apiInstance"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import { LOGOUT_ACTION, REFRESH_TOKEN } from "./action"
import { combinedReducer } from "./reducers"

const rootReducer: Reducer = (state: RootState, action: any) => {
  if (action.type === LOGOUT_ACTION) {
    storage.removeItem("persist:root")
    state = {} as RootState
    window.location.href = "/auth/sign-in"
  } else if (action.type === REFRESH_TOKEN) {
    state = { ...state, storage: action.payload }
  }
  return combinedReducer(state, action)
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["storage"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)

    return middleware
  },
  devTools: process.env.NODE_ENV !== "production"
})

export const persistor = persistStore(store)
export type RootDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof combinedReducer>
