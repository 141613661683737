import RootContainer from "@/components/RootContainer/RootContainer"
import UploadCADFile from "./components/UploadCADFile/UploadCADFile"
import "./SpecificationPage.scss"
import Toolbar from "./components/Toolbar/Toolbar"
import OrderToolbar from "./components/OrderToolBar/OrderToolbar"
import { useEffect, useMemo, useState } from "react"
import EditSpecificationDrawer from "./components/EditSpecificationDrawer/EditSpecificationDrawer"
import { useNavigate, useParams } from "react-router-dom"
import { cleanState, IQuoteStore, setEditProduct } from "@/store/quote"
import { useDispatch, useSelector } from "react-redux"
import ProductItem from "./components/ProductItem/ProductItem"
import { useAppLoading, useLoading } from "@/hooks/useLoading"
import { Product, useLazyGetQuoteQuery } from "@/services/apiDigifabster/quote"
import { useSuitableMaterials } from "@/hooks"
import {
  useCheckWallThicknessMutation,
  useLazyGetModelsDetailQuery,
  WallThicknessPayload
} from "@/services/apiDigifabster/model"
import { RootState } from "@/store"
import { Button, Drawer, Modal } from "@/components"
import { IProductStore } from "@/store/product"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { ButtonHierarchy } from "@/enums/common.enum"
import { EditOutlined } from "@ant-design/icons"
import ModalManualRequest from "./components/ModalManualRequest/ModalManualRequest"

export default function SpecificationPage(): JSX.Element {
  const [selectedItems, setSelectedItems] = useState<Product[]>([])
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false)
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const { openLoading, closeLoading } = useLoading()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [getQuote, { isLoading }] = useLazyGetQuoteQuery()
  const [checkWallThickness, { isError: isWallThickNessError }] =
    useCheckWallThicknessMutation()
  const [getModelsDetail, { isLoading: loadingGetModels }] =
    useLazyGetModelsDetailQuery()
  const { currentQuote, selectedProduct, suitableMaterial } = useSelector<
    RootState,
    IQuoteStore & IProductStore
  >((s) => ({ ...s.quote, ...s.product }))
  let { quoteId } = useParams()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  useAppLoading([isLoading, loadingGetModels])

  useSuitableMaterials()

  useEffect(() => {
    dispatch(cleanState())
    if (quoteId) {
      getQuote({ quoteId })
    }
  }, [quoteId, getQuote])

  useEffect(() => {
    if (!currentQuote) return

    if (currentQuote.products && currentQuote.products.length > 0) {
      const id = currentQuote.products.map((item) => item.model_id).join(",")
      getModelsDetail({ id })
      checkWallThickness(
        currentQuote.products.reduce<WallThicknessPayload>(
          (pre, cur) => ({ ...pre, [cur.model_id]: cur.material_id }),
          {}
        )
      )
    }
  }, [currentQuote])

  useEffect(() => {
    setIsSelectAllChecked(
      selectedItems?.length === (currentQuote?.products?.length || 0) &&
        (currentQuote?.products?.length || 0) > 0
    )
  }, [selectedItems, currentQuote])

  const indeterminate = useMemo(() => {
    return (
      selectedItems?.length > 0 &&
      selectedItems?.length < (currentQuote?.products?.length || 0)
    )
  }, [selectedItems, currentQuote])

  const handleSelectAll = (selectAll: boolean) => {
    if (selectAll) {
      setSelectedItems(currentQuote?.products || [])
    } else {
      setSelectedItems([])
    }
  }

  const handleSelectItem = (item: Product, selected: boolean) => {
    if (selected) {
      setSelectedItems((prevSelectedItems) => {
        const updatedSelection = [...prevSelectedItems, item]
        return updatedSelection
      })
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      )
    }
  }

  const handleCheckout = () => {
    navigate(`/new-quote/checkout/${quoteId}`, { replace: true })
  }

  const handleEditItem = (id: number) => {
    const product = currentQuote?.products.find((e) => e.id === id)
    if (!product) return
    dispatch(setEditProduct(product))
    setOpenEditDrawer(true)
  }

  const handleCloseEditModal = () => {
    setOpenEditDrawer(false)
  }

  const handleSaveQuote = () => {
    openLoading()

    navigate("/new-quote/save-quote-success")
    setTimeout(() => {
      closeLoading()
    }, 2000)
  }

  const openModalManualRequest = () => {
    setIsOpenModal(true)
  }

  const closeModalManualRequest = () => {
    setIsOpenModal(false)
  }

  return (
    <RootContainer
      onBack={() => navigate("/new-quote")}
      headerText="Order Configuration"
      subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
      // customRightElement={
      //   <Button
      //     hierarchy={ButtonHierarchy.TONAL}
      //     onClick={() => handleEditItem(selectedItems[0]?.id)}
      //     disabled={selectedItems.length !== 1}
      //   >
      //     <EditOutlined /> Edit Multiple Parts
      //   </Button>
      // }
    >
      <div className="container-spec">
        <Toolbar />
        {(currentQuote?.products || []).map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            isSelected={selectedItems?.some((item) => item.id === product.id)}
            onSelect={(selected) => handleSelectItem(product, selected)}
            onEditItem={handleEditItem}
            isEditable={!!suitableMaterial[product?.parent_model_id]}
            openDrawer={handleEditItem}
          />
        ))}
        <div className="upload-box">
          <UploadCADFile />
        </div>
        <OrderToolbar
          data={currentQuote?.products || []}
          onCheckOut={handleCheckout}
          onSaveQuote={handleSaveQuote}
          isRequiresReview={
            currentQuote?.available_order_initial_statuses?.[0] ===
            EOrderStatus.WAITING_FOR_REVIEW
          }
          DFMError={isWallThickNessError}
          onSubmitManualRequest={openModalManualRequest}
        />
        {
          <Drawer
            className="edit-specification-drawer"
            width={420}
            open={openEditDrawer}
            customtitle={{
              title: "Edit Specifications",
              subtitle: String(selectedProduct?.model_title),
              imageUrl: selectedProduct?.model_thumbnail
            }}
            onClose={handleCloseEditModal}
          >
            <EditSpecificationDrawer
              onUpdateDone={handleCloseEditModal}
              openEditDrawer={openEditDrawer}
            />
          </Drawer>
        }
        <ModalManualRequest
          openModal={isOpenModal}
          closeModal={closeModalManualRequest}
          currentQuote={currentQuote}
        />
      </div>
    </RootContainer>
  )
}
