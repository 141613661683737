import { ErrorToastIcon, SuccessToastIcon, WarningToastIcon } from "@/assets"
import Toast from "@/components/common/atom/Toast/Toast"
import { toast } from "react-toastify"

export const showSuccess = (title?: string, content?: string) => {
  return toast(
    <Toast content={content} statusIcon={SuccessToastIcon} title={title} />
  )
}

export const showWarning = (title?: string, content?: string) => {
  return toast(
    <Toast content={content} statusIcon={WarningToastIcon} title={title} />
  )
}

export const showError = (title?: string, content?: string) => {
  return toast(
    <Toast content={content} statusIcon={ErrorToastIcon} title={title} />
  )
}
