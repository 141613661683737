import "./Toolbar.scss"

interface ToolbarProps {}

function Toolbar({}: ToolbarProps) {
  return (
    <div className="toolbar-container">
      {/* <div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `"Open Sans", sans-serif`
            }
          }}
        >
          <CustomCheckbox
            indeterminate={indeterminate}
            checked={isSelectAllChecked}
            onChange={(e) => onSelectAll(e.target.checked)}
          >
            <p className="text-checkbox">Select All</p>
          </CustomCheckbox>
        </ConfigProvider>
      </div> */}
      <div>PART</div>
      <div>Specification</div>
      <div>POST PROCESSING</div>
      <div>Quantity</div>
      <div>Price</div>
      {/* <div className="toolbar-button">
        <Button
          hierarchy={ButtonHierarchy.OUTLINE}
          onClick={() => onEditPart(true)}
        >
          <EditOutlined /> Edit Part
        </Button>
      </div> */}
    </div>
  )
}

export default Toolbar
