import { useGetSuitableMaterialsMutation } from "@/services/apiDigifabster/model"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import { IQuoteStore } from "@/store/quote"
import { useEffect } from "react"
import { useSelector } from "react-redux"

export const useSuitableMaterials = () => {
  const { technologies, suitableMaterial } = useSelector<
    RootState,
    IProductStore
  >((s) => s.product)
  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)

  const [getSuitableMaterials, { isLoading }] =
    useGetSuitableMaterialsMutation()

  useEffect(() => {
    if (!technologies.length || !currentQuote || !currentQuote.products) return

    const modelIds = currentQuote.products
      .map((e) => e.parent_model_id)
      .filter((e) => !suitableMaterial[e])

    getSuitableMaterials(modelIds)
  }, [currentQuote, technologies])

  return isLoading
}
