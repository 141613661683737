import React from "react"
import { Input } from "antd"
import "./TextArea.scss"
import { InputRef, TextAreaProps } from "antd/es/input"
import { Type } from "@/enums/common.enum"

const { TextArea } = Input

interface ITextareaProps extends TextAreaProps {
  type?: Type.PRIMARY | Type.DEFAULT
  error?: boolean
}

const Textarea = React.forwardRef<InputRef, ITextareaProps>((props, ref) => {
  const errorTextArea = props.error ? "error" : ""
  return (
    <div className="textarea">
      <div className={`textarea-wrapper ${errorTextArea}`}>
        <Input.TextArea {...props} autoSize={props.autoSize ?? true} />
      </div>
    </div>
  )
})

Textarea.displayName = "Textarea"

export default Textarea
