import "./OrderToolbar.scss"
import { Button } from "@/components"
import { Size } from "@/enums/common.enum"
import { Product } from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { useSelector } from "react-redux"

interface IOrderToolbarProps {
  data: Product[]
  onCheckOut: () => void
  onSaveQuote: () => void
  onSubmitManualRequest: () => void
  isRequiresReview?: boolean
  DFMError?: boolean
}
function OrderToolbar({
  data,
  onCheckOut,
  onSaveQuote,
  isRequiresReview,
  onSubmitManualRequest,
  DFMError
}: IOrderToolbarProps) {
  // TODO: mission price in productEntity
  const total = data.reduce(
    (acc, val) => acc + val.price_per_part * val.count,
    0
  )

  const { isUploadingFile } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )

  const disableBtnCheckout = data.length <= 0 || isUploadingFile || DFMError

  return (
    <div className="order-toolbar">
      <div className="order-toolbar-container">
        <div className="order-toolbar-box">
          <p>
            Order Total ({data.length} {data.length > 1 ? "items" : "item"}):{" "}
            {!isRequiresReview && <span>${total.toFixed(2)}</span>}
            {isRequiresReview && <span>Requires Review</span>}
          </p>
          <div className="order-toolbar-button">
            <>
              <div className="order-toolbar-button-text">
                Have Question? &nbsp;
                <span onClick={onSubmitManualRequest}>Ask for help</span>
              </div>
              <Button
                customSize={Size.EXTRA_LARGE}
                onClick={onCheckOut}
                disabled={disableBtnCheckout}
              >
                Proceed to Checkout
              </Button>
            </>
            {/* {isRequiresReview ? (
              <>
                <Button
                  customType={Type.NEUTRAL}
                  hierarchy={ButtonHierarchy.OUTLINE}
                  customSize={Size.EXTRA_LARGE}
                  onClick={() => {}}
                  customClassName="order-toolbar-button-action"
                >
                  Download PDF
                </Button>
                <Button
                  customSize={Size.EXTRA_LARGE}
                  onClick={onSubmitManualRequest}
                >
                  Submit Manual Request
                </Button>
              </>
            ) : (
              <>
                <Button
                  customType={Type.NEUTRAL}
                  hierarchy={ButtonHierarchy.OUTLINE}
                  customSize={Size.EXTRA_LARGE}
                  onClick={onSaveQuote}
                  customClassName="order-toolbar-button-action"
                >
                  Save quote
                </Button>
                <Button
                  customSize={Size.EXTRA_LARGE}
                  onClick={onCheckOut}
                  customClassName="order-toolbar-button-action"
                >
                  Checkout
                </Button>
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderToolbar
