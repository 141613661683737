import { combineReducers } from "@reduxjs/toolkit"
import { storageReducer } from "./storage"
import { api } from "@/services/apiInstance"
import { appReducer } from "./app"
import { authReducer } from "./auth"
import { userReducer } from "./user"
import { quoteReducer } from "./quote"
import { productReducer } from "./product"

export const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  storage: storageReducer,
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  quote: quoteReducer,
  product: productReducer
})
