import {
  EBudget,
  EHaveCADFile,
  EService,
  ETimeline
} from "@/enums/createQuote.enum"

export const IS_HAVE_CAD_FILE_OPTIONS = [
  {
    id: EHaveCADFile.YES,
    title: "Yes"
  },
  {
    id: EHaveCADFile.NO,
    title: "No"
  }
]

export const BUDGET_OPTIONS = [
  {
    id: EBudget.LESS_FIVE,
    title: "<$500"
  },
  {
    id: EBudget.FIVE_TEN,
    title: "$500 - $1000"
  },
  {
    id: EBudget.TEN_TWENTY,
    title: "$1000 - $2000"
  },
  {
    id: EBudget.TWENTY_FIFTY,
    title: "$2000 - $5000"
  },
  {
    id: EBudget.FIFTY_HUNDRED,
    title: "$5000 - $10,000"
  },
  {
    id: EBudget.MORE_HUNDRED,
    title: "+$10,000"
  }
]

export const TIMELINE_OPTIONS = [
  {
    id: ETimeline.LESS_ONE,
    title: "<1 week"
  },
  {
    id: ETimeline.ONE_TWO,
    title: "1-2 weeks"
  },
  {
    id: ETimeline.TWO_FOUR,
    title: "2-4 weeks"
  },
  {
    id: ETimeline.MORE_FOUR,
    title: "4+ weeks"
  }
]

export const SERVICES = [
  {
    label: "CAD, Engineering Services",
    value: EService.CAD
  },
  {
    label: "Short-run Manufacturing",
    value: EService.SHORT
  },

  {
    label: "Assembly, Finishing, Painting",
    value: EService.ASSEMBLY
  },

  {
    label: "Art, Props & Sculptures",
    value: EService.ART
  },

  {
    label: "Scale Models, Trade Show Displays, Concept Models",
    value: EService.SCALE
  }
]

export const SERVICE_LABEL = {
  [EService.CAD]: "CAD, Engineering Services",
  [EService.SHORT]: "Short-run Manufacturing",
  [EService.ASSEMBLY]: "Assembly, Finishing, Painting",
  [EService.ART]: "Art, Props & Sculptures",
  [EService.SCALE]: "Scale Models, Trade Show Displays, Concept Models"
}

export const IS_HAVE_CAD_FILE = {
  [EHaveCADFile.YES]: "Yes",
  [EHaveCADFile.NO]: "No"
}

export const BUDGET = {
  [EBudget.LESS_FIVE]: "<$500 - $1000",
  [EBudget.FIVE_TEN]: "$500 - $1000",
  [EBudget.TEN_TWENTY]: "$1000 - $2000",
  [EBudget.TWENTY_FIFTY]: "$2000 - $5000",
  [EBudget.FIFTY_HUNDRED]: "$5000 - $10,000",
  [EBudget.MORE_HUNDRED]: "+$10,000"
}

export const TIMELINE = {
  [ETimeline.LESS_ONE]: "<1 week",
  [ETimeline.ONE_TWO]: "1-2 weeks",
  [ETimeline.TWO_FOUR]: "2-4 weeks",
  [ETimeline.MORE_FOUR]: "4+ weeks"
}
