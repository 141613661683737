import { REGEX_EMAIL, REQUIRED_ERROR } from "@/constants/common.constant"
import { object, string } from "yup"

export const loginSchema = object({
  email: string()
    .required(REQUIRED_ERROR("Email"))
    .matches(REGEX_EMAIL, "Invalid format"),
  password: string()
    .required(REQUIRED_ERROR("Password"))
    .min(6, "Password must be at least 6 characters")
})
