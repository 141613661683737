import { priceDisplay } from "@/utils/functionHelper"
import "./InvoiceItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"

interface IInvoiceItemProps {
  item: LineItem
}

function InvoiceItem({ item }: IInvoiceItemProps) {
  return (
    <div className="invoice-item-box">
      <div className="invoice-item-box-col-1">
        <div>
          <img src={item.product.thumb_120x120} alt="" width={68} height={54} />
        </div>
        <p>{item.product.title}</p>
      </div>
      <div className="invoice-item-box-col-2">
        <p>{item.technology_title}</p>
        <p>
          {item.material_title} ,{item.config.color.name_for_user}
        </p>
        <p>{item.config.lead_time.name_for_user}</p>
      </div>
      <div className="invoice-item-box-col-3">
        <p>{item.amount}</p>
      </div>
      <div className="invoice-item-box-col-4">
        <p>${priceDisplay(item.total_price)}</p>
        <p>${priceDisplay(item.unit_price)} / unit</p>
      </div>
    </div>
  )
}

export default InvoiceItem
