import Header from "@/components/Header/Header"
import "./RootContainer.scss"
import Button from "../common/atom/Button/Button"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
interface IRootContainerProps {
  children?: React.ReactNode
  headerText: string
  subHeaderText?: string
  customRightElement?: React.ReactNode
  onBack?: Function
}
const RootContainer: React.FC<IRootContainerProps> = ({
  children,
  subHeaderText,
  headerText,
  onBack,
  customRightElement
}) => {
  return (
    <div className="main-layout">
      <Header customClassName="main-layout-header">
        <div className="main-layout-header-left">
          {onBack && (
            <Button
              hierarchy={ButtonHierarchy.LINK}
              customSize={Size.LARGE}
              customType={Type.NEUTRAL}
              customClassName="back-button"
              onClick={() => onBack()}
            >
              <ArrowLeftOutlined />
            </Button>
          )}
          <h3 className="header-text">
            {headerText}
            <span className="header-subtext">{subHeaderText}</span>
          </h3>
        </div>

        <div className="main-layout-header-right">{customRightElement}</div>
      </Header>

      <div className="main-layout-content">{children}</div>
    </div>
  )
}

export default RootContainer
