import { IMaterialConfig } from "@/store/product"
import { api } from "../apiInstance"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    technologyList: build.query<TechnologyListResponse, void | PagingQuery>({
      query: (queryArg) => ({
        url: `/api/dgfo/technologies/`,
        method: "GET",
        params: queryArg ? { offset: 100 * (queryArg.page - 1) } : {}
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as technologyService }

export interface PagingQuery {
  page: number
}

export interface TechnologyListResponse {
  count: number
  next: string
  previous: string
  results: {
    id: 0
    title: string
    tech_id: 0
    image: string
    materials: {
      id: 0
      title: string
      note_for_user: string
      spec_sheet_url: string
      printer_size: string
      min_wall_thickness: 0.1
      accept_drawing: string
      is_iqt_only: true
      extra_fieldsets: [
        {
          id: string
          title: string
          options: [
            {
              id: string
              option: string
            }
          ]
          is_active: true
          sort_order: 4294967295
          material: 0
        }
      ]
      tags: [string]

      // TODO: define type
      filling: any[]
      layer_thickness: any[]
      lead_time: any[]
      color: any[]
      post_production: IMaterialConfig[]
      tolerance: any[]
    }[]
    note: string
  }[]
}

export const { useLazyTechnologyListQuery, useTechnologyListQuery } =
  injectedRtkApi
