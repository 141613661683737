import { MailOutlined } from "@ant-design/icons"
import { Card, Divider, Flex, Space } from "antd"
import "./ContactCard.scss"
import { HeadPhoneMicIcon } from "@/assets"

interface IContactCard {
  email: string
  sessionId: string
  phone: string
}

interface IContactCardData {
  data: IContactCard
}

const ContactCard: React.FC<IContactCardData> = ({ ...props }) => {
  const { email, phone, sessionId } = props.data
  return (
    <div className="contact-card">
      <Card bordered={false} style={{ display: "inline-block" }}>
        <Flex gap={16} vertical align="flex-start">
          <div className="contact-card-support">
            <Space direction="vertical" size={"small"}>
              <Flex gap={8} align="center">
                <img
                  src={HeadPhoneMicIcon}
                  className="contact-card-support-title"
                />
                <div className="contact-card-support-title">
                  Contact Live Support
                </div>
              </Flex>
              <div className="contact-card-support-datetime">
                Monday - Friday 8:30AM - 4:30PM PST
              </div>
              <div className="contact-card-support-phone">
                Toll free: {phone}
              </div>
            </Space>
          </div>
          <Flex gap={8} align="center">
            <MailOutlined className="contact-card-email" />
            <div className="contact-card-email">Email: {email}</div>
          </Flex>
          <Divider style={{ margin: 0 }} />
          <div className="contact-card-session">Session ID: {sessionId}</div>
        </Flex>
      </Card>
    </div>
  )
}

export default ContactCard
