import Button from "@/components/common/atom/Button/Button"
import FormField from "@/components/common/atom/FormField/FormField"
import TextField from "@/components/common/atom/TextField/TextField"
import { Size, Type } from "@/enums/common.enum"
import { useLoading } from "@/hooks/useLoading"
import { Code, useForgotPasswordMutation } from "@/services/api/auth"
import { RESET_PASSWORD_EMAIL_SCHEMA } from "@/validations/resetPassword.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { ConfigProvider, Flex, Form } from "antd"
import { useEffect } from "react"
import { Controller, FieldError, useForm } from "react-hook-form"
import { createSearchParams, useNavigate } from "react-router-dom"
import * as toast from "@/utils/Toast"
import { ErrorMessage } from "@/shared/messages"
import { useSendMailForgotPasswordMutation } from "@/services/apiDigifabster/auth"
interface IResetPWEmailFormInput {
  email: string
}

function EmailResetPWForm(): JSX.Element {
  const errorStatus = (errors?: FieldError) => (errors ? "error" : "")
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    getValues
  } = useForm<IResetPWEmailFormInput>({
    defaultValues: {
      email: ""
    },
    resolver: yupResolver(RESET_PASSWORD_EMAIL_SCHEMA)
  })
  const navigate = useNavigate()
  const [handleForgotPassword, { isLoading, isSuccess, error }] =
    useSendMailForgotPasswordMutation()
  const { openLoading, closeLoading } = useLoading()
  useEffect(() => {
    if (!isSuccess) return

    navigate({
      pathname: "/auth/forgot-password/notify",
      search: createSearchParams({ email: getValues().email }).toString()
    })
  }, [isSuccess])

  useEffect(() => {
    if (isLoading) {
      openLoading()
    } else {
      closeLoading()
    }
  }, [isLoading])

  useEffect(() => {
    if (error) {
      const errorData = error as any
      if (errorData?.status === 404) {
        setError("email", { message: ErrorMessage[Code.UserNotFound] })
      } else {
        toast.showError(errorData?.data?.message)
      }
    }
  }, [error])

  const onSubmit = (data: IResetPWEmailFormInput) => {
    handleForgotPassword(data)
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", sans-serif'
        }
      }}
    >
      <Form
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={handleSubmit(onSubmit)}
      >
        <FormField
          size={Size.MEDIUM}
          textLabel="Enter your email"
          errorText={errors.email?.message}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter your email"
                status={errorStatus(errors.email)}
              ></TextField>
            )}
          />
        </FormField>
        <Form.Item>
          <Flex vertical align="center" justify="center">
            <Button
              type="primary"
              htmlType="submit"
              customSize={Size.EXTRA_LARGE}
              style={{ marginTop: "40px", width: "70%" }}
            >
              Send reset password link
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </ConfigProvider>
  )
}

export default EmailResetPWForm
