import "./RegisterPage.scss"
import { SubmitHandler, useForm } from "react-hook-form"
import RegisterForm from "./components/RegisterForm/RegisterForm"
import { IFormRegister } from "@/interfaces/register.interface"
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom"
import { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { REGISTER_SCHEMA } from "@/validations/register.validation"
import { useRegisterMutation } from "@/services/apiDigifabster/auth"
import * as toast from "@/utils/Toast"
import { useAppLoading } from "@/hooks/useLoading"
export default function RegisterPage(): JSX.Element {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    getValues
  } = useForm<IFormRegister>({
    resolver: yupResolver(REGISTER_SCHEMA)
  })

  const [handleRegister, { isLoading, isSuccess, isUninitialized, error }] =
    useRegisterMutation()
  const navigate = useNavigate()

  useAppLoading([isLoading])

  useEffect(() => {
    if (error) {
      const errorData = error as any
      if (errorData && errorData?.status === 404) {
        setError("email", { message: "This email address has been taken" })
      } else {
        toast.showError("Internal server error")
      }
    }
  }, [error])

  const onSubmit: SubmitHandler<IFormRegister> = (data) => {
    const { email, password, firstName, lastName, phone } = data
    handleRegister({
      companyName: "forge-labs-inc",
      email,
      password,
      firstName,
      lastName,
      phone
    })
  }

  useEffect(() => {
    if (!isSuccess || isUninitialized) return

    window.location.href = "/"
  }, [isSuccess])

  const location = useLocation()

  return location.pathname.endsWith("/success") ? (
    <Outlet />
  ) : (
    <div className="register-page-wrapper">
      <h3>Register</h3>
      <div className="register-form-wrapper">
        <RegisterForm
          onSubmit={onSubmit}
          control={control}
          handleSubmit={handleSubmit}
          setValue={setValue}
          errors={errors}
        />
      </div>
    </div>
  )
}
