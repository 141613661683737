import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { useAppLoading } from "./useLoading"
import { useGoogleLogInMutation } from "@/services/apiDigifabster/auth"
import * as toast from "@/utils/Toast"

export const useGoogleSignin = () => {
  const [signIn, { isLoading }] = useGoogleLogInMutation()
  useAppLoading([isLoading])

  const handleGoogleSignin = async () => {
    try {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      // TODO: verify user
      const idToken = await result.user.getIdToken()
      const data = await signIn({ idToken })
      if (data.error) {
        throw new Error()
      }
      window.location.href = "/"
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      // TODO: error handling
      console.log(errorCode, errorMessage)
      toast.showError("Failed to login")
    }
  }

  return handleGoogleSignin
}
